//@flow
import mapboxgl from "mapbox-gl";
import React from "react";
import axios from "axios";
import styles from "./UturnAnalyser.module.css";
import Typography from "@material-ui/core/Typography/Typography";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";

type Props = {
  map: mapboxgl.Map,
}

type State = {
  routes: null,
  selectedReplaced: boolean,
  selectedDate: string,
  selectedSession: string,
  delay: string
}

const RouteLineSource = 'routingLineSource'
const RouteLineLayer = 'routingLineLayer'
const MarkerSource = 'markerSource'
const MarkerLayer = 'markerLayer'
const emptyFC = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  }
}

const replacedEnum = true
const notReplacedEnum = false

const replacedOptions = {
  replaced: {val: replacedEnum, string: 'Replaced'},
  notReplaced: {val: notReplacedEnum, string: 'Not replaced'}
}

export class UturnAnalyser extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      routes: null,
      selectedReplaced: replacedOptions.replaced, // whether we should show the routes that were modified, or were not modified
      selectedDate: "",
      selectedSession: "",
      delay: "" // the delay when using the no-traffic route
    }

    this.delayPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
  }

  componentDidMount(): void {
    const map = this.props.map

    this.addLayers()

    this.getRoutes()

    map.on('mouseenter', RouteLineLayer, this.onOriginDestinationEnter);

  }

  componentWillUnmount(): void {
    this.removeLayers()
  }

  addLayers = () => {
    const map = this.props.map

    let routeSource = map.getSource(RouteLineSource)
    if (routeSource) {
      map.removeLayer(RouteLineLayer)
      map.removeSource(RouteLineSource)
    }

    // add the nugraph route layer
    map.addSource(RouteLineSource, emptyFC);
    map.addLayer({
      'id': RouteLineLayer,
      'type': 'line',
      'source': RouteLineSource,
      'paint': {
        'line-color': ['get', 'stroke'],
        'line-width': [
          'match',
          ['get', 'stroke'],
          '#000000', 5,
          3
        ],
      },
      'filter': ['==', '$type', 'LineString']
    });

    map.addSource(MarkerSource, emptyFC);
    map.addLayer({
      'id': MarkerLayer,
      'type': 'circle',
      'source': MarkerSource,
      'paint': {
        'circle-color': [
          'match',
          ['typeof', ['get', 'duration before']],
          'string', '#389300',
          '#b20000'      // start point
        ],
        'circle-radius': 8,
      },
      'filter': ['==', '$type', 'Point']
    });
  }

  removeLayers = () => {
    const map = this.props.map

    let routeSource = map.getSource(RouteLineSource)
    if (routeSource) {
      map.removeLayer(RouteLineLayer)
      map.removeSource(RouteLineSource)
    }
    let markerSource = map.getSource(MarkerSource)
    if (markerSource) {
      map.removeLayer(MarkerLayer)
      map.removeSource(MarkerSource)
    }
  }

  getRoutes = () => {
    var config = {
      method: "GET",
      url: process.env.REACT_APP_BFF_URL + "/v1/getUturns"
    };
    axios(config)
      .then((resp) => {
        this.setState({
          routes: resp.data,
        }, this.updateRoute)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sortedDates = () => {
    let dates = this.state.routes.replaced
    if (this.state.selectedReplaced.val === notReplacedEnum) {
      dates = this.state.routes.notReplaced
    }

    if (!dates) {
      return []
    }
    let datesList = []
    Object.keys(dates).map(function (key) {
      datesList.push(key)
    })
    datesList.sort()
    return datesList
  }

  sortedSessions = (selectedDate) => {
    let dates = this.state.routes.replaced
    if (this.state.selectedReplaced.val === notReplacedEnum) {
      dates = this.state.routes.notReplaced
    }
    if (!selectedDate) {
      selectedDate = this.sortedDates()[0]
    }
    if (!(dates && dates[selectedDate].routes)) {
      return []
    }
    let sessions = dates[selectedDate].routes
    let sessionsList = []
    Object.keys(sessions).map(function (key) {
      sessionsList.push(sessions[key])
    })
    sessionsList.sort(function (i, j) {
      if (i.delaySeconds < j.delaySeconds) {
        return 1
      }
      return -1
    })
    let sessionIds = []
    for (let i = 0; i < sessionsList.length; i++) {
      sessionIds.push(sessionsList[i].session)
    }
    return sessionIds
  }

  onSelectedReplacedChange = (e) => {
    let replaced = replacedOptions.replaced
    if (e.target.value === replacedOptions.notReplaced.val.toString()) {
      replaced = replacedOptions.notReplaced
    }
    this.setState({
      selectedReplaced: replaced
    }, this.updateRoute)
  }

  onSelectedDateChange = (e) => {
    this.setState({
      selectedDate: e.target.value
    }, this.updateRoute)
  }

  onSelectedSessionChange = (e) => {
    this.setState({
      selectedSession: e.target.value
    }, this.updateRoute)
  }

  updateRoute = () => {
    let dates = this.state.routes.replaced
    if (this.state.selectedReplaced.val === notReplacedEnum) {
      dates = this.state.routes.notReplaced
    }

    let selectedDate = this.state.selectedDate
    if (!selectedDate) {
      selectedDate = this.sortedDates()[0]
    }

    let selectedSession = this.state.selectedSession
    if (!selectedSession) {
      selectedSession = this.sortedSessions(selectedDate)[0]
    }

    const map = this.props.map
    let route = dates[selectedDate].routes[selectedSession]
    if (!route) {
      return
    }

    let routeSource = map.getSource(RouteLineSource)
    if (routeSource) {
      routeSource.setData(route.route)
    }
    let markerSource = map.getSource(MarkerSource)
    if (markerSource) {
      markerSource.setData(route.route)
    }

    if (route.route.features.length === 0) {
      return
    }
    this.setMapBounds(route)

    this.setState({delay: route.delayString})
  }

  setMapBounds = (route) => {
    let mapBounds = {
      maxLat: 0,
      maxLng: 0,
      minLat: 180,
      minLng: 180
    }
    for (let i = 0; i < route.route.features.length; i++) {
      let geometry = route.route.features[i].geometry
      if (geometry.type !== "LineString") {
        continue
      }
      for (let j = 0; j < geometry.coordinates.length; j++) {
        let coordinates = geometry.coordinates[j]
        if (coordinates[0] > mapBounds.maxLng) {
          mapBounds.maxLng = coordinates[0]
        }
        if (coordinates[0] < mapBounds.minLng) {
          mapBounds.minLng = coordinates[0]
        }
        if (coordinates[1] > mapBounds.maxLat) {
          mapBounds.maxLat = coordinates[1]
        }
        if (coordinates[1] < mapBounds.minLat) {
          mapBounds.minLat = coordinates[1]
        }
      }
    }
    // check if the source and destination are visible on the screen
    const map = this.props.map
    map.fitBounds([[
      mapBounds.minLng,
      mapBounds.minLat
    ], [
      mapBounds.maxLng,
      mapBounds.maxLat
    ]], {padding: 200});
  }

  onRouteEnter = (e) => {
    const map = this.props.map
    map.getCanvas().style.cursor = 'pointer';
    let msg = ''
    if (e.features && e.features[0].properties['type'] === 'origin') {
      msg = 'origin'
      if (e.features[0].properties['heading']) {
        msg += '<br>heading: ' + e.features[0].properties['heading'] + '°'
      }
    } else if (e.features && e.features[0].properties['type'] === 'destination') {
      msg = 'destination'
    } else {
      return
    }
    this.delayPopup.setLngLat(e.lngLat)
      .setHTML(msg)
      .addTo(map);
  }

  render() {
    let cardContent = null
    let routes = this.state.routes
    if (routes) {
      let dates = this.sortedDates()
      let sessions = this.sortedSessions(this.state.selectedDate)
      let replacedPicker = (
        <div className={styles.datePicker}>
          <Typography style={{'width': '70px'}} variant={'subtitle1'} color='primary'
                      noWrap>Replaced</Typography>
          <FormControl>
            <NativeSelect
              defaultValue={replacedOptions.replaced.val}
              value={this.state.selectedReplaced.val}
              onChange={this.onSelectedReplacedChange}
              input={<Input name='name' id='uncontrolled-native'/>}
            >
              {
                Object.keys(replacedOptions).map(function (key) {
                  return (
                    <option
                      value={replacedOptions[key].val}>{replacedOptions[key].string}</option>
                  )
                })
              }
            </NativeSelect>
          </FormControl>
        </div>
      )
      let sessionPicker = (
        <div className={styles.datePicker}>
          <Typography style={{'width': '70px'}} variant={'subtitle1'} color='primary'
                      noWrap>Session</Typography>
          <FormControl>
            <NativeSelect
              defaultValue={sessions[0].session}
              value={this.state.selectedSession}
              onChange={this.onSelectedSessionChange}
              input={<Input name='name' id='uncontrolled-native'/>}
            >
              {
                sessions.map(function (key) {
                  return (
                    <option
                      value={key}>{key}</option>
                  )
                })
              }
            </NativeSelect>
          </FormControl>
        </div>
      )
      let datePicker = (
        <div className={styles.datePicker}>
          <Typography style={{'width': '70px'}} variant={'subtitle1'} color='primary'
                      noWrap>Date</Typography>
          <FormControl>
            <NativeSelect
              defaultValue={dates[0]}
              value={this.state.selectedDate}
              onChange={this.onSelectedDateChange}
              input={<Input name='name' id='uncontrolled-native'/>}
            >
              {
                dates.map(function (key) {
                  return (
                    <option
                      value={key}>{key}</option>
                  )
                })
              }
            </NativeSelect>
          </FormControl>
        </div>
      )
      cardContent = (
        <Card className={styles.body}>
          <CardContent className={styles.content}>
            <div>
              {replacedPicker}
              {datePicker}
              {sessionPicker}
              <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                          noWrap>{'No-traffic route: ' + this.state.delay}</Typography>
            </div>
          </CardContent>
        </Card>
      )

    }

    return (
      <div>
        {cardContent}
      </div>
    )
  }

}