export function turnInfoToHTML(turnInfo, turnCommandIdx) {
    let turnCommand = turnInfo.getTurnCommand().toLowerCase()
    let lanes = turnInfo.getLanesList()
    if (turnInfo.getShouldSuppressTurnCommand()) {
        turnCommand = '<s>' + turnCommand + '</s>'
    }

    let html = turnCommandIdx + '. ' + turnCommand

    // lane turns
    for (let i = 0; i < lanes.length; i++) {
        if (i === 0) {
            html += '<br>'
        }
        if (lanes[i].getShouldUse()) {
            html += '<b>'
        }
        let turn = lanes[i].getLaneTurnsList()
        for (let j = 0; j < turn.length; j++) {
            html += turn[j]
            if (j < turn.length - 1) {
                html += ';'
            }
        }
        if (lanes[i].getShouldUse()) {
            html += '</b>'
        }
        if (i < lanes.length - 1) {
            html += ' | '
        }
    }

    // lane destination names
    for (let i = 0; i < lanes.length; i++) {
        if (i === 0) {
            html += '<br>'
        }
        if (lanes[i].getShouldUse()) {
            html += '<b>'
        }
        let destinations = lanes[i].getDestinations()
        if (!destinations) {
            continue
        }
        let destinationNames = destinations.getNamesList()
        for (let j = 0; j < destinationNames.length; j++) {
            html += destinationNames[j]
            if (j < destinationNames.length - 1) {
                html += ';'
            }
        }
        if (lanes[i].getShouldUse()) {
            html += '</b>'
        }
        if (i < lanes.length - 1) {
            html += ' | '
        }
    }

    // lane destination refs
    for (let i = 0; i < lanes.length; i++) {
        if (i === 0) {
            html += '<br>'
        }
        if (lanes[i].getShouldUse()) {
            html += '<b>'
        }
        let destinations = lanes[i].getDestinations()
        if (!destinations) {
            continue
        }
        let destinationRefs = destinations.getRefsList()
        for (let j = 0; j < destinationRefs.length; j++) {
            html += destinationRefs[j]
            if (j < destinationRefs.length - 1) {
                html += ';'
            }
        }
        if (lanes[i].getShouldUse()) {
            html += '</b>'
        }
        if (i < lanes.length - 1) {
            html += ' | '
        }
    }

    // destination names
    if (turnInfo.getDestinations()) {
        let destinationNames = turnInfo.getDestinations().getNamesList()
        let destinationRefs = turnInfo.getDestinations().getRefsList()
        for (let i = 0; i < destinationNames.length; i++) {
            if (i === 0) {
                html += '<br>'
            }
            html += destinationNames[i]
            if (i < destinationNames.length - 1) {
                html += ';'
            }
        }
        for (let i = 0; i < destinationRefs.length; i++) {
            if (i === 0) {
                html += '<br>'
            }
            html += destinationRefs[i]
            if (i < destinationRefs.length - 1) {
                html += ';'
            }
        }
    }

    return html
}
