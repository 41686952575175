import React from "react";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import styles from "./NearestNeighbour.module.css";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {PropertyRow} from "./PropertyRow";

type Props = {
  propertyMap: {}
}

export class PropertyTable extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // convert the string of osm ids into html links, eg "1234, 5678" -> <a href="1234">1234</a><a href="5678">5678</a>
  parseOsmIds = (str) => {
    let idsStr = str.split(',')
    let htmlStr = []
    for (let i = 0; i < idsStr.length; i++) {
      let link = 'https://openstreetmap.org/node/' + idsStr[i].trim()
      if (i === idsStr.length - 1) {
        // without comma
        htmlStr.push(<a href={link} target='_blank'>{idsStr[i].trim()}</a>)
      } else {
        // with comma
        htmlStr.push(<div style={{display: 'inline'}}><a href={link} target='_blank'>{idsStr[i].trim()}</a>,</div>)
      }
    }
    return htmlStr
  }

  render() {
    let propertyMap = this.props.propertyMap
    let parseOsmIds = this.parseOsmIds
    return (
      <Table style={{tableLayout: 'fixed'}}>
        <colgroup>
          <col style={{width: '30%'}}/>
          <col style={{width: '70%'}}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={styles.cell}>Property</TableCell>
            <TableCell className={styles.cell}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(propertyMap).map(function (key) {
              if (key === 'out edges' || key === 'kalman' || key === 'vehicleFlowHistoricPattern' || key === 'turnCostHistory' || key === 'vehicleFlowHistory') {
                return
              }
              if (key === 'osm ids') {
                let osmLinks = parseOsmIds(propertyMap[key])
                return (<PropertyRow k={key} value={osmLinks}/>)
              }
              return (<PropertyRow k={key} value={propertyMap[key]}/>)
            })
          }
        </TableBody>
      </Table>
    );
  }
}
