import React, { Component } from 'react'
import styles from './App.module.css'
import MapContainer from './map/MapContainer'
import LoginContainer from './login/LoginContainer'
import type { LoginContextType } from './LoginContext'
import LoginContext, { createDefaultLoginContext } from './LoginContext'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { materialUITheme } from './theming/colorTheme'
import type { SelectedEdgeContextType } from './components/traffic/EdgeCard'
import { SelectedEdgeContext } from './components/traffic/EdgeCard'
import TopMenu from './components/TopMenu'
import SplashScreenAnimation from "./SplashScreenAnimation";

class App extends Component {

  constructor (props) {
    super(props)

    let onContextUpdated = (newContext) => {
      this.setState({
        loginContextValue: newContext
      })
    }

    let onSelectedEdgeContextUpdated = (newContext: SelectedEdgeContextType) => {
      this.setState({
        selectedEdgeContextValue: newContext
      })
    }

    const selectedEdgeContextValue: SelectedEdgeContextType = {}
    selectedEdgeContextValue.edge = null
    selectedEdgeContextValue.onSelectionChanged = onSelectedEdgeContextUpdated

    this.updateMenuItem = this.updateMenuItem.bind(this);

    this.state = {
      loginContextValue: createDefaultLoginContext(onContextUpdated),
      selectedEdgeContextValue: selectedEdgeContextValue,
      selectedMenuItem: "routing",
      shouldStartAnimation: false,
      finishedAnimation: false,
    }
  }

  componentDidMount(){
    document.title = "Nugraph"
  }

  updateMenuItem(val) {
    this.setState({
      selectedMenuItem: val
    })
  }

  render () {
    let eventListeners = [
      {
        eventName: 'complete',
        callback: () => {
          this.setState({
            finishedAnimation: true,
          })
        },
      },
    ]

    let splashScreen = null
    if (!this.state.finishedAnimation) {
      splashScreen = (
        <div className={styles.splashScreenBackground} style={{'zIndex': '1'}}>
          <div className={styles.splashScreenRadialBackground}/>
          <SplashScreenAnimation start={this.state.shouldStartAnimation} eventListeners={eventListeners}/>
        </div>
      )
    }

    let onCompletedCallback = () => {
      this.setState({
        shouldStartAnimation: true
      })
    }

    return (
      <MuiThemeProvider theme={materialUITheme}>
        <div className={styles.body}>
          <SelectedEdgeContext.Provider value={this.state.selectedEdgeContextValue}>
            <LoginContext.Provider value={this.state.loginContextValue}>
              <LoginContext.Consumer>
                {(context: LoginContextType) => {
                  const showLoginDialog = !context.isLoggedIn()
                  if (showLoginDialog) {
                    return (
                      <LoginContainer/>
                    )
                  }
                }}
              </LoginContext.Consumer>

              <LoginContext.Consumer>
                {(context: LoginContextType) => {
                  if (!context.isLoggedIn()) {
                    return
                  }
                  return (
                      <TopMenu
                        content={<MapContainer overlayName={this.state.selectedMenuItem} loginCtx={context}/>}
                        updateMenuItemProp={this.updateMenuItem}
                        onCompletedCallback={onCompletedCallback}
                      />
                  )
                }}
              </LoginContext.Consumer>
              {splashScreen}
            </LoginContext.Provider>
          </SelectedEdgeContext.Provider>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default App
