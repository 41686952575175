//@flow
import React from 'react'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import styles from './Turncommands.css'
import mapboxgl from 'mapbox-gl'
import InputLabel from '@material-ui/core/InputLabel'

type Props = {
  map: mapboxgl.Map,
}

type State = {
  parameters: {},
}

const Source = {
  id: "nugraph-source",
  type: "vector",
  tiles: [
    // "http://localhost:8080/v1/tiles/{z}/{x}/{y}.mvt?accessToken=$TOKEN"
    "https://nugraph-tiles.graphmasters.net/v1/tiles/{z}/{x}/{y}.mvt?accessToken="
  ]
}

const SymbolLayer = {
  id: "nugraph-symbol-layer",
  minzoom: 15,
  type: "symbol",
  layout: {
    "text-font": [
      "turnicons Regular"
    ],
    "text-field": "{turnCommand}",
    "text-size": {
      "type": "interval",
      "stops": [
        [
          12,
          17
        ],
        [
          13,
          16
        ],
        [
          14,
          15
        ],
        [
          20,
          14
        ]
      ]
    },
    "text-offset": [0, 0],
    "text-rotate": [
      "+",
      0.0,
      [
        "get",
        "angle"
      ]
    ],
    "text-allow-overlap": true
  },
  paint: {
    "text-color": [
      "case",
      ["==",["get","suppressed"],true],
      "#f54242",
      "#12c76c"
    ]
  },
  filter: [
    "==",
    "$type",
    "Point"
  ],
  "source-layer": "nugraph",
  source: "nugraph-source"
}

const ZoomInLayer = {
  "id": "nugraph-zoom-in-layer",
  "type": "symbol",
  "maxzoom": 15,
  "layout": {
    "text-font": [
      "Open Sans Regular"
    ],
    "text-field": "Zoom in to view turn",
    "text-size": {
      "type": "interval",
      "stops": [
        [
          12,
          25
        ],
        [
          13,
          23
        ],
        [
          14,
          20
        ],
        [
          20,
          19
        ]
      ]
    }
  },
  "paint": {
    "text-color": "#12c76c"
  },
  "source-layer": "tile-borders",
  "source": "nugraph-source"
}

const emptyFC = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  }
}


export class Turncommands extends React.Component<Props, State> {

  constructor (props) {
    super(props);
    this.state = {
      parameters: {
      }
    }
  }

  componentDidMount (): void {
    const map = this.props.map

    this.addLayers()

    // map.on('mouseenter', OriginDestinationLayer, this.onOriginDestinationEnter);
    // map.on('mouseleave', OriginDestinationLayer, this.onOriginDestinationLeave);
  }

  componentWillUnmount (): void {
    this.removeLayers()
  }

  addLayers = () => {
    const map = this.props.map

    Source.tiles[0] += this.props.loginCtx.token.accessToken
    let source = map.getSource(Source.id)
    if (source) {
      map.removeLayer(SymbolLayer.id)
      map.removeLayer(ZoomInLayer.id)
      map.removeSource(Source.id)
    }

    // add the route casing layer
    map.addSource(Source.id, Source);
    map.addLayer(SymbolLayer);
    map.addLayer(ZoomInLayer);
  }

  removeLayers = () => {
    const map = this.props.map

    let source = map.getSource(Source.id)
    if (source) {
      map.removeLayer(SymbolLayer.id)
      map.removeLayer(ZoomInLayer.id)
      map.removeSource(Source.id)
    }
  }

  onParamChange = key => event => {
    let params = this.state.parameters
    params[key] = event.target.value
    this.setState({
      parameters: params
    })
  }

  render () {

    return (
      <Card className={styles.body}>
        <CardContent className={styles.content}>
          <InputLabel>Turncommand</InputLabel>
        </CardContent>
      </Card>
    )
  }
}
