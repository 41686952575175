import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'

const drawerWidth = 240

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  appFrame: {
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: '100%', // `calc(100% - ${drawerWidth}px)`, // uncomment to shift map content when drawer is open
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: 0, //-drawerWidth, // uncomment to shift map content when drawer is open
  },
  'content-right': {
    marginRight: 0, //-drawerWidth, // uncomment to shift map content when drawer is open
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
})

type Props = {
  updateMenuItemProp: () => {},
  onCompletedCallback: null
}

type State = {
  open: boolean,
  selectedMenuItem: ""
}

class TopMenu extends React.Component<Props, State> {
  state = {
    open: false,
  }

  listItems = (
    <div>
      <ListItem button onClick={() => this.handleClick('Search', 'search')}>
        <ListItemText primary="Search"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Nearest neighbour', 'nearestNeighbour')}>
        <ListItemText primary="Nearest Neighbour"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Map data', 'mapData')}>
        <ListItemText primary="Explore map data"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Routing', 'routing')}>
        <ListItemText primary="Routing"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Simulation', 'simulation')}>
        <ListItemText primary="Simulation"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Tour', 'tour')}>
        <ListItemText primary="Tour"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('TomTom Flow', 'tomtom-flow')}>
        <ListItemText primary="TomTom Flow"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Parser Status', 'parser-status')}>
        <ListItemText primary="Parser Status"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Uturn Analyser', 'uturnAnalyser')}>
        <ListItemText primary="Uturn analyser"/>
      </ListItem>
      <ListItem button onClick={() => this.handleClick('Turncommand', 'turncommands')}>
        <ListItemText primary="Turncommands"/>
      </ListItem>
    </div>
  );

  componentDidMount (): void {
    this.handleClick('Routing', 'routing')
    setTimeout(() => {
      if (this.props.onCompletedCallback) {
        this.props.onCompletedCallback()
      }
    }, 500)
  }

  handleDrawerOpen = () => {
    this.setState({open: true})
  }

  handleDrawerClose = () => {
    this.setState({open: false})
  }

  handleClick = (name, val) => {
    this.props.updateMenuItemProp(val)
    this.setState({
      selectedMenuItem: name,
      open: false,
    })
  }

  render () {
    const {classes, theme} = this.props
    const {open} = this.state
    var {selectedMenuItem} = this.state

    if (!selectedMenuItem || selectedMenuItem === "") {
      selectedMenuItem = "Nugraph"
    }

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes[`appBarShift-left`]]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon/>
              </IconButton>
              <Typography variant={'subtitle1'} color="inherit" noWrap>
                {selectedMenuItem}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            // variant="persistent" // uncomment to shift map content when drawer is open
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
              </IconButton>
            </div>
            <Divider/>
            <div>
              {this.listItems}
            </div>
          </Drawer>
          <main
            className={classNames(classes.content, classes[`content-left`], {
              [classes.contentShift]: open,
              [classes[`contentShift-left`]]: open,
            })}
          >
            {this.props.content}
          </main>
        </div>
      </div>
    )
  }
}

TopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(TopMenu)
