import React from "react";
import styles from "./Routing.module.css";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

type Props = {
  id: "",
  properties: {},
}

export class StartTimesChart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let formatDuration = (val) => {
      if (val >= 60) {
        let minutes = Math.floor(val / 60)
        let seconds = Math.floor(val)%60
        return minutes + 'm' + seconds + 's'
      }
      return val.toFixed(0) + 's'
    }

    let yData = []
    for (let i = 0; i < this.props.yData.length; i++) {
      let y = {y: this.props.yData[i]}
      if (i === this.props.currentIdx) {
        y['color'] = 'red'
      }
      yData.push(y)
    }

    const options = {
      tooltip: {
        formatter: function () {
          return formatDuration(this.y)
        }
      },
      title:{
        text: undefined
      },
      series: [
        {
          data: yData,
          color: 'orange'
        },
      ],
      chart: {
        height: '50%',
        type: 'column'
      },
      legend: { enabled: false },
      plotOptions: {
        series: {
          animation: false
        }
      },
      xAxis: {
        title: {
          text: null
        },
        labels: {
          enabled: true,
        },
        categories: this.props.xData,
      },
      yAxis: [{
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            return formatDuration(this.value)
          }
        }
      }]
    }

    return (
      <div className={styles.chartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    )
  }
}