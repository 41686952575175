import React from "react";
import styles from "./NearestNeighbour.module.css";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {HistoricTurnCostHighchart} from "./HistoricTurnCostHighchart";
import {HistoricChartHighchart} from "./HistoricChartHighchart";

type Props = {
  kalman: {},
  vehicleFlow: {},
  highway: '',
  speedLimit: '',
}

type State = {
  useFlow: boolean
}

export class HistoricChart extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      useFlow: false
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onToggleChange = () => {
    let newState = !this.state.useFlow
    this.setState({
      useFlow: newState
    })
  }

  twoDP(val) {
    return Math.round(val * 100) / 100
  }

  render() {
    let highchart = null

    if (this.state.useFlow) {
      highchart = <HistoricTurnCostHighchart
        historicPattern={this.props.vehicleFlow.flow_in_vehicles_per_hour_historic_pattern}
        recentMeasurements={this.props.vehicleFlow.recent_flow_in_vehicles_per_hour}
        slotDuration={this.props.vehicleFlow.flow_time_slot_duration_seconds}
        recentTimeSlotIndex={this.props.vehicleFlow.flow_historic_pattern_recent_index}
        unit={'veh/h'}
      />
    } else {
      highchart = <HistoricChartHighchart kalman={this.props.kalman}/>
    }

    let properties = {
      'Road Class': this.props.highway,
      'Speed limit': this.props.speedLimit,
      'Current': this.twoDP(this.props.kalman.speedShortInMps * 3.6) + 'km/h',
      'Long term': this.twoDP(this.props.kalman.speedLongInMps * 3.6) + 'km/h',
      'All week': this.twoDP(this.props.kalman.speedAllWeek * 3.6) + 'km/h',
      'Working hours': this.twoDP(this.props.kalman.speedWorkingHours * 3.6) + 'km/h',
      'Free flow': this.twoDP(this.props.kalman.speedFreeFlow * 3.6) + 'km/h',
      'Measurements': this.props.kalman.totalMeasurements,
      'Last changed': this.props.kalman.lastChanged
    }

    return (
      <div className={styles.chartContainer}>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.useFlow}
              onChange={this.onToggleChange}
              color='primary'
              value='dynamic-class-name'
            />
          }
          label='vehicle flow'
          className={styles.materialSlider}
        />
        {highchart}
        <Divider light className={styles.divider}/>
        <div className={styles.infoRow}>
          {Object.keys(properties).map(function (key) {
            return (
              <div className={styles.infoBox}>
                <Typography variant={'subtitle1'} color="primary">{key}</Typography>
                <Typography
                  variant={'subtitle1'}>{properties[key]}</Typography>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}