import React from "react";
import Button from '@material-ui/core/Button/Button'
import Input from '@material-ui/core/Input'
import Card from '@material-ui/core/Card/Card'
import styles from './Tour.module.css'
import CardContent from '@material-ui/core/CardContent/CardContent'
import mapboxgl from "mapbox-gl";
import type {LoginContextType} from "../../LoginContext";
import {grpc} from "@improbable-eng/grpc-web";
import {Geometry, GetTourRequest, VehicleType} from '../routing/routing_pb';
import Typography from "@material-ui/core/Typography/Typography";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

type State = {
  tours: {},
  selectedDay: "",
  selectedVehicle: "",
  vehicleType: vehicleType.delivery
}

type Props = {
  map: mapboxgl.Map,
  loginCtx: LoginContextType
}

const vehicleType = {
  delivery: {
    val: 0,
    text: 'Delivery',
    rpc: VehicleType.DELIVERY
  },
  bicycle: {
    val: 1,
    text: 'bicycle',
    rpc: VehicleType.BICYCLE,
  },
  pedestrian: {
    val: 2,
    text: 'pedestrian',
    rpc: VehicleType.PEDESTRIAN,
  },
}

const RoutingService = require('../routing/routing_service')
const myTransport = grpc.CrossBrowserHttpTransport({withCredentials: true});
grpc.setDefaultTransport(myTransport);

const RouteSource = 'routeSource'
const RouteLayer = 'routeLayer'
const RouteCasingSource = 'routeCasingSource'
const RouteCasingLayer = 'routeCasingLayer'
const WaypointSource = 'waypointSource'
const WaypointLayer = 'waypointLayer'
const WaypointCasingSource = 'waypointCasingSource'
const WaypointCasingLayer = 'waypointCasingLayer'
const WaypointTextSource = 'waypointTextSource'
const WaypointTextLayer = 'waypointTextLayer'
const emptyFC = {
  type: 'geojson',
  lineMetrics: true,
  data: {
    type: 'FeatureCollection',
    features: []
  }
}

export class Tour extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      tours: {},
      selectedDay: "",
      selectedVehicle: "",
      vehicleType: vehicleType.delivery
    }
    this.fileReader = null
  }

  componentDidMount() {
    const map = this.props.map
    this.removeSources()

    map.addSource(RouteCasingSource, emptyFC)
    map.addLayer({
      'id': RouteCasingLayer,
      'type': 'line',
      'source': RouteCasingSource,
      'paint': {
        'line-color': "#171717",
        'line-width': 6,
      },
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'filter': ['==', '$type', 'LineString']
    });
    map.addSource(RouteSource, emptyFC)
    map.addLayer({
      'id': RouteLayer,
      'type': 'line',
      'source': RouteSource,
      'paint': {
        'line-color': "#001d9e",
        'line-width': 4,
        'line-gradient': [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'blue',
          0.1,
          'royalblue',
          0.3,
          'cyan',
          0.5,
          'lime',
          0.7,
          'yellow',
          1,
          'red'
        ]
      },
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'filter': ['==', '$type', 'LineString']
    });
    map.addSource(WaypointCasingSource, emptyFC)
    map.addLayer({
      'id': WaypointCasingLayer,
      'type': 'circle',
      'source': WaypointCasingSource,
      'paint': {
        'circle-color': "#171717",
        'circle-radius': 12
      },
      'filter': ['==', '$type', 'Point']
    });
    map.addSource(WaypointSource, emptyFC)
    map.addLayer({
      'id': WaypointLayer,
      'type': 'circle',
      'source': WaypointSource,
      'paint': {
        'circle-color': "#f8fbff",
        'circle-radius': 10
      },
      'filter': ['==', '$type', 'Point']
    });
    map.addSource(WaypointTextSource, emptyFC)
    map.addLayer({
      'id': WaypointTextLayer,
      'type': 'symbol',
      'source': WaypointTextSource,
      'paint': {
        'text-color': 'black',
      },
      'layout': {
        'text-field': ['get', 'stopIndex'],
        'text-size': 12,
        'text-font': ['Open Sans Regular'],
        'text-allow-overlap': true,
      },
      'filter': ['==', '$type', 'Point']
    });
  }

  componentWillUnmount() {
    this.resetState()
    this.removeSources()

  }

  handleUpload = (e) => {
    this.resetState()

    let file = e.target.files[0]
    if (!file) {
      console.log('could not upload file')
      return
    }
    let fileReader = new FileReader();
    fileReader.onload = this.handleFileData(fileReader)
    fileReader.readAsText(file);
  }

  handleFileData = (fileReader) => {
    this.fileReader = fileReader
    return this.grpcRequest
  }

  grpcRequest = () => {
    let getTourRequest = new GetTourRequest()
    getTourRequest.setTourData(this.fileReader.result)
    getTourRequest.setVehicleType(this.state.vehicleType.rpc)

    let metadata = new grpc.Metadata()
    metadata.set('Authorization', 'Bearer ' + this.props.loginCtx.token.accessToken)

    grpc.unary(RoutingService.RoutingService.GetTour, {
      request: getTourRequest,
      host: process.env.REACT_APP_BFF_GRPC,
      metadata: metadata,
      onEnd: res => {
        const {status, statusMessage, headers, message, trailers} = res;
        // console.log('client.onEnd', status, message, trailers);
        if (status !== grpc.Code.OK) {
          console.log(message)
          return
        }
        let tours = this.unmarshalProto(message)
        this.setState({
          tours: tours
        }, this.updateRoute)
      },
    });
  }

  unmarshalProto = (getTourResponse) => {
    let toursObj = {
      days: {}
    }
    let days = getTourResponse.getDaysList()

    // day loop
    for (let i = 0; i < days.length; i++) {
      let tours = days[i].getToursList()
      let dayObj = {
        tours: {}
      }
      // vehicle loop
      for (let j = 0; j < tours.length; j++) {
        let tour = tours[j].getRoute()
        if (!tour) {
          continue
        }
        let routeFeatureCollection = {
          type: 'FeatureCollection',
          features: new Array()
        }
        let waypointFeatureCollection = {
          type: 'FeatureCollection',
          features: new Array()
        }
        let mapBounds = this.initMapBounds()
        // waypoint loop
        let waypoints = tours[j].getWaypointsList()
        for (let k = 0; k < waypoints.length; k++) {
          waypointFeatureCollection.features.push(this.pbfToFeature(waypoints[k], mapBounds))
        }
        routeFeatureCollection.features.push(this.pbfToFeature(tour, mapBounds))
        dayObj.tours[tours[j].getVehicleId()] = {
          route: routeFeatureCollection,
          waypoints: waypointFeatureCollection,
          mapBounds: mapBounds
        }
      }
      toursObj.days[days[i].getDay()] = dayObj
    }

    return toursObj
  }

  pbfToFeature = (feature, mapBounds) => {
    let geom = feature.getGeometry()
    let geomType = geom.getCoordinatesCase()
    let coordinates = []
    switch (geomType) {
      case Geometry.CoordinatesCase.POINT:
        coordinates = geom.getPoint().toObject().lngLatList
        this.updateMapBounds(mapBounds, geom.getPoint().getLngLatList()[1], geom.getPoint().getLngLatList()[0])
        break
      case Geometry.CoordinatesCase.LINE_STRING:
        let list = geom.getLineString().toObject().pointsList
        for (let i = 0; i < list.length; i++) {
          coordinates.push(list[i].lngLatList)
          // this.updateMapBounds(mapBounds, list[i].lngLatList[1], list[i].lngLatList[0])
        }
        break
      default:
        console.log('unsupported geometry type', geomType)
        return
    }

    return {
      type: feature.getType(),
      properties: feature.getProperties().toObject(),
      geometry: {
        type: geom.toObject().type,
        coordinates: coordinates,
      },
    }
  }

  onSelectedDayChange = (e) => {
    this.setState({
      selectedDay: e.target.value
    }, this.updateRoute)
  }

  onSelectedVehicleChange = (e) => {
    this.setState({
      selectedVehicle: e.target.value
    }, this.updateRoute)
  }

  updateRoute = () => {
    let selectedDay = this.state.selectedDay
    if (!selectedDay) {
      selectedDay = this.sortedDays()[0]
    }

    let selectedVehicle = this.state.selectedVehicle
    if (!selectedVehicle) {
      selectedVehicle = this.sortedVehicles(selectedDay)[0]
    }

    const map = this.props.map
    let route = this.state.tours.days[selectedDay].tours[selectedVehicle]
    if (!route || !route.route) {
      return
    }

    let routeSource = map.getSource(RouteSource)
    if (routeSource) {
      routeSource.setData(route.route)
    }
    let routeCasingSource = map.getSource(RouteCasingSource)
    if (routeCasingSource) {
      routeCasingSource.setData(route.route)
    }
    let waypointSource = map.getSource(WaypointSource)
    if (waypointSource) {
      waypointSource.setData(route.waypoints)
    }
    let waypointCasingSource = map.getSource(WaypointCasingSource)
    if (waypointCasingSource) {
      waypointCasingSource.setData(route.waypoints)
    }
    let waypointTextSource = map.getSource(WaypointTextSource)
    if (waypointTextSource) {
      waypointTextSource.setData(route.waypoints)
    }

    if (route.route.features.length === 0) {
      return
    }
    this.setMapBounds(route.mapBounds)
  }

  resetState = () => {
    this.setState({
      tours: {},
      selectedDay: "",
      selectedVehicle: ""
    })
  }

  removeSources = () => {
    const map = this.props.map
    let routeSource = map.getSource(RouteSource)
    if (routeSource) {
      map.removeLayer(RouteLayer)
      map.removeSource(RouteSource)
    }
    let routeCasingSource = map.getSource(RouteCasingSource)
    if (routeCasingSource) {
      map.removeLayer(RouteCasingLayer)
      map.removeSource(RouteCasingSource)
    }
    let waypointSource = map.getSource(WaypointSource)
    if (waypointSource) {
      map.removeLayer(WaypointLayer)
      map.removeSource(WaypointSource)
    }
    let waypointCasingSource = map.getSource(WaypointCasingSource)
    if (waypointCasingSource) {
      map.removeLayer(WaypointCasingLayer)
      map.removeSource(WaypointCasingSource)
    }
    let waypointTextSource = map.getSource(WaypointTextSource)
    if (waypointTextSource) {
      map.removeLayer(WaypointTextLayer)
      map.removeSource(WaypointTextSource)
    }
  }

  setMapBounds = (mapBounds) => {
    // check if the source and destination are visible on the screen
    const map = this.props.map
    map.fitBounds([[
      mapBounds.minLng,
      mapBounds.minLat
    ], [
      mapBounds.maxLng,
      mapBounds.maxLat
    ]], {padding: 200});
  }

  initMapBounds = () => {
    return {
      maxLat: 0,
      maxLng: 0,
      minLat: 180,
      minLng: 180
    }
  }

  updateMapBounds = (current, lat, lng) => {
    if (lat < current.minLat) {
      current.minLat = lat
    }
    if (lat > current.maxLat) {
      current.maxLat = lat
    }
    if (lng < current.minLng) {
      current.minLng = lng
    }
    if (lat > current.maxLng) {
      current.maxLng = lng
    }
  }

  sortedDays = () => {
    if (!(this.state.tours && this.state.tours.days)) {
      return []
    }
    let days = this.state.tours.days
    let daysList = []
    Object.keys(days).map(function (key) {
      daysList.push(key)
    })
    daysList.sort()
    return daysList
  }

  sortedVehicles = (selectedDay) => {
    if (!selectedDay) {
      selectedDay = this.sortedDays()[0]
    }
    if (!(this.state.tours && this.state.tours.days && this.state.tours.days[selectedDay])) {
      return []
    }
    let vehicles = this.state.tours.days[selectedDay].tours
    let vehiclesList = []
    Object.keys(vehicles).map(function (key) {
      vehiclesList.push(key)
    })
    vehiclesList.sort()
    return vehiclesList
  }

  onVehicleTypeChange = event => {
    let vehicle = {}
    Object.keys(vehicleType).map(function (key) {
      if (event.target.value == vehicleType[key].val) { // intentional `==`
        vehicle = vehicleType[key]
      }
    })
    this.setState({
      vehicleType: vehicle,
    })
  }

  render() {
    let cardContent = null
    if (this.state.tours && this.state.tours.days) {
      let days = this.sortedDays()
      let vehicles = this.sortedVehicles(this.state.selectedDay)
      let vehiclePicker = (
        <div className={styles.dayPicker}>
          <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                      noWrap>Vehicle</Typography>
          <FormControl>
            <NativeSelect
              defaultValue={vehicles[0]}
              value={this.state.selectedVehicle}
              onChange={this.onSelectedVehicleChange}
              input={<Input name='name' id='uncontrolled-native'/>}
            >
              {
                vehicles.map(function (key) {
                  return (
                    <option
                      value={key}>{key}</option>
                  )
                })
              }
            </NativeSelect>
          </FormControl>
        </div>
      )
      cardContent = (
        <Card className={styles.body}>
          <CardContent className={styles.content}>
            <div>
              <div className={styles.dayPicker}>
                <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                            noWrap>Day</Typography>
                <FormControl>
                  <NativeSelect
                    defaultValue={days[0]}
                    value={this.state.selectedDay}
                    onChange={this.onSelectedDayChange}
                    input={<Input name='name' id='uncontrolled-native'/>}
                  >
                    {
                      days.map(function (key) {
                        return (
                          <option
                            value={key}>{key}</option>
                        )
                      })
                    }
                  </NativeSelect>
                </FormControl>
              </div>
              {vehiclePicker}
            </div>
          </CardContent>
        </Card>
      )
    }

    let uploadButton = (
      <div className={styles.uploadButton}>
        <label htmlFor="contained-button-file">
          <Input style={{display: "none"}} accept=".csv" id="contained-button-file" type="file"
                 onChange={this.handleUpload}/>
          <Button variant="contained" component="span">
            Upload CSV
          </Button>
        </label>
      </div>
    )

    let vehicleSelect = (
      <div className={styles.vehiclePicker}>
        <FormControl>
          <NativeSelect
            defaultValue={vehicleType.delivery.val}
            value={this.state.vehicleType.val}
            onChange={this.onVehicleTypeChange}
            input={<Input name='name' id='uncontrolled-native'/>}
          >
            {
              Object.keys(vehicleType).map(function (key) {
                console.log(vehicleType[key].text)
                return (
                  <option
                    value={vehicleType[key].val}>{vehicleType[key].text}</option>
                )
              })
            }
          </NativeSelect>
        </FormControl>
      </div>
    )

    return (
      <div>
        {vehicleSelect}
        {uploadButton}
        {cardContent}
      </div>
    )
  }
}