//@flow
import React from 'react'
import styles from './MapContainer.module.css'
import mapboxgl from 'mapbox-gl'
import {Search} from './search/Search'
import {NearestNeighbour} from './nearestneighbour/NearestNeighbour'
import {MapData} from './mapdata/MapData'
import {Tour} from './tour/Tour'
import {Routing} from './routing/Routing'
import {Simulation} from './simulation/Simulation'
import type {LoginContextType} from '../LoginContext'
import {FlowData} from './tomtom/FlowData'
import {ParserStatus} from './parserstatus/ParserStatus'
import FormControl from '@material-ui/core/FormControl/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from '@material-ui/core/Radio/Radio'
import axios from "axios";
import SkyLight from 'react-skylight'
import Switch from '@material-ui/core/Switch'
import Fab from '@material-ui/core/Fab';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import Button from "@material-ui/core/Button";
import { Turncommands } from './turncommands/Turncommands'
import TrafficLayerVector from './layers/TrafficLayer'
import {UturnAnalyser} from "./uturnAnalyser/UturnAnalyser";
import TrafficLightsLayerVector from "./layers/TrafficLightsLayer";
import * as constants from '../constants'
import {SALZBURG_LOCATION} from './salzburgLocations.js'

type Props = {
  overlayName: string,
  loginCtx: LoginContextType
}

type State = {
  map: ?mapboxgl.Map,
  showLayers: boolean,
  showParsed: boolean,
  value: "",
}

const TrafficSource = 'trafficSource'
const TrafficLayer = 'trafficLayer'
const ClosureSource = 'closureSource'
const ClosureLayer = 'closureLayer'
const AreaIdsSource = 'areaIdsSource'
const AreaIdsLayer = 'areaIdsLayer'
const ParsedSource = 'parsedSource'
const ParsedLayer = 'parsedLayer'
const PartitionsSource = 'partitionsSource'
const PartitionsLayer = 'partitionsLayer'
const LineColorProp = 'line-color'

const RouteLineSource = 'routingLineSource'
const RouteLineLayer = 'routingLineLayer'
const RouteCasingSource = 'routingCasingSource'
const RouteCasingLayer = 'routingCasingLayer'
const ComparisonLineSource = 'comparisonLineSource'
const ComparisonLineLayer = 'comparisonLineLayer'
const CurrentLocationSource = 'currentLocationSource'
const CurrentLocationLayer = 'currentLocationLayer'

const StyleMapbox = 'mapbox'
const StyleNugraph = 'nugraph'
const StyleOSM = 'osm'

const emptyFC = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  }
}

const trafficLineColor = [
  "interpolate",
  ["linear"],
  ["get", "flow"],
  -1, "#000000",
  0, "red",
  0.5, "yellow",
  1, "lime"
]

// units are kph
const speedLineColor = [
  "interpolate",
  ["linear"],
  ["get", "flow"],
  0, "red",
  40, "yellow",
  80, "lime",
  120, "cyan",
  160, "blue",
  200, "pink"
]

const closuresLineColor = "red"

const mapboxStyleSrc = {
  mapbox: process.env.REACT_APP_MAPBOX_STYLE_NAME,
  osm: 'https://tiles.graphmasters.net/styles/basic-preview/style.json'
}

const bboxQueryType = {
  traffic: 0,
  speeds: 1,
  closures: 2,
  areaIds: 3
}

/*
*
*/
export default class MapContainer extends React.Component<Props, State> {

  state = {
    map: null,
    showLayers: false,
    showParsed: false,
    parsedRegions: [],
    failedRegions: [],
    partitions: [],
    value: StyleMapbox,
    showPartitions: false,
    showLiveTraffic: false,
    showTrafficTiles: false,
    showTrafficLights: false,
    showAreaIds: false,
    errorMsg: '',
    clearButtonDisplay: "none",
    closureFc: {},
  }

  constructor(props) {
    super(props)
    this.updateState = this.updateState.bind(this);

    this.trafficPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    this.closurePopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    this.partitionPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.getTrafficContext = new AbortController();

    this.errorDialog = React.createRef();
  }

  componentDidMount() {
    let centerPoint = process.env.REACT_APP_START_CENTER
    let centerPointSplitted = centerPoint.split(",")
    let centerLat = parseFloat(centerPointSplitted[0])
    let centerLng = parseFloat(centerPointSplitted[1])

    //TODO this is not our mapbox key. Would it be best practice if the mapbox key would come the bff?
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY
    const map = new mapboxgl.Map({
      container: 'map',
      style: mapboxStyleSrc[this.state.value],
      center: [centerLng, centerLat], // starting position [lng, lat]
      zoom: 13, // starting zoom
      transformRequest: (url, resourceType) => {
        if (resourceType === 'Tile' && url.startsWith('https://traffic-tiles.graphmasters.net')) {
          return {
            url: url,
            headers: { 'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken }
          }
        }
      }
    });

    map.on('style.load', () => {
      this.setState({
        showLayers: true
      })

      // add the traffic layer
      map.addSource(TrafficSource, emptyFC)
      map.addLayer({
        'id': TrafficLayer,
        'type': 'line',
        'source': TrafficSource,
        'paint': {
          'line-color': trafficLineColor,
          'line-width': 3
        }
      })

      // handle hover over a layer
      map.on('mouseenter', TrafficLayer, this.onTrafficEnter);
      map.on('mouseleave', TrafficLayer, this.onTrafficLeave);

      // add the area id layer
      map.addSource(AreaIdsSource, emptyFC)
      map.addLayer({
        'id': AreaIdsLayer,
        'type': 'line',
        'source': AreaIdsSource,
        'paint': {
          'line-color': trafficLineColor,
          'line-width': 3
        }
      })

      // handle hover over a layer
      map.on('mouseenter', AreaIdsLayer, this.onAreaIdsEnter);
      map.on('mouseleave', AreaIdsLayer, this.onAreaIdsLeave);

      // add the closure layer
      map.addSource(ClosureSource, emptyFC)
      map.addLayer({
        'id': ClosureLayer,
        'type': 'line',
        'source': ClosureSource,
        'paint': {
          'line-color': [
            'match',
            ['get', 'legacy'],
            1, '#002fa3',
            '#ff0000'
          ],
          'line-width': 3,
          'line-opacity': [
            'match',
            ['get', 'legacy'],
            1, 0.5,
            0.5
          ],
        }
      })

      // handle hover over a layer
      map.on('mouseenter', ClosureLayer, this.onClosureEnter);
      map.on('mouseleave', ClosureLayer, this.onClosureLeave);
      map.on('click', ClosureLayer, this.onClosureClick);

      // add the parsed regions layer
      map.addSource(ParsedSource, emptyFC)
      map.addLayer({
        'id': ParsedLayer,
        'type': 'fill',
        'source': ParsedSource,
        'paint': {
          'fill-color': '#ee00ff',
          'fill-opacity': 0.2,
        }
      })

      // add the partition bbox layer
      map.addSource(PartitionsSource, emptyFC)
      map.addLayer({
        'id': PartitionsLayer,
        'type': 'line',
        'source': PartitionsSource,
        'paint': {
          'line-color': '#444444',
          'line-width': 2
        }
      })

      // handle hover over a layer
      map.on('mouseenter', PartitionsLayer, this.onPartitionEnter);
      map.on('mouseleave', PartitionsLayer, this.onPartitionLeave);

      map.on('moveend', () => {
        if (this.state.showLiveTraffic) {
          this.getTraffic(bboxQueryType.traffic)
        }
        if (this.state.showAreaIds) {
          this.getTraffic(bboxQueryType.areaIds)
        }
      })
    })

    map.on('styledata', this.CheckAnythingOnMap)

    this.getParsed()
    this.getPartitions(map)
    this.getFailed(map)

    map.doubleClickZoom.disable();

    this.setState({
      map: map
    })

    // set map start position depending on if browser location is available
    // also check if the url contains a route query - so we don't move the map twice
    if ("geolocation" in navigator && !window.location.href.split('?')[1]) {
      navigator.geolocation.getCurrentPosition(position => {
        map.setCenter([
          position.coords.longitude,
          position.coords.latitude
        ])
      })
    }

    SALZBURG_LOCATION.forEach(location => {
      // Create a container div for each marker and label.
      const container = document.createElement('div');
      container.className = 'marker-container';

      // Create a span for the label.
      const label = document.createElement('span');
      label.className = 'marker-label';
      label.innerText = location.name;

      // Append the label to the container.
      container.appendChild(label);

      new mapboxgl.Marker(container)
          .setLngLat([location.lng, location.lat])
          .addTo(map);
    });
  }

  componentWillUnmount() {
    this.cancelGetTrafficContext()
  }

  updateState(val) {
    this.state.map.jumpTo({
      center: [val.lng, val.lat]
    })
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    })
    switch (e.target.value) {
      case "nugraph":
        this.state.map.setStyle(constants.resourcesURL + 'style.json')
        break
      case "mapbox":
        this.state.map.setStyle(process.env.REACT_APP_MAPBOX_STYLE_NAME)
        break
      case "osm":
        this.state.map.setStyle(constants.resourcesURL + 'style-osm-tiles.json')
        const partitionsSource = this.state.map.getSource(ParsedSource)
        if (partitionsSource) {
          this.state.map.removeLayer(PartitionsLayer)
          this.state.map.removeSource(PartitionsSource)
        }
        break
      default:
        break
    }
  }

  getParsed = () => {
    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getParsed'
    }
    axios(config)
      .then((resp) => {
        this.setState({
          parsedRegions: resp.data
        })
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  getFailed = (map) => {
    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getFailed'
    }
    axios(config)
      .then((resp) => {
        this.setState({
          failedRegions: resp.data
        })
        this.drawFailed(map)
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  getPartitions = (map) => {
    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getPartitions'
    }
    axios(config)
      .then((resp) => {
        this.setState({
          partitions: resp.data
        })
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  drawFailed = (map) => {
    this.state.failedRegions.forEach(function (reg) {
      let id = reg.minLat + "," + reg.minLng + ";" + reg.maxLat + "," + reg.maxLng
      // add the bounding box
      map.addLayer({
        'id': id,
        'type': 'fill',
        'source': {
          'type': 'geojson',
          'data': {
            'type': 'Polygon',
            'coordinates': [[
              [reg.minLng, reg.minLat],
              [reg.minLng, reg.maxLat],
              [reg.maxLng, reg.maxLat],
              [reg.maxLng, reg.minLat],
              [reg.minLng, reg.minLat]
            ]]
          }
        },
        'layout': {},
        'paint': {
          'fill-color': '#8e8e8e',
          'fill-opacity': 0.5
        }
      })

      // add a label
      map.addLayer({
        'id': 'label_' + id,
        'type': 'symbol',
        'source': {
          'type': 'geojson',
          'data': {
            'type': 'Point',
            'coordinates': [0.5 * (reg.minLng + reg.maxLng), 0.5 * (reg.minLat + reg.maxLat)]
          }
        },
        'layout': {
          'icon-image': 'information-15',
          'icon-size': 10,
          'text-field': 'not parsed',
          'text-size': 10,
          'text-font': ['Open Sans Regular'],
        },
        'paint': {
          'text-color': 'black'
        }
      })

      // set the label size to scale with zoom level
      map.setLayoutProperty('label_' + id, 'text-size', [
        "interpolate",
        ["linear"],
        ["zoom"],
        6, 6,
        10, 30
      ]);
    })
  }

  drawParsed = (map) => {
    map.getSource(ParsedSource).setData(this.state.parsedRegions)
  }

  onParsedToggle = () => {
    const map = this.state.map
    let showParsed = !this.state.showParsed
    this.setState({
      showParsed: showParsed
    })

    if (showParsed) {
      this.drawParsed(map)
    } else {
      map.getSource(ParsedSource).setData(emptyFC.data)
    }
  }

  drawPartitions = (map) => {
    let features = {
      'type': 'FeatureCollection',
      'features': []
    }

    this.state.partitions.forEach(function (reg) {
      // add the bounding box
      features.features.push({
        'type': 'Feature',
        'properties': {
          'minLat': reg.min_lat,
          'minLng': reg.min_lng,
          'maxLat': reg.max_lat,
          'maxLng': reg.max_lng
        },
        'geometry': {
          'type': 'LineString',
          'coordinates': [
            [reg.min_lng, reg.min_lat],
            [reg.min_lng, reg.max_lat],
            [reg.max_lng, reg.max_lat],
            [reg.max_lng, reg.min_lat],
            [reg.min_lng, reg.min_lat]
          ]
        }
      })
    })

    map.getSource(PartitionsSource).setData(features)
  }

  onPartitionEnter = (e) => {
    const map = this.state.map
    let html = null
    if (e.features[0]) {
      html = '<div>' +
        'minLat: ' + e.features[0].properties.minLat + '<br>' +
        'minLng: ' + e.features[0].properties.minLng + '<br>' +
        'mixLat: ' + e.features[0].properties.maxLat + '<br>' +
        'maxLng: ' + e.features[0].properties.maxLng +
        '</div>'
    }
    map.getCanvas().style.cursor = 'pointer';
    this.partitionPopup.setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(map);
  }

  onPartitionLeave = (e) => {
    const map = this.state.map
    map.getCanvas().style.cursor = '';
    this.partitionPopup.remove()
  }

  onPartitionToggle = () => {
    const map = this.state.map
    let showPartitions = !this.state.showPartitions
    this.setState({
      showPartitions: showPartitions
    })

    if (showPartitions) {
      this.drawPartitions(map)
    } else {
      map.getSource(PartitionsSource).setData(emptyFC.data)
    }
  }

  onTrafficEnter = (e) => {
    const map = this.state.map
    let html = null
    if (e.features[0]) {
      html = 'speed: ' + e.features[0].properties.speed + '<br> speed limit: ' + e.features[0].properties.speedLimit +
        '<br> capacity: ' + e.features[0].properties.capacity + '<br> current: ' + e.features[0].properties.current
      if (e.features[0].properties.flow === -1) {
        html = 'no data'
      }
    }
    map.getCanvas().style.cursor = 'pointer';
    this.trafficPopup.setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(map);
  }

  onTrafficLeave = (e) => {
    const map = this.state.map
    map.getCanvas().style.cursor = '';
    this.trafficPopup.remove()
  }

  onAreaIdsEnter = (e) => {
    const map = this.state.map
    let html = null
    if (e.features[0]) {
      html = 'area id: ' + e.features[0].properties.areaId + '<br> highway: ' + e.features[0].properties.highway
      if (e.features[0].properties.access) {
        html += '<br> access: ' + e.features[0].properties.access
      }
      if (e.features[0].properties.trackType) {
        html += '<br> track type: ' + e.features[0].properties.trackType
      }
    }
    map.getCanvas().style.cursor = 'pointer';
    this.trafficPopup.setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(map);
  }

  onAreaIdsLeave = (e) => {
    const map = this.state.map
    map.getCanvas().style.cursor = '';
    this.trafficPopup.remove()
  }

  onClosureEnter = (e) => {
    const map = this.state.map
    let html = 'click to see data in console'
    map.getCanvas().style.cursor = 'pointer';
    this.closurePopup.setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(map);
  }

  onClosureLeave = (e) => {
    const map = this.state.map
    map.getCanvas().style.cursor = '';
    this.closurePopup.remove()
  }

  onClosureClick = (e) => {
    let map = this.state.map
    console.log(e.features)
    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/getClosure?uid=' + e.features[0].properties.uid
    }
    axios(config)
      .then((resp) => {
        if(resp.data.features && resp.data.features[0] && resp.data.features[0].properties && resp.data.features[0].properties.metadata) {
          console.log(JSON.parse(resp.data.features[0].properties.metadata))

          let fc = this.state.closureFc
          resp.data.features.shift()
          for (let i = 0; i < resp.data.features.length; i++) {
            fc.features.push(resp.data.features[i])
          }
          map.getSource(ClosureSource).setData(fc)

        } else {
          console.log('no data available for closure')
        }
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  onTrafficToggle = () => {
    this.cancelGetTrafficContext()

    let liveTraffic = !this.state.showLiveTraffic
    this.setState({
      showLiveTraffic: liveTraffic,
      showTrafficTiles: false,
      showClosures: false,
      showAreaIds: false
    })

    if (liveTraffic) {
      this.removeSource(ClosureSource)
      this.removeSource(AreaIdsSource)
      this.getTraffic(bboxQueryType.traffic)
    } else {
      this.removeSource(TrafficSource)
    }
  }

  onClosuresToggle = () => {
    let closures = !this.state.showClosures
    this.setState({
      showLiveTraffic: false,
      showTrafficTiles: false,
      showClosures: closures,
      showAreaIds: false
    })

    if (closures) {
      this.getClosures()
      this.removeSource(TrafficSource)
      this.removeSource(AreaIdsSource)
    } else {
      this.removeSource(ClosureSource)
    }
  }

  onAreaIdsToggle = () => {
    this.cancelGetTrafficContext()

    let areaIds = !this.state.showAreaIds
    this.setState({
      showLiveTraffic: false,
      showTrafficTiles: false,
      showClosures: false,
      showAreaIds: areaIds
    })

    if (areaIds) {
      this.getTraffic(bboxQueryType.areaIds)
      this.removeSource(TrafficSource)
      this.removeSource(ClosureSource)
    } else {
      this.removeSource(AreaIdsSource)
    }
  }

  onTrafficTilesToggle = () => {
    let showTrafficTiles = !this.state.showTrafficTiles
    this.setState({
      showTrafficTiles: showTrafficTiles,
      showClosures: false,
      showLiveTraffic: false
    })

    if (showTrafficTiles) {
      this.removeSource(ClosureSource)
      this.removeSource(TrafficSource)
    }
  }

  removeSource = (id) => {
    let map = this.state.map
    let source = map.getSource(id)
    if (source) {
      source.setData(emptyFC.data)
    }
  }

  onTrafficLightsToggle = () => {
    let showTrafficLights = !this.state.showTrafficLights
    this.setState({
      showTrafficLights: showTrafficLights,
    })
  }

  getCurrentLocation = () => {
    const map = this.state.map
    navigator.geolocation.getCurrentPosition(position => {
      map.flyTo({
        center: [
          position.coords.longitude,
          position.coords.latitude
        ]
      });
    });
  }

  getClosures = () => {
    const map = this.state.map

    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/getClosures'
    }
    axios(config)
      .then((resp) => {
        map.setPaintProperty(TrafficLayer, LineColorProp, closuresLineColor)
        map.getSource(ClosureSource).setData(resp.data)
        this.setState({
          closureFc: resp.data
        })
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  cancelGetTrafficContext = () => {
    if (this.getTrafficContext) {
      this.getTrafficContext.abort()
      // get a new context
      this.getTrafficContext = new AbortController();
    }
  }

  getTraffic = (queryType) => {
    this.cancelGetTrafficContext()

    const map = this.state.map

    let zoom = map.getZoom()
    let bounds = map.getBounds()
    let minLat = bounds._sw.lat
    let minLng = bounds._sw.lng
    let maxLat = bounds._ne.lat
    let maxLng = bounds._ne.lng

    let query = 'minLat=' + minLat + '&minLng=' + minLng + '&maxLat=' + maxLat + '&maxLng=' + maxLng + '&z=' + zoom
    switch (queryType) {
      case bboxQueryType.speeds:
        query += '&useSpeed=1'
        break
      case bboxQueryType.closures:
        query += '&closures=1'
        break
      case bboxQueryType.areaIds:
        query += '&areaIds=1'
        break
    }

    let config = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
      },
      url: process.env.REACT_APP_BFF_URL + '/v1/getTraffic?' + query,
      signal: this.getTrafficContext.signal
    }
    axios(config)
      .then((resp) => {
        let lineColor = trafficLineColor
        let source = TrafficSource
        let layer = TrafficLayer
        switch (queryType) {
        case bboxQueryType.speeds:
          lineColor = speedLineColor
          break
        case bboxQueryType.areaIds:
          lineColor = speedLineColor
          layer = AreaIdsLayer
          source = AreaIdsSource
          break
        case bboxQueryType.closures:
          lineColor = closuresLineColor
          break
        }
        map.setPaintProperty(layer, LineColorProp, lineColor)
        map.getSource(source).setData(resp.data)
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  onError = (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      return
    }
    console.log(error.response)
    this.setState({
      errorMsg: error.toString() + '\n\n' + error.response.request.response
    })
    this.errorDialog.current.show();
  }

  onClear = () => {
    const map = this.state.map

    let routeSource = map.getSource(RouteLineSource)
    if (routeSource) {
      map.removeLayer(RouteLineLayer)
      map.removeSource(RouteLineSource)
    }

    let casingSource = map.getSource(RouteCasingSource)
    if (casingSource) {
      map.removeLayer(RouteCasingLayer)
      map.removeSource(RouteCasingSource)
    }

    let comparisonSource = map.getSource(ComparisonLineSource)
    if (comparisonSource) {
      map.removeLayer(ComparisonLineLayer)
      map.removeSource(ComparisonLineSource)
    }

    let currentLocationSource = map.getSource(CurrentLocationSource)
    if (currentLocationSource) {
      map.removeLayer(CurrentLocationLayer)
      map.removeSource(CurrentLocationSource)
    }
  }

  CheckAnythingOnMap = () => {
    if (!this.state || !this.state.map) {
      this.setState({
        clearButtonDisplay: "none"
      })
      return
    }

    if (this.state.showLayers) {
      switch (this.props.overlayName) {
        case 'routing':
          this.setState({
            clearButtonDisplay: "none"
          })
          break
        default:
          const map = this.state.map
          let routeSource = map.getSource(RouteLineSource)
          let casingSource = map.getSource(RouteCasingSource)
          let comparisonSource = map.getSource(ComparisonLineSource)
          let currentLocationSource = map.getSource(CurrentLocationSource)
          if (routeSource || casingSource || comparisonSource || currentLocationSource) {
            this.setState({
              clearButtonDisplay: "inherit"
            })
          } else {
            this.setState({
              clearButtonDisplay: "none"
            })
          }
      }
    } else {
      this.setState({
        clearButtonDisplay: "none"
      })
    }
  }

  toggles = [
    {text: 'live traffic', stateId: 'showLiveTraffic', onChange: this.onTrafficToggle},
    {text: 'live speed', stateId: 'showTrafficTiles', onChange: this.onTrafficTilesToggle},
    {text: 'closures', stateId: 'showClosures', onChange: this.onClosuresToggle},
    {text: 'area ids', stateId: 'showAreaIds', onChange: this.onAreaIdsToggle},
    {text: 'traffic lights', stateId: 'showTrafficLights', onChange: this.onTrafficLightsToggle},
    {text: 'show osm partitions', stateId: 'showPartitions', onChange: this.onPartitionToggle},
    {text: 'show parsed regions', stateId: 'showParsed', onChange: this.onParsedToggle},
  ]

  render() {
    let overlay = null
    let mapStyle = styles.map
    let formControlStyle = styles.styleChooserBody
    if (this.state.showLayers) {
      switch (this.props.overlayName) {
        case 'search':
          overlay = <Search map={this.state.map} loginCtx={this.props.loginCtx} updateStateProp={this.updateState}/>
          break
        case 'nearestNeighbour':
          overlay = <NearestNeighbour map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'mapData':
          overlay = <MapData map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'uturnAnalyser':
          overlay = <UturnAnalyser map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'routing':
          overlay = <Routing map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'simulation':
          overlay = <Simulation map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'tour':
          overlay = <Tour map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'tomtom-flow':
          overlay = <FlowData map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        case 'parser-status':
          overlay = <ParserStatus loginCtx={this.props.loginCtx}/>
          mapStyle = styles.hidden
          formControlStyle = styles.hidden
          break
        case 'turncommands':
          overlay = <Turncommands map={this.state.map} loginCtx={this.props.loginCtx}/>
          break
        default:
          overlay = null
      }
    }

    let layerToggles = this.toggles.map(toggle => (
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={this.state[toggle.stateId]}
              onChange={toggle.onChange}
              color="primary"
              value="dynamic-class-name"
            />
          }
          style={{marginRight: '0px'}}
          className={styles.materialSlider}
        />
        <div className={styles.partitionToggleText}>{toggle.text}</div>
      </div>
    ))

    return (
      <div className={styles.body}>
        <SkyLight hideOnOverlayClicked
                  dialogStyles={{width: '30%', height: '10px', marginTop: '-300px', marginLeft: '0%'}}
                  ref={this.errorDialog} title="Error getting status">
          <div style={{'white-space': 'pre-wrap'}}>
            {this.state.errorMsg}
          </div>
        </SkyLight>
        <div id="map" className={mapStyle}/>
        {overlay}
        <Fab color="primary" aria-label="locate" className={styles.location} onClick={this.getCurrentLocation}>
          <GpsFixedIcon/>
        </Fab>
        <div className={formControlStyle}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Style"
              name="style1"
              value={this.state.value}
              onChange={this.handleChange}
            >
              <FormControlLabel value={StyleNugraph} control={<Radio/>} label="Nugraph"/>
              <FormControlLabel value={StyleMapbox} control={<Radio/>} label="mapbox"/>
              <FormControlLabel value={StyleOSM} control={<Radio/>} label="OSM"/>
            </RadioGroup>
          </FormControl>
          {layerToggles}
          <div>
            <Button variant="contained" onClick={this.onClear}
                    className={styles.clearButton} color="primary"
                    style={{display: this.state.clearButtonDisplay}}
                    size="small">Clear Route</Button>
          </div>
        </div>
        {this.state.showTrafficTiles && <TrafficLayerVector map={this.state.map}/>}
        {this.state.showTrafficLights && <TrafficLightsLayerVector map={this.state.map}/>}
      </div>
    )
  }
}
