//@flow
import React from 'react'
import axios from 'axios'

export type Token = {
  accessToken: string,
  expiresIn: number
}

export type LoginContextType = {
  token: ?Token,
  isLoggedIn: () => boolean,
  attemptLogin: (string, string, () => void) => void,
}

type LoginResponseType = {
  ok: boolean,
  tokenResp: Token
}

const authenticationURL = process.env.REACT_APP_BFF_URL + '/auth/authenticate'

export function createDefaultLoginContext (onContextUpdated: (LoginContextType) => void): LoginContextType {
  return {
    token: null,
    expiration: null,
    isLoggedIn: function () {
      return this.token != null
    },
    attemptLogin: function (username: string, password: string, onError: () => void) {
      axios.get(authenticationURL, {
        auth: {
          username: username,
          password: password
        },
      }).then(res => {
        const data: LoginResponseType = res.data
        if (data.ok) {
          const newContext = {...this, token: data.tokenResp}
          onContextUpdated(newContext)
        }
      }).catch(error => {
        onError()
      })
    }
  }

}

const LoginContext = React.createContext(createDefaultLoginContext(null))
export default LoginContext