import React from "react";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import styles from "./NearestNeighbour.module.css";

type Props = {
  k: '',
  value: null,
}

export class PropertyRow extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let cellStyle = styles.cell
    if (this.props.k === 'geometry') {
      cellStyle = styles.geometryCell
    }

    return (
      <TableRow key={this.props.k}>
        <TableCell className={styles.cell} component="th" scope="row">
          {this.props.k}
        </TableCell>
        <TableCell className={cellStyle}>
          <div className={styles.tableContent}>
            {this.props.value}
          </div>
        </TableCell>
      </TableRow>
    )
  }
}