import React from 'react'
import styles from './ParserStatus.module.css'
import type {LoginContextType} from '../../LoginContext'
import axios from 'axios'
import SkyLight from 'react-skylight'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
    loginCtx: LoginContextType
}

type State = {}

const LeftRoadSide = false
const RightRoadSide = true
const spatialServices = {
    spatiald: 'spatiald',
    spatialdloadA: 'spatialdload-a',
    spatialdloadB: 'spatialdload-b',
    spatialdlintA: 'spatialdlint-a',
    spatialdlintB: 'spatialdlint-b',
    spatialdvipA: 'spatialdvip-a',
    spatialdvipB: 'spatialdvip-b',
    gmlint: 'gmlint',
    gmload: 'gmload'
}

export class ParserStatus extends React.Component<Props, State> {

    constructor(props) {
        super(props)

        this.state = {
            statuses: [],
            failed: [],
            regionsHtml: '',
            currTime: '',
            customMinLat: 0,
            customMinLng: 0,
            customMaxLat: 0,
            customMaxLng: 0,
            customName: '',
            customRightSideOfRoad: '',
            spatialService: spatialServices.spatiald,
            readStrand: '',
            writeStrand: '',
            errorMsg: ''
        }

        this.errorDialog = React.createRef();
        this.getStatus()
        this.getRegions()

        this.ws = new WebSocket(process.env.REACT_APP_BFF_WS + '/v1/parserStatus/getStatusSocket')

        console.log(process.env.REACT_APP_BFF_WS)
        console.log(this.ws)
    }

    componentDidMount() {
        this.ws.onopen = () => {
            console.log('Parser status: sending token via websocket')
            this.ws.send(JSON.stringify({
                token: this.props.loginCtx.token.accessToken
            }));

            // on connecting, do nothing but log it to the console
            console.log('Parser status: websocket connected')
        }

        this.ws.onmessage = evt => {
            let data = JSON.parse(evt.data)
            this.setState({
                currTime: data.timestamp,
                statuses: data.statuses,
                failed: data.failed
            })
        }

        this.ws.onclose = () => {
            console.log('Parser status: web socket closed')
        }

        this.getStrands()
    }

    componentWillUnmount() {
        this.ws.close()
    }

    getRegions = () => {
        let config = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
            },
            url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getRegions'
        }
        axios(config)
            .then((resp) => {
                let regionsHtml = this.createRegionsList(resp.data)
                this.setState({
                    regionsHtml: regionsHtml
                })
            })
            .catch((error) => {
                this.onError(error)
            })
    }

    createRegionsList = (regions) => {
        let parent = this

        function createCollapsibleRow(regions) {
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].subregions) {
                    regions[i].subcontent = createCollapsibleRow(regions[i].subregions)
                }
            }
            if (regions.length > 0) {
                return (
                  <div key={regions[0].name} className={styles.wrapCollapsible}>
                      {regions.map((region) =>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{region.name}</Typography>
                                <Button className={styles.parseButton}
                                        variant='contained' onClick={(e) => {e.stopPropagation(); parent.sendParseRequest(region.url)}}>Parse</Button>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {region.subcontent}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )}
                  </div>
                )
            }
        }

        return createCollapsibleRow(regions)
    }

    sendParseRequest = (url) => {
        console.log('received parsing request: ' + url)

        axios.post(process.env.REACT_APP_BFF_URL + '/v1/parserStatus/newJob', {url: url}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken,
            }
        })
            .catch(function (error) {
                console.log(error)
            })
    }

    sendCustomParseRequest = () => {
        let name = this.state.customName
        let roadSide = this.state.customRightSideOfRoad
        let minLat = this.state.customMinLat
        let minLng = this.state.customMinLng
        let maxLat = this.state.customMaxLat
        let maxLng = this.state.customMaxLng
        console.log('received parsing request: ', name, roadSide, minLat, minLng, maxLat, maxLng)

        axios.post(process.env.REACT_APP_BFF_URL + '/v1/parserStatus/newCustomJob',
            {
                name: name,
                isRightSideOfRoad: roadSide,
                minLat: minLat,
                minLng: minLng,
                maxLat: maxLat,
                maxLng: maxLng
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken,
                }
            })
          .catch(function (error) {
              console.log(error)
          })
    }

    getStatus = () => {
        let config = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
            },
            url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getStatus'
        }
        axios(config)
            .then((resp) => {
                this.setState({
                    currTime: resp.data.timestamp,
                    statuses: resp.data.statuses,
                    failed: resp.data.failed
                })
            })
            .catch((error) => {
                this.onError(error)
            })
    }

    getStrands = () => {
        let config = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
            },
            url: process.env.REACT_APP_BFF_URL + '/v1/parserStatus/getStrands?service=' + this.state.spatialService
        }

        axios(config)
            .then((resp) => {
                this.setState({
                    readStrand: resp.data.Read,
                    writeStrand: resp.data.Write,
                })
            })
            .catch((error) => {
                this.onError(error)
            })
    }

    onError = (error) => {
        console.log(error)
        this.setState({
            errorMsg: error.toString() + '\n\n' + error.response.request.response
        })
        this.errorDialog.current.show();
    }

    onCustomRoadSideChange = event => {
        this.setState({
            customRightSideOfRoad: event.target.value
        })
    }

    onTextFieldChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    onSpatialServiceChange = event => {
        this.setState({
            spatialService: event.target.value
        }, this.getStrands)
    }

    render() {
        let statusTable = null
        if (this.state.statuses != null) {
            statusTable = <tbody>
            {this.state.statuses.map((status, index) =>
                <tr key={status.id}>
                    <td className={styles.statusTable}>{status.id}</td>
                    <td className={styles.statusTable}>{status.url}</td>
                    <td className={styles.statusTable}>{status.status}</td>
                    <td className={styles.statusTable}>{status.progress}</td>
                    <td className={styles.statusTable}>{status.time_remaining}</td>
                    <td className={styles.statusTable}>{status.start_time}</td>
                </tr>,
            )}
            </tbody>
        }

        let failedTable = null
        if (this.state.failed != null) {
            failedTable = <tbody>
            {this.state.failed.map((failed, index) =>
                <tr key={index}>
                    <td className={styles.statusTable}>{failed.country}</td>
                    <td className={styles.statusTable}>{failed.minLat}</td>
                    <td className={styles.statusTable}>{failed.minLng}</td>
                    <td className={styles.statusTable}>{failed.maxLat}</td>
                    <td className={styles.statusTable}>{failed.maxLng}</td>
                </tr>,
            )}
            </tbody>
        }

        return (
            <div className={styles.head}>
                <SkyLight hideOnOverlayClicked
                          dialogStyles={{width: '30%', height: '10px', marginTop: '-300px', marginLeft: '0%'}}
                          ref={this.errorDialog} title='Error getting status'>
                    <div style={{'white-space': 'pre-wrap'}}>
                        {this.state.errorMsg}
                    </div>
                </SkyLight>
                <h1>Parser status: {this.state.currTime}</h1>
                <div className={styles.spatialServiceRow}>
                    <FormControl className={styles.spatialServiceSelect}>
                        <InputLabel>Spatial service</InputLabel>
                        <Select
                          value={this.state.spatialService}
                          onChange={this.onSpatialServiceChange}
                        >
                            {
                                Object.keys(spatialServices).map((val) =>
                                  <MenuItem value={spatialServices[val]}>{spatialServices[val]}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    {
                        ['Read strand:', this.state.readStrand, 'Write strand:', this.state.writeStrand].map((display) =>
                          <Typography display={'inline'} className={styles.spatialServiceStrand}>
                              {display}
                          </Typography>
                        )
                    }
                </div>
                <table className={styles.statusTable}>
                    <thead>
                    <tr>
                        <th className={styles.statusTable}>Region</th>
                        <th className={styles.statusTable}>Url</th>
                        <th className={styles.statusTable}>Status</th>
                        <th className={styles.statusTable}>Progress</th>
                        <th className={styles.statusTable}>Time Remaining</th>
                        <th className={styles.statusTable}>Start Time</th>
                    </tr>
                    </thead>
                    {statusTable}
                </table>
                <h3>Failed regions</h3>
                <table className={styles.statusTable}>
                    <thead>
                    <tr>
                        <th className={styles.statusTable}>Country</th>
                        <th className={styles.statusTable}>MinLat</th>
                        <th className={styles.statusTable}>MinLng</th>
                        <th className={styles.statusTable}>MaxLat</th>
                        <th className={styles.statusTable}>MaxLng</th>
                    </tr>
                    </thead>
                    {failedTable}
                </table>
                <h3>Custom area</h3>
                <div>
                    <TextField
                      id='customName'
                      label='name'
                      className={styles.customRegionField}
                      margin='dense'
                      onChange={this.onTextFieldChange}
                    />
                    <FormControl className={styles.customRoadSideSelect}>
                        <InputLabel htmlFor='road-side-simple'>Road Side</InputLabel>
                        <Select
                          value={this.state.customRightSideOfRoad}
                          onChange={this.onCustomRoadSideChange}
                          inputProps={{
                              name: 'road-side',
                              id: 'road-side-simple',
                          }}                        >
                            <MenuItem value={LeftRoadSide}>Left</MenuItem>
                            <MenuItem value={RightRoadSide}>Right</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                      id='customMinLat'
                      label='min lat'
                      className={styles.customRegionField}
                      margin='dense'
                      onChange={this.onTextFieldChange}
                    />
                    <TextField
                      id='customMinLng'
                      label='min lng'
                      className={styles.customRegionField}
                      margin='dense'
                      onChange={this.onTextFieldChange}
                    />
                    <TextField
                      id='customMaxLat'
                      label='max lat'
                      className={styles.customRegionField}
                      margin='dense'
                      onChange={this.onTextFieldChange}
                    />
                    <TextField
                      id='customMaxLng'
                      label='max lng'
                      className={styles.customRegionField}
                      margin='dense'
                      onChange={this.onTextFieldChange}
                    />
                    <Button className={styles.customParseButton}
                            variant='contained' onClick={() => {this.sendCustomParseRequest()}}>Parse</Button>
                </div>
                <h3>Available regions</h3>
                <div>{this.state.regionsHtml}</div>
            </div>
        )
    }
}
