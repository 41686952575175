import {grpc} from '@improbable-eng/grpc-web';
import {
  CurrentTurnCommand,
  GetCurrentTurnCommandRequest,
  GetRouteRequest,
  GetRouteResponse,
  SimulationRequest,
  SimulationResponse,
  SimulationSettings,
  SimulationUpdateResponse,
  GetTourRequest,
  GetTourResponse
} from './routing_pb';

let RoutingService = (function () {
  function RoutingService() {
  }

  RoutingService.serviceName = "graphmasters.nugraph_website.route.Routing";
  return RoutingService;
}());

RoutingService.GetRoute = {
  methodName: "GetRoute",
  service: RoutingService,
  requestStream: false,
  responseStream: true,
  requestType: GetRouteRequest,
  responseType: GetRouteResponse
};

RoutingService.Simulate = {
  methodName: "Simulate",
  service: RoutingService,
  requestStream: false,
  responseStream: true,
  requestType: SimulationRequest,
  responseType: SimulationResponse
};

RoutingService.GetCurrentTurnCommand = {
  methodName: "GetCurrentTurnCommand",
  service: RoutingService,
  requestStream: false,
  responseStream: false,
  requestType: GetCurrentTurnCommandRequest,
  responseType: CurrentTurnCommand
};

RoutingService.SimulationUpdate = {
  methodName: "SimulationUpdate",
  service: RoutingService,
  requestStream: false,
  responseStream: false,
  requestType: SimulationSettings,
  responseType: SimulationUpdateResponse
};

RoutingService.GetTour = {
  methodName: "GetTour",
  service: RoutingService,
  requestStream: false,
  responseStream: false,
  requestType: GetTourRequest,
  responseType: GetTourResponse
};

function RoutingServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RoutingServiceClient.prototype.getRoute = function getRoute(requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  };
  let client = grpc.invoke(RoutingService.GetRoute, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.end.forEach(function (handler) {
        handler();
      });
      listeners.status.forEach(function (handler) {
        handler({code: status, details: statusMessage, metadata: trailers});
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RoutingServiceClient.prototype.simulate = function getRoute(requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  };
  let client = grpc.invoke(RoutingService.Simulate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.end.forEach(function (handler) {
        handler();
      });
      listeners.status.forEach(function (handler) {
        handler({code: status, details: statusMessage, metadata: trailers});
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

RoutingServiceClient.prototype.getCurrentTurnCommand = function getCurrentTurnCommand(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  let client = grpc.unary(RoutingService.GetCurrentTurnCommand, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          let err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RoutingServiceClient.prototype.simulationUpdate = function simulationUpdate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  let client = grpc.unary(RoutingService.SimulationUpdate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          let err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

export {RoutingService, RoutingServiceClient};
