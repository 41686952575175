import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import CascadeMenu from "./CascadeMenu";

const styles = {
  subMenuItem: {
    display: "flex",
    justifyContent: "space-between"
  }
};

class SubMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      anchorElement: null
    };
  }

  handleItemClick = event => {
    if (!this.anchorElement) {
      this.setState({
        anchorElement: event.currentTarget
      });
    }

    this.setState({
      menuOpen: !this.menuOpen
    });
  };

  handleSubMenuClose = () => {
    this.setState({
      menuOpen: false
    });
  };

  render() {
    const { menuItem, componentFunc, menuItems, extraDebugItems, classes } = this.props;
    const { anchorElement, menuOpen } = this.state;

    let shouldRenderExtra = menuItem.display.includes("debug")

    return (
      <React.Fragment>
        <MenuItem
          onClick={this.handleItemClick}
          onMouseOver={this.handleItemClick}
          className={classNames(classes.subMenuItem)}
        >
          {menuItem.display}
          <ArrowRightIcon />
        </MenuItem>
        <CascadeMenu
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={menuOpen}
          menuItems={menuItems}
          anchorElement={anchorElement}
          onClose={this.handleSubMenuClose}
          componentFunc={componentFunc}
          extraDebugItems={extraDebugItems}
          shouldRenderExtraDebugItems={shouldRenderExtra}
        />
      </React.Fragment>
    );
  }
}

SubMenu.propTypes = {
  menuItem: PropTypes.any,
  componentFunc: PropTypes.func.isRequired,
  classes: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  extraDebugItems: PropTypes.any
};

export default withStyles(styles)(SubMenu);
