import React from 'react'
import { Histogram, BarSeries, XAxis, YAxis } from '@data-ui/histogram';
import Typography from '@material-ui/core/Typography/Typography'

export class MyHistogram extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}
  componentWillUnmount() {}

  parseSeconds = (val) => {
    if (val > 60) {
      return Math.floor(val/60) + 'm' + Math.floor(val%60) + 's'
    } else {
      return val.toFixed(0) + 's'
    }
  }

  parseDistance = (val) => {
    if (val > 1) {
      return val.toFixed(2) + 'km'
    } else {
      val *= 1000
      return val.toFixed(0) + 'm'
    }
  }

  getStatistics = (data, comparison) => {
    if (data && data[0]) {
      let weightedSum = 0
      for (let i = 0; i < data.length; i++) {
        weightedSum += data[i]
      }
      let average = weightedSum / data.length
      let msg = this.parseDistance(average)
      if (this.props.timeXAxis) {
        msg = this.parseSeconds(average)
      }

      let description = 'Average'
      if (comparison) {
        description = 'Comparison average'
      }
      return (
        <Typography style={{marginBottom: '10px'}}>
          {description}: {msg}
        </Typography>
      )
    }
  }

  render () {
    let normalized = false
    if (this.props.normalized) {
      normalized = true
    }

    let xLabel = null
    if (this.props.xLabel) {
      xLabel = this.props.xLabel
    }

    let tickFormat = null
    let labelFormat = function(val) {return val}
    if (this.props.timeXAxis) {
      tickFormat = this.parseSeconds
      labelFormat = this.parseSeconds
    }

    let limits = null
    if (this.props.useLimits) {
      let maxValue = 1
      if (this.props.data) {
        maxValue = Math.max.apply(null, this.props.data)
        if (this.props.comparisonData) {
          let maxComparisonValue = Math.max.apply(null, this.props.comparisonData)
          maxValue = Math.max(maxValue, maxComparisonValue)
        }
        maxValue = Math.ceil(maxValue * 10) / 10
      }
      limits = [0.2, maxValue]
    } else if (this.props.simulation) {
      // this doesn't really work
      // let minValue = Math.min.apply(null, this.props.data)
      // limits = [0.85*minValue, 1.5*minValue]
    }

    let comparisonSeries = null
    if (this.props.comparisonData) {
      comparisonSeries = (<BarSeries
        animated
        fill={'#840001'}
        fillOpacity={0.3}
        rawData={this.props.comparisonData}
      />)
    }

    let statistics = null
    let comparisonStatistics = null
    if (this.props.showStats) {
      statistics = this.getStatistics(this.props.data, false)
      comparisonStatistics = this.getStatistics(this.props.comparisonData, true)
    }

    return (
      <div>
        <Histogram
          height={this.props.height}
          width={this.props.width}
          ariaLabel='My histogram'
          orientation='vertical'
          cumulative={false}
          normalized={normalized}
          limits={limits}
          binCount={15}
          valueAccessor={datum => datum}
          binType='numeric'
          renderTooltip={({ event, datum, data, color }) => (
            <div>
              <strong style={{ color }}>{labelFormat(datum.bin0)} to {labelFormat(datum.bin1)}</strong>
              <div><strong>count </strong>{datum.count}</div>
              <div><strong>cumulative </strong>{datum.cumulative}</div>
              <div><strong>density </strong>{datum.density}</div>
            </div>
          )}
        >
          <BarSeries
            animated
            fill={'#005eff'}
            fillOpacity={0.3}
            rawData={this.props.data}
          />
          {comparisonSeries}
          <XAxis
            label={xLabel}
            tickFormat={tickFormat}
          />
          <YAxis />
        </Histogram>
        {statistics}
        {comparisonStatistics}
      </div>
    );
  }
}

