//@flow
import React from 'react'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import styles from './EdgeCard.module.css'
import Typography from '@material-ui/core/Typography/Typography'
import { withEdgeCardData } from './EdgeCardHOC'
import Divider from '@material-ui/core/Divider/Divider'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export type Edge = {
  id: string,
  currentSpeed: number,
  sourceGeohash: string,
  isOneway: boolean,
  lastMeasurementTime: string,
  length: number,
  osmDataTimestamp: string,
  osmIds: [],
  roadclass: string,
  speedLimit: number,
  targetGeohash: string,
  timeLost: number,
  totalMeasurements: number
}

export type SelectedEdgeContextType = {
  edge: ?Edge,
  onSelectionChanged: (?Edge) => void
}

export const SelectedEdgeContext = React.createContext(null)

type Props = {
  edge: ?Edge,
  edgeData: {},
  start: any
}

type State = {
  timeSinceLastMeasurement: number
}

/*
*
*/
export class EdgeCard extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      timeSinceLastMeasurement: 0,
    }
  }

  componentDidMount () {
    this.timer = setInterval(this.tick, 1000)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  tick = () => {

    // This function is called every 50 ms. It updates the
    // elapsed counter. Calling setState causes the component to be re-rendered
    this.setState({timeSinceLastMeasurement: new Date() - this.props.start})
  }

  render () {
    const edge: Edge = this.props.edge
    const edgeData = this.props.edgeData

    let content = 'No edge selected'
    if (edge) {
      console.log(edge)
      console.log(edgeData)

      // Calculate elapsed to tenth of a second:
      const elapsed = Math.round(this.state.timeSinceLastMeasurement / 100)

      // This will give a number with one digit after the decimal dot (xx.x):
      const seconds = (elapsed / 10).toFixed(0)

      let now = new Date()
      let day = now.getDay()
      let hour = now.getHours()
      let minute = now.getMinutes()
      let historicSlot = day * 144 + hour * 6 + Math.floor(minute / 6)
      let historicData = edgeData.historicPattern.slice()

      if (historicSlot < historicData.length) {
        historicData[historicSlot] = {
          y: historicData[historicSlot],
          color: '#fb00aa'
        }
      }

      const options = {
        title: {
          text: 'Historic pattern'
        },
        tooltip: {
          formatter: function() {
            const day = this.x / 144
            let dayString = "Sunday"
            if (day < 1) {

            } else if (day < 2) {
              dayString = "Monday"
            } else if (day < 3) {
              dayString = "Tuesday"
            } else if (day < 4) {
              dayString = "Wednesday"
            }else if (day < 5) {
              dayString = "Thursday"
            }else if (day < 6) {
              dayString = "Friday"
            }else  {
              dayString = "Saturday"
            }

            let time = this.x % 144
            time = time * 10
            let hour = Math.floor(time / 60)
            let minutes = Math.floor(time % 60)

            return dayString + ', ' + hour + ':' + minutes + ': <b>' + this.y + ' km/h</b>';
          }
        },
        series: [{
          name: "historic",
          data: historicData,
          zones: [{
            value: 60,
            color: '#f7a35c'
          }, {
            value: 100,
            color: '#90ed7d'
          }, {
            color: '#7cb5ec'
          }]
        }],
        chart: {
          height: 200,
          type: 'column'
        },
        plotOptions: {
          series: {
            animation: false
          }
        },
        xAxis: {
          title: {
            text: null
          },
          plotLines: [{
            color: 'red', // Color value
            value: 144, // Value of where the line will appear
            width: 2 // Width of the line
          },
            {
              color: 'red', // Color value
              value: 288, // Value of where the line will appear
              width: 2 // Width of the line
            },
            {
              color: 'red', // Color value
              value: 432, // Value of where the line will appear
              width: 2 // Width of the line
            },
            {
              color: 'red', // Color value
              value: 576, // Value of where the line will appear
              width: 2 // Width of the line
            },
            {
              color: 'red', // Color value
              value: 720, // Value of where the line will appear
              width: 2 // Width of the line
            },
            {
              color: 'red', // Color value
              value: 864, // Value of where the line will appear
              width: 2 // Width of the line
            },
          ],
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: {
            text: null
          },
          labels: {
            format: '{value} km/h'
          }
        }
      }

      let histAvg = 0
      for (let i = 0; i < edgeData.historicPattern.length; i++) {
        histAvg += edgeData.historicPattern[i]
      }
      histAvg = histAvg / edgeData.historicPattern.length

      content =
        (<div className={styles.content}>
          <div className={styles.titleRow}>
            <Typography variant={'subtitle1'}>{edge.id}</Typography>
            <Typography variant={'subtitle'}>last measurement {seconds} seconds ago ({edgeData.recentMeasurementTime})</Typography>
          </div>
          <div className={styles.graph}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </div>
          <Divider light className={styles.divider}/>
          <div className={styles.infoRow}>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Road Class</Typography>
              <Typography
                variant={'subtitle1'}>{edge.roadclass}</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Speed limit</Typography>
              <Typography
                variant={'subtitle1'}>{Math.round(edge.speedLimit * 100) / 100} km/h</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Current speed</Typography>
              <Typography
                variant={'subtitle1'}>{Math.round(edgeData.speedShortInMpS * 3.6 * 100) / 100} km/h</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Long term avg</Typography>
              <Typography
                variant={'subtitle1'}>{Math.round(edgeData.speedLongInMpS * 3.6 * 100) / 100} km/h</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Historic avg</Typography>
              <Typography
                variant={'subtitle1'}>{Math.round(histAvg * 3.6 * 100) / 100} km/h</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Measurements</Typography>
              <Typography
                variant={'subtitle1'}>{edgeData.totalMeasurements}</Typography>
            </div>
            <div className={styles.infoBox}>
              <Typography variant={'subtitle1'} color="primary">Max speed</Typography>
              <Typography
                variant={'subtitle1'}>{Math.round(Math.sqrt(edgeData.speedMaxInMpS) * 3.6 * 100) / 100} km/h</Typography>
            </div>
          </div>
        </div>)
    }

    return (

      <Card className={styles.body}>
        <CardContent className={styles.content}>
          {content}
        </CardContent>
      </Card>
    )
  }
}

export default withEdgeCardData(EdgeCard)