import React from 'react'
import { Bar } from 'react-chartjs-2';

export class AccelerationChartStacked extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let travelTimeAndTurnCosts = []
    let speedPenalties = []
    let anglePenalties = []
    let highwayPenalties = []
    let labels = []
    let locations = []
    for (let i = 0; i < this.props.data.length; i++) {
      labels.push(i.toString())
      travelTimeAndTurnCosts.push(this.props.data[i].getConfigurablePenalties().getTravelTimeAndTurnCost())
      speedPenalties.push(this.props.data[i].getConfigurablePenalties().getSpeedPenalty())
      anglePenalties.push(this.props.data[i].getConfigurablePenalties().getAnglePenalty())
      highwayPenalties.push(this.props.data[i].getConfigurablePenalties().getHighwayPenalty())
      locations.push(this.props.data[i].getLocation().toObject())
    }


    let chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Travel time and turn cost',
          data: travelTimeAndTurnCosts,
          backgroundColor: 'blue',
        },
        {
          label: 'Speed penalty',
          data: speedPenalties,
          backgroundColor: 'cyan',
        },
        {
          label: 'Angle penalty',
          data: anglePenalties,
          backgroundColor: 'green',
        },
        {
          label: 'Highway penalty',
          data: highwayPenalties,
          backgroundColor: 'red',
        }
      ],
    }

    let chart = (
      <Bar
        data={chartData}
        width={this.props.width}
        height={this.props.height}
        getElementAtEvent={(elems) => {
          if (this.props.chartClickCallback && locations && elems[0] && locations[elems[0]._index]) {
            this.props.chartClickCallback(locations[elems[0]._index])
          }
        }}
        options={{
          tooltips: {
            callbacks: {
              title: (elems) => {
                if (this.props.chartClickCallback && locations && elems[0] && locations[elems[0].index]) {
                  this.props.chartClickCallback(locations[elems[0].index])
                }
                return elems.raw
              },
            }
          },
          title: {
            display: true,
            text: 'Penalties on the route'
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }}
      />
    );

    return (
      <div>{chart}</div>
    )
  }
}

