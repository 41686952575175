import React from 'react'
import styles from './Routing.module.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ReactTooltip from 'react-tooltip';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';
import {FiInfo} from 'react-icons/fi';

export class ParametersCard extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}
  componentWillUnmount() {}

  render () {
    let title = ''
    if (this.props.title) {
      title = this.props.title
    }

    let properties = []
    if (this.props.properties) {
      properties = this.props.properties
    }

    let defaultProperties = null
    if (this.props.defaultProperties) {
      defaultProperties = this.props.defaultProperties
    }

    let resetFunc = null
    if (this.props.resetFunc) {
      resetFunc = this.props.resetFunc
    }

    let resetButtonVisibility = 'hidden'
    if (properties) {
      Object.keys(properties).map(function (key) {
        if (resetButtonVisibility === 'hidden' && properties[key].value != defaultProperties[key].value) {
          resetButtonVisibility = 'visible'
        }
      })
    }

    let onChangeFunc = null
    if (this.props.onChangeFunc) {
      onChangeFunc = this.props.onChangeFunc
    }

    let disabled = false
    if (this.props.disabled) {
      disabled = this.props.disabled
    }

    return (
      <ExpansionPanel className={styles.expansionPanel} >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.truckPropsExpansionPanel}>
          <div className={styles.resetDiv} style={{'visibility': resetButtonVisibility}}>
            <Button className={styles.resetButton} variant='contained' onClick={resetFunc}>Reset</Button>
          </div>
          <Table className={styles.truckPropsTable}>
            <TableBody>
              {
                Object.keys(properties).map(function (key, rowIndex) {
                  if (rowIndex >= Object.keys(properties).length / 2) return
                  let cols = [null, null]
                  Object.keys(properties).map(function (key, propIndex) {
                    if (propIndex === 2 * rowIndex || propIndex === 2 * rowIndex + 1) {
                      cols[propIndex % 2] = (
                        <TableCell className={styles.truckPropsCell} component='th' scope='row'>
                          <div className={styles.truckProp}>
                            <Typography
                              variant={'subtitle1'}
                              style={{fontSize: '10px'}}
                              color='primary'
                              data-for={'ReactTooltip' + propIndex.toString()}
                              data-tip={properties[key].help}
                              data-iscapture={true}
                            >
                              {properties[key].display + ' '}<FiInfo className={styles.infoSVG}/>
                            </Typography>
                            <ReactTooltip id={'ReactTooltip' + propIndex.toString()} className={styles.hoverText} type={'dark'} effect={'solid'} />
                            <TextField
                              disabled={disabled}
                              value={properties[key].value}
                              onChange={onChangeFunc(key)}/>
                          </div>
                        </TableCell>
                      )
                    }
                  })
                  return (
                    <TableRow>
                      {cols[0]}
                      {cols[1]}
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

