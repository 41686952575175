import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export class StatsTable extends React.Component<Props, State> {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        let tableData = this.props.tableData

        return (
          <TableContainer>
              <Table>
                  <TableHead>
                      <TableRow>
                          {tableData.map(entry => (
                            <TableCell>{entry.title}</TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow>
                          {tableData.map(entry => (
                            <TableCell>{entry.parse(entry.val) + entry.unit}</TableCell>
                          ))}
                      </TableRow>
                  </TableBody>
              </Table>
          </TableContainer>
        )
    }
}