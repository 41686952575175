//@flow
import React from 'react'
import styles from './Search.module.css'
import Geosuggest from 'react-geosuggest'

type Props = {
  updateStateProp: () => {}
}

type State = {
}

/*
*
*/

const geosuggestStyle = {
  'input': {
    'width': '100%',
    'height': '25px'
  },
  'suggests': {
    'position': 'relative',
    'backgroundColor': 'white',
    'zIndex': '99'
  }
}

export class Search extends React.Component<Props, State> {

  onSuggestSelect = (suggest) => {
    if (suggest) {
      this.props.updateStateProp(suggest.location)
    }
  }

  render () {
    return (

      <div className={styles.body}>
        <Geosuggest
          placeholder="Start typing!"
          onSuggestSelect={this.onSuggestSelect}
          style={geosuggestStyle}
        />
      </div>
    )
  }
}