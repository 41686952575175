import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './splash-screen.json'
import styles from "./App.module.css";

export default class SplashScreenAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.start = false
    this.eventListeners = []
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className={styles.splashScreen}>
        <Lottie options={defaultOptions}
                isStopped={!this.props.start}
                isPaused={false}
                eventListeners={this.props.eventListeners}
        />
      </div>
    )
  }
}