//@flow
import React from 'react'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import styles from './FlowData.module.css'
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import { LineSelector } from '../utilities/LineSelector'
import Button from '@material-ui/core/Button/Button'
import * as constants from '../../constants'

type Props = {
  map: mapboxgl.Map,
}

type State = {
  selectedFeature: {}
}

const FlowLineSource= "flowLineSource"
const FlowLineLayer= "flowLineLayer"
const FlowLineSymbolLayer = "flowLineSymbolLayer"

/*
*
*/
export class FlowData extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.emptyFeatureCollection = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    }
    this.lineSelector = null
    this.state = {
      selectedFeature: null,
    }
  }

  componentDidMount () {
    const map = this.props.map
    map.addSource(FlowLineSource, this.emptyFeatureCollection);
    map.addLayer(this.flowLayer())

    map.loadImage(constants.resourcesURL + 'arrow.png', function(error, image) {
      if (error) throw error;
      map.addImage('arrow', image);
      map.addLayer({
        id: FlowLineSymbolLayer,
        type: 'symbol',
        source: FlowLineSource,
        'layout': {
          'icon-image': 'arrow',
          'icon-size': 0.4,
          'symbol-placement': 'line',
          'symbol-spacing': 30,
        },
        paint: {}
      });
    });

    this.lineSelector = new LineSelector(map, FlowLineLayer, FlowLineSource, this.selectFeature)
    this.lineSelector.activate()

    this.getFlowData()
  }

  componentWillUnmount(){
    const map = this.props.map
    if (this.lineSelector) {
      this.lineSelector.deactivate()
    }
    map.removeLayer(FlowLineLayer)
    map.removeLayer(FlowLineSymbolLayer)
    map.removeSource(FlowLineSource)
  }

  selectFeature = (f) => {
    this.setState({
      selectedFeature: f,
    })
  }

  getFlowData = () => {
    const bounds = this.props.map.getBounds()

    const bottomLeftStr = bounds.getSouthWest().lat+","+bounds.getSouthWest().lng
    const topRightStr = bounds.getNorthEast().lat+','+bounds.getNorthEast().lng
    const bboxUrlPart = "bottomLeft="+bottomLeftStr+"&topRight="+topRightStr

    var config = {
      method: "GET",
      url: process.env.REACT_APP_BFF_URL + "/v1/flowData?"+bboxUrlPart,
    };
    axios(config)
      .then((resp) => {
        this.setFlows(resp.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setFlows = (data) => {
    this.props.map.getSource(FlowLineSource).setData(data);
  }

  rematch = () => {
    var config = {
      method: "POST",
      url: process.env.REACT_APP_BFF_URL + "/v1/flowData/rematch?id=" + encodeURIComponent(this.state.selectedFeature.properties.id),
    };
    axios(config)
      .then((resp) => {
        this.getFlowData()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render () {
    let content = null
    let f = this.state.selectedFeature
    if (f) {
      content = (
        <Card className={styles.body}>
          <CardContent className={styles.content}>
            {f.properties.id}
            <Button variant="contained" onClick={this.rematch}>Rematch</Button>
          </CardContent>
        </Card>)
    }

    return content
  }

  flowLayer() {
    return {
      id: FlowLineLayer,
      type: 'line',
      source: FlowLineSource,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': {
          property: 'type',
          type: 'categorical',
          stops: [
            ["raw", "#ff110b"],
            ["matched", "#6043ff"],
          ]
        },
        'line-opacity': {
          property: 'type',
          type: 'categorical',
          stops: [
            ["raw", 0.5],
            ["matched", 1.0],
          ]
        },
        'line-width': 2,
      },
      //   //filter: ['==', 'id', '']
    }
  }
}
