//@flow
import React from 'react'
import mapboxgl from 'mapbox-gl'
import { withTrafficLayerData } from './TrafficLayerHOC'
import type { Edge, SelectedEdgeContextType } from '../../components/traffic/EdgeCard'
import type { LoginContextType } from '../../LoginContext'

type Props = {
  map: mapboxgl.Map,
  selectedEdgeContext: SelectedEdgeContextType,
  loginCtx: LoginContextType
}
type State = {}

const trafficSource = "traffic_source"
const trafficLayerId = 'traffic_layer'
const trafficLayerHighlightId = 'traffic_layer_highlight'
const trafficLayerSelectedId = 'traffic_layer_selected'
//const baseUrlTiles = 'http://localhost:8087'
const baseUrlTiles = 'https://traffic-tiles.graphmasters.net/v1'

/*
*
*/
class TrafficLayerVector extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.trafficPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
  }

  componentDidMount () {
    const map = this.props.map
    map.addSource(trafficSource, generateTrafficSource(this.props.loginCtx.token.accessToken))
    map.addLayer(generateTrafficLayer())
    map.on('mouseenter', trafficLayerId, this.onTrafficEnter);
    map.on('mouseleave', trafficLayerId, this.onTrafficLeave);

    // map.addLayer(generateHighlightLayer())
    // map.addLayer(generateSelectedLayer())
    //
    // map.on('click', trafficLayerId, this.onPolylineClicked)
    // map.on("mousemove", trafficLayerId, this.highlightLineFeature);
  }

  componentWillUnmount () {
    const map = this.props.map
    this.trafficPopup.remove()
    map.off('mouseenter', trafficLayerId, this.onTrafficEnter);
    map.off('mouseleave', trafficLayerId, this.onTrafficLeave);
    map.removeLayer(trafficLayerId)
    map.removeSource(trafficSource)
  }

  onTrafficEnter = (e) => {
    const map = this.props.map
    let html = null
    if (e.features[0]) {
      html = Math.round(e.features[0].properties.currentSpeed * 100) / 100 + " km/h"
      if (e.features[0].properties.currentSpeed === -1) {
        html = 'no data'
      }
    }
    map.getCanvas().style.cursor = 'pointer';
    this.trafficPopup.setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(map);
  }

  onPolylineClicked = (e) => {
    console.log(e.features[0])
    const feature = e.features[0]
    const properties = feature.properties

    const edge: Edge = {}
    edge.id = properties.edgeId
    edge.currentSpeed = properties.currentSpeed
    edge.isOneway = properties.isOneway
    edge.lastMeasurementTime = properties.lastMeasurementTime
    edge.length = properties.length
    edge.osmDataTimestamp = properties.osmDataTimestamp
    edge.osmIds = properties.osmIds
    edge.roadclass = properties.roadclass
    edge.sourceGeohash = properties.sourceGeohash
    edge.speedLimit = properties.speedLimit
    edge.targetGeohash = properties.targetGeohash
    edge.timeLost = properties.timeLost
    edge.totalMeasurements = properties.totalMeasurements

    const newContext = {...this.props.selectedEdgeContext}
    newContext.edge = edge

    this.props.selectedEdgeContext.onSelectionChanged(newContext)
    this.props.map.setFilter(trafficLayerSelectedId, ["==", "edgeId", properties.edgeId]);
  }

  highlightLineFeature = (e) => {
    // Single out the first found feature.
    var feature = e.features[0];
    this.props.map.setFilter(trafficLayerHighlightId, ["==", "edgeId", feature.properties.edgeId]);
  }

  render () {
    return (null)
  }
}

export default withTrafficLayerData(TrafficLayerVector)

function generateTrafficSource(token) {
  return {
    "tiles": [
      baseUrlTiles + "/{z}/{x}/{y}.mvt?accessToken="+token
    ],
    "type": "vector"
  }
}

function generateTrafficLayer () {
  return {
    "interactive": true,
    "type": "line",
    "source": trafficSource,
    "id": trafficLayerId,
    "paint": {
      "line-color": {
        "property": "currentSpeed",
        "stops": [
          [
            0,
            "#FF0000"
          ],
          [
            5,
            "#FF1F00"
          ],
          [
            10,
            "#FF4000"
          ],
          [
            15,
            "#FF6200"
          ],
          [
            20,
            "#FF8400"
          ],
          [
            25,
            "#FFA700"
          ],
          [
            30,
            "#FFC900"
          ],
          [
            35,
            "#FFE900"
          ],
          [
            40,
            "#F5FF00"
          ],
          [
            45,
            "#D6FF00"
          ],
          [
            50,
            "#B4FF00"
          ],
          [
            55,
            "#92FF00"
          ],
          [
            60,
            "#6FFF00"
          ],
          [
            65,
            "#4DFF00"
          ],
          [
            70,
            "#2BFF00"
          ],
          [
            75,
            "#0CFF00"
          ],
          [
            80,
            "#00FF12"
          ],
          [
            85,
            "#00FF32"
          ],
          [
            90,
            "#00FF54"
          ],
          [
            95,
            "#00FF76"
          ],
          [
            100,
            "#00FF99"
          ],
          [
            105,
            "#00FFBB"
          ],
          [
            110,
            "#00FFDC"
          ],
          [
            115,
            "#00FFFC"
          ],
          [
            120,
            "#00E2FF"
          ],
          [
            125,
            "#00C2FF"
          ],
          [
            130,
            "#00A0FF"
          ],
          [
            135,
            "#007DFF"
          ],
          [
            140,
            "#005AFF"
          ],
          [
            145,
            "#0039FF"
          ],
          [
            150,
            "#0018FF"
          ],
          [
            155,
            "#0600FF"
          ],
          [
            160,
            "#2500FF"
          ],
          [
            165,
            "#4700FF"
          ],
          [
            170,
            "#6900FF"
          ],
          [
            175,
            "#8B00FF"
          ],
          [
            180,
            "#AE00FF"
          ],
          [
            185,
            "#CF00FF"
          ],
          [
            190,
            "#EF00FF"
          ],
          [
            195,
            "#FF00EF"
          ],
          [
            200,
            "#FF00CF"
          ]
        ]
      },
      "line-width": {
        "base": 1.2,
        "stops": [
          [
            12,
            1
          ],
          [
            13,
            3
          ],
          [
            14,
            4
          ],
          [
            20,
            15
          ]
        ]
      },
      "line-opacity": 1,
      "line-offset": 2,
      // "line-offset": {
      //   "property": "isOneway",
      //   "type": "categorical",
      //   "stops": [
      //     [true, 0],
      //     [false, 2]
      //   ]
      // },
    },
    "layout": {
      "line-join": "round",
      "line-cap": "round"
    },
    "source-layer": "traffic"
  };
}

function generateHighlightLayer() {
  return {
    "id": trafficLayerHighlightId,
    "type": "line",
    "source": trafficSource,
    "layout": {
      "line-join": "round",
      "line-cap": "round"
    },
    "paint": {
      "line-color": "#ffffff",
      "line-width": 10,
      "line-opacity": 0.5,
      "line-offset": {
        property: 'isOneway',
        type: 'categorical',
        stops: [
          [true, 0],
          [false, 5],
        ]
      },
    },
    "source-layer": "traffic",
    "filter": ["==", "edgeId", ""]
  }
}

function generateSelectedLayer() {
  return {
    "id": trafficLayerSelectedId,
    "type": "line",
    "source": trafficSource,
    "layout": {
      "line-join": "round",
      "line-cap": "round"
    },
    "paint": {
      "line-color": "#000000",
      "line-width": 10,
      "line-opacity": 0.5,
      "line-offset": {
        property: 'isOneway',
        type: 'categorical',
        stops: [
          [true, 0],
          [false, 5],
        ]
      },
    },
    "source-layer": "traffic",
    "filter": ["==", "edgeId", ""]
  }
}
