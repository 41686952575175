// @flow

import createMuiTheme from '@material-ui/core/es/styles/createMuiStrictModeTheme'

export const materialUITheme = createMuiTheme({
  palette: {
    primary: {
      light: '#2770d0',
      main: '#0d47a1',
      dark: '#ac0800',
      contrastText: '#fff'
    },
    secondary: {
      light: '#bef67a',
      main: '#8bc34a',
      dark: '#5a9216',
      contrastText: '#000'
    },
    typography: {
      primary: {
        light: '#2770d0',
        main: '#0d47a1',
        dark: '#ac0800',
        contrastText: '#fff'
      }
    }
  }
})
