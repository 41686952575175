//@flow
import React from 'react'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import styles from './MapData.module.css'
import mapboxgl from 'mapbox-gl'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'

type Props = {
  map: mapboxgl.Map,
}

type State = {
  selectedFeature: {},
  script: ''
}

const SelectionHighlightLayer = 'selectionHighlightLayer'
const SelectedLayer = 'selectedLayer'
const HighlightLayer = 'highlightLayer'

/*
*
*/
export class MapData extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      selectedFeature: null,
      script: null,
    }
  }

  componentDidMount () {
    const map = this.props.map
    map.addLayer(this.getSelectionHighlightLayer())
    map.addLayer(this.getSelectedLayer())
    map.addLayer(this.getHighlightLayer())

    // register highlighters on all road layers in the given style
    let layers = this.getRoadLayers()
    layers.forEach((l, i, arr) => {
      map.on('mousemove', l, this.onMouseMove)
    })

    // register onClick event on highlight layer to select single feature
    map.on('click', SelectionHighlightLayer, this.selectFeature)
  }

  componentWillUnmount () {
    const map = this.props.map

    // unregister highlighters on all road layers in the given style
    let layers = this.getRoadLayers()
    layers.forEach((l, i, arr) => {
      map.off('mousemove', l, this.onMouseMove)
    })

    // unregister onClick event on highlight layer to select single feature
    map.off('click', SelectionHighlightLayer, this.selectFeature)

    map.removeLayer(SelectionHighlightLayer)
    map.removeLayer(SelectedLayer)
    map.removeLayer(HighlightLayer)
  }

  getRoadLayers = () => {
    var layers = []
    this.props.map.getStyle().layers.forEach(function (l, i, arr) {
      if (l.id.indexOf('road') !== -1) {
        layers.push(l.id)
      }
    })
    return layers
  }

  onMouseMove = (e) => {
    const {
      map
    } = this.props

    let f = e.features[0]
    if (f) {
      map.setFilter(SelectionHighlightLayer, ['==', 'id', f.properties.id])
    }
  }

  selectFeature = (e) => {
    const {
      map
    } = this.props

    if (e.features) {
      let f = e.features[0]
      map.setFilter(SelectedLayer, ['==', 'id', f.properties.id])
      this.setState({
        selectedFeature: f,
      })
    } else {
      console.log('no feature')
      map.setFilter(SelectedLayer, ['==', 'id', ''])
      this.setState({
        selectedFeature: null,
      })
    }
  }

  unselect = (e) => {
    this.selectFeature({})
  }

  getSelectionHighlightLayer () {
    return {
      id: SelectionHighlightLayer,
      type: 'line',
      source: 'nugraph',
      'source-layer': 'road',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#FF00FF',
        'line-width': 8,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }

  getSelectedLayer () {
    return {
      id: SelectedLayer,
      type: 'line',
      source: 'nugraph',
      'source-layer': 'road',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#3909ff',
        'line-width': 8,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }

  getHighlightLayer () {
    return {
      id: HighlightLayer,
      type: 'line',
      source: 'nugraph',
      'source-layer': 'road',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#19ff20',
        'line-width': 3,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }

  onTextChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  render () {
    let f = this.state.selectedFeature
    let content = null
    if (f) {
      content = (
            <Table style={{tableLayout: 'fixed'}}>
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Object.keys(f.properties).map(function (key, index) {
                    let cellStyle = styles.cell;
                    if (key==='geometry') {
                      cellStyle = styles.geometryCell
                    }
                    return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell className={cellStyle}>
                            <div className={styles.tableContent}>
                              {f.properties[key]}
                            </div>
                          </TableCell>
                        </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>)
    }

    return (<Card className={styles.body}>
      <CardContent className={styles.content}>
        {content}
      </CardContent>
    </Card>)
  }
}