import type {LngLatLike} from "mapbox-gl/src/geo/lng_lat";
import * as api_conversion from "../routing/api_conversion";
import mapboxgl from "mapbox-gl";

export default class TurnInfoPopups {
    popups = []

    PushIfNotEmpty(turnInfo, turnCommandIdx: number, lngLat: LngLatLike, map) {
        if (turnInfo === undefined) {
            return
        }

        const turnCmd = turnInfo.getTurnCommand()
        if( turnCmd === undefined || turnCmd === "NONE") {
            return
        }

        const html = api_conversion.turnInfoToHTML(turnInfo, turnCommandIdx)

        const popup = new mapboxgl.Popup({
            offset: 5,
            closeButton: true,
            closeOnClick: false,
        })
            .setLngLat(lngLat)
            .setHTML(html)
            .addTo(map);

        this.popups.push(popup)
    }

    Clear() {
        for (const popup of this.popups) {
            popup.remove()
        }
    }
}
