import React from 'react'
import type { Edge } from './EdgeCard'
import type { LoginContextType } from '../../LoginContext'
import axios from 'axios'

type Props = {
  loginCtx: LoginContextType,
  edge: Edge
}

type State = {
  edge: Edge,
  edgeData: {}
}

//const detailsUrl = 'http://localhost:9050/v1/traffic/details/'
const detailsUrl = 'https://traffic.graphmasters.net/bff/v1/traffic/details/'

export function withEdgeCardData (EdgeCardComponent) {

  return class extends React.Component<Props, State> {

    constructor(props: Props) {
      super(props)
      this.state = {
        edge: props.edge,
        loginCtx: props.loginCtx
      }
    }

    componentDidMount() {
      this.updateTrafficData()
      // start a time to periodically update the traffic state
      let stopTimer = setInterval(() => {
        this.updateTrafficData()
      }, 5000)
      this.setState({
        stopTimer: stopTimer
      })
    }

    componentWillUnmount() {
      this.state.stopTimer()
    }

    updateTrafficData = () => {
      if (!this.props.edge) {
        return
      }

      axios.get(detailsUrl+ this.props.edge.sourceGeohash + "/" + this.props.edge.id, {
        headers: {
          "Authorization": "Bearer " + this.state.loginCtx.token.accessToken
        }
      }).then(res => {
        this.setState({
          edge: this.props.edge,
          edgeData: res.data
        })
      }).catch(error => {
        console.log(error)
      })
    }

    render () {

      let lastMeasurementTime = new Date()
      if (this.state.edge) {
        lastMeasurementTime = Date.parse(this.state.edgeData.recentMeasurementTime)
      } else {
        return (null)
      }

      return (
        <EdgeCardComponent {...this.props} edge={this.state.edge} edgeData={this.state.edgeData} start={lastMeasurementTime}/>
      )
    }
  }
}