import React from "react";
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenu from "./SubMenu";

export default class CascadeMenu extends React.Component {
  renderMenuItems = () => {
    const { menuItems, componentFunc, extraDebugItems } = this.props;
    return menuItems.map(menuItem => {
      if (menuItem.hasOwnProperty("subMenuItems")) {
        return (
          <SubMenu
            key={menuItem.key}
            menuItem={menuItem}
            menuItems={menuItem.subMenuItems}
            componentFunc={componentFunc}
            extraDebugItems={extraDebugItems}
          />
        );
      }

      let componentToRender = componentFunc(menuItem)
      return (
        <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
          {componentToRender}
        </MenuItem>
      );
    });
  };

  render() {
    const { anchorElement, open, onClose, extraDebugItems, shouldRenderExtraDebugItems, ...others } = this.props;

    return (
      <MuiMenu
        {...others}
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
        MenuListProps={{ onMouseLeave: onClose }}
        autoFocus={false}
      >
        {this.renderMenuItems()}
        {shouldRenderExtraDebugItems ? extraDebugItems : null}
      </MuiMenu>
    );
  }
}

CascadeMenu.propTypes = {
  anchorElement: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  componentFunc: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  extraDebugItems: PropTypes.any,
  shouldRenderExtraDebugItems: PropTypes.bool
};
