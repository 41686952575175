//@flow
import React from 'react'
import styles from './Routing.module.css'
import mapboxgl from 'mapbox-gl'
import Geosuggest from 'react-geosuggest'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Typography from '@material-ui/core/Typography/Typography'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import type {LoginContextType} from '../../LoginContext'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import SkyLight from 'react-skylight'
import ReactJson from 'react-json-view'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {Knob} from 'react-rotary-knob'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import qs from 'query-string'
import {createBrowserHistory} from 'history'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input'
import Slider from '@material-ui/core/Slider'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ClockIcon from '@material-ui/icons/AccessTime'
import {grpc} from '@improbable-eng/grpc-web'
import {
    Comparison,
    ComparisonType,
    Coordinate,
    Geometry,
    GetCurrentTurnCommandRequest,
    GetRouteRequest,
    GetRouteResponse,
    PathType,
    Provider,
    ProviderType,
    TruckParameters,
    VehicleProperties,
    VehicleType,
} from './routing_pb'
import {urlParamConversion} from './URL_parser'
import {MyHistogram} from "./Histogram"
import {AccelerationChartStacked} from "./AccelerationChartStacked"
import {ParametersCard} from "./ParametersCard"
import {ReservationChart} from "./ReservationChart"
import NoEntry from './no-entry.svg';
import Toll from './toll.svg';
import Closure from './icon_closure.svg';
import Barrier from './barrier.svg';
import Physical from './physical.png';
import CascadeMenu from "./CascadeMenu";
import * as constants from '../../constants'
import * as api_conversion from './api_conversion';
import TurnInfoPopups from "../turncommands/TurnInfoPopups";
import {StartTimesChart} from "./start_times_chart";

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const RoutingService = require('./routing_service')
const myTransport = grpc.CrossBrowserHttpTransport({withCredentials: true})
grpc.setDefaultTransport(myTransport)

const tollImg = new Image(100, 100);
tollImg.src = Toll;
const noEntryImg = new Image(100, 100);
noEntryImg.src = NoEntry;
const closureImg = new Image(120, 120);
closureImg.src = Closure;
const barrierImg = new Image(120, 120);
barrierImg.src = Barrier;
const physicalImg = new Image(100, 100);
physicalImg.src = Physical;

type Props = {
    map: mapboxgl.Map,
    loginCtx: LoginContextType
}

type State = {
    selectedFeature: {},
    vehicleProps: {},
    truckProps: {},

    route: {},
    comparisonRoute: {}
}

const PinStartName = 'start'
const PinDestName = 'dest'
const RouteLineSource = 'routingLineSource'
const RouteLineLayer = 'routingLineLayer'
const RouteCasingSource = 'routingCasingSource'
const RouteCasingLayer = 'routingCasingLayer'
const ComparisonLineSource = 'comparisonLineSource'
const ComparisonLineLayer = 'comparisonLineLayer'
const ComparisonCasingSource = 'comparisonCasingSource'
const ComparisonCasingLayer = 'comparisonCasingLayer'
const CurrentLocationSource = 'currentLocationSource'
const CurrentLocationLayer = 'currentLocationLayer'
const ContractionBoundaryLayer = 'contractionBoundaryLayer'
const RestrictionLayer = 'restrictionLayer'
const RestrictionIconsLayer = 'restrictionIconsLayer'
const ComparisonRestrictionLayer = 'comparisonRestrictionLayer'
const ComparisonRestrictionIconsLayer = 'comparisonRestrictionIconsLayer'
const AccelerationLocationSource = 'accelerationLocationSource'
const AccelerationLocationLayer = 'accelerationLocationLayer'

const compare = {
    azureTruckCompare: {
        val: 1,
        url: 'https://external-truck-routing.graphmasters.net/api',
        text: 'TomTom',
        rpc: ComparisonType.TOMTOM
    },
    germanyCompare: {
        val: 2,
        url: '',
        text: 'Germany',
        rpc: ComparisonType.GERMANY
    },
    nugraphSelfishCompare: {
        val: 3,
        url: '',
        text: 'Nugraph Selfish',
        rpc: ComparisonType.NUGRAPH_SELFISH
    },
    customCompare: {
        val: 4,
        url: '',
        text: 'Custom',
        rpc: ComparisonType.CUSTOM
    },
    hereCompare: {
        val: 5,
        url: '',
        text: 'Here',
        rpc: ComparisonType.HERE
    },
    europeTruckRouterCompare: {
        val: 6,
        url: '',
        text: 'Europe Truck router',
        rpc: ComparisonType.EUROPE_TRUCK_ROUTER
    },
    germnayNotrafficCompare: {
        val: 7,
        url: '',
        text: 'Germany (ignore traffic)',
        rpc: ComparisonType.GERMANY_NO_TRAFFIC
    },
    europeCompare: {
        val: 8,
        url: '',
        text: 'Europe',
        rpc: ComparisonType.EUROPE
    },
    europeNotrafficCompare: {
        val: 9,
        url: '',
        text: 'Europe (ignore traffic)',
        rpc: ComparisonType.EUROPE_NO_TRAFFIC
    },
    routerGatewayCompare: {
        val: 10,
        url: '',
        text: 'router-gateway',
        rpc: ComparisonType.ROUTER_GATEWAY,
    },
}

const routingProvider = {
    routerGatewayProvider: {
        val: 0,
        url: '',
        text: 'router-gateway',
        rpc: ProviderType.PROVIDER_ROUTER_GATEWAY,
    },
    hereProvider: {
        val: 1,
        url: '',
        text: 'Here',
        rpc: ProviderType.PROVIDER_HERE
    },
    europeTruckRouterProvider: {
        val: 2,
        url: '',
        text: 'Europe Truck router',
        rpc: ProviderType.PROVIDER_EUROPE_TRUCK_ROUTER
    },
    europeRouterProvider: {
        val: 3,
        url: '',
        text: 'Europe router',
        rpc: ProviderType.PROVIDER_EUROPE
    },
    eventRouter: {
        val: 4,
        url: '',
        text: 'Event router',
        rpc: ProviderType.PROVIDER_EVENT
    },
    simulationRouter: {
        val: 5,
        url: '',
        text: 'Simulation router',
        rpc: ProviderType.PROVIDER_SIMULATION,
    },
    bicycleRouterProvider: {
        val: 6,
        url: '',
        text: 'Bicycle router',
        rpc: ProviderType.PROVIDER_BICYCLE
    },
    pedestrianRouterProvider: {
        val: 7,
        url: '',
        text: 'Pedestrian router',
        rpc: ProviderType.PROVIDER_PEDESTRIAN
    },
    germanyProvider: {
        val: 8,
        url: '',
        text: 'Germany',
        rpc: ProviderType.PROVIDER_GERMANY
    },
    germanyTestProvider: {
        val: 9,
        url: '',
        text: 'Germany test',
        rpc: ProviderType.PROVIDER_GERMANY_TEST
    },
    multitargetEuropeProvider: {
        val: 12,
        url: '',
        text: 'multitarget europe',
        rpc: ProviderType.PROVIDER_MULTITARGET_EUROPE
    },
    multitargetGermanyProvider: {
        val: 13,
        url: '',
        text: 'multitarget Germany',
        rpc: ProviderType.PROVIDER_MULTITARGET_GERMANY
    },
    multitargetBicycleProvider: {
        val: 14,
        url: '',
        text: 'multitarget bicycle',
        rpc: ProviderType.PROVIDER_MULTITARGET_BICYCLE
    },
    kapschProvider: {
        val: 15,
        url: '',
        text: 'Kapsch salzburg',
        rpc: ProviderType.PROVIDER_KAPSCH
    },
}

const vehicleType = {
    car: {
        val: 0,
        text: 'Car',
        rpc: VehicleType.CAR
    },
    delivery: {
        val: 1,
        text: 'Delivery',
        rpc: VehicleType.DELIVERY,
    },
    truck: {
        val: 2,
        text: 'Truck',
        rpc: VehicleType.TRUCK,
    },
    bicycle: {
        val: 3,
        text: 'bicycle',
        rpc: VehicleType.BICYCLE,
    },
    pedestrian: {
        val: 4,
        text: 'pedestrian',
        rpc: VehicleType.PEDESTRIAN,
    },
}

const geosuggestStyle = {
    'input': {
        'width': '280px',
        'height': '25px'
    },
    'suggests': {
        'position': 'relative',
        'backgroundColor': 'white',
        'zIndex': '99'
    }
}
const emptyFC = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: []
    }
}
// units are kph
const speedLineColor = [
    'interpolate',
    ['linear'],
    ['get', 'anticipatedSpeed'],
    0, 'red',
    40, 'yellow',
    80, 'lime',
    120, 'cyan',
    160, 'blue',
    200, 'pink'
]
const frequencyOpacity = [
    'interpolate',
    ['linear'],
    ['get', 'frequencyDensity'],
    0, 1,   // if frequency density is not set
    1e-6, 0.3, // have a minimum opacity as something we can still see
    1, 1
]
const zoomLineOpacity = [
    'interpolate', ['linear'], ['zoom'],
    8.9, ['get', 'lowZoomOpacity'],
    9, ['get', 'highZoomOpacity']
]
const debugZoomLineOpacity = [
    'interpolate', ['linear'], ['zoom'],
    10.9, ['get', 'lowZoomOpacity'],
    11, ['get', 'highZoomOpacity'],
    12.9, ['get', 'highZoomOpacity'],
    13, 1
]
const startIcon = require('../../assets_served_by_bff/ic-marker-go.png')
const destIcon = require('../../assets_served_by_bff/ic-marker-destination.png')

const maxAngleDefaultValue = 20
const angleMultiplierDefaultValue = 10
const primaryPlusMultiplierDefaultValue = 1
const secondaryMultiplierDefaultValue = 1.2
const serviceResidentialTertiaryMultiplierDefaultValue = 4
const trackUnknownConstructionMultiplierDefaultValue = 7
const truckSpeedLowerBoundDefaultValue = 0.0
const truckSpeedUpperBoundDefaultValue = 80.0
const truckSpeedTransformationFactorDefaultValue = 0.7

// Info: To add more truck parameters, simply add them to the defaultParams. The key must match the router query parameter
const defaultParams = {
    maxAngle: {
        display: 'max angle', value: maxAngleDefaultValue,
        help: 'Apply a penalty when circle-angle ANYWHERE is greater than maxAngle. Use a value of 0 to switch it off.'
    },
    angleMultiplier: {
        display: 'angle multiplier', value: angleMultiplierDefaultValue,
        help: 'Apply a penalty when circle-angle ANYWHERE is greater than maxAngle. Use a value of 0 to switch it off.'
    },
    primaryPlusMultiplier: {
        display: 'primary-plus-multiplier', value: primaryPlusMultiplierDefaultValue,
        help: 'Apply a penalty for any road equal to or better than primary road. This includes primary, motorway, motorway-links etc. Use a value of 1 to switch it off.'
    },
    secondaryMultiplier: {
        display: 'secondary-multiplier', value: secondaryMultiplierDefaultValue,
        help: 'Apply a penalty for secondary or secondary-link roads. Use a value of 1 to switch it off.'
    },
    serviceResidentialTertiaryMultiplier: {
        display: 'service-residential-tertiary-multiplier', value: serviceResidentialTertiaryMultiplierDefaultValue,
        help: 'Apply a penalty for service, residential, tertiary roads. Use a value of 1 to switch it off.'
    },
    trackUnknownConstructionMultiplier: {
        display: 'track-unknown-construction-multiplier', value: trackUnknownConstructionMultiplierDefaultValue,
        help: 'Apply a penalty for roads which are almost always discouraged like track, unknown type or construction roads. Use a value of 1 to switch it off.'
    },
    truckSpeedLowerBound: {
        display: 'truck-speed-lower-bound', value: truckSpeedLowerBoundDefaultValue,
        help: 'Value in km/hr above which truck speed will not be same as car speed'
    },
    truckSpeedUpperBound: {
        display: 'truck-speed-upper-bound', value: truckSpeedUpperBoundDefaultValue,
        help: 'Value in km/hr above which truck speed will be constant'
    },
    truckSpeedTransformationFactor: {
        display: 'truck-speed-transformation-factor', value: truckSpeedTransformationFactorDefaultValue,
        help: 'Factor to multiple with car speed to get truck speed'
    },
}

// All units are in SI (m for height, width, length & kg for weight)
const defaultVehicleProps = {
    height: {
        display: 'height of vehicle', value: 3.8,
        help: 'height of vehicle in m'
    },
    width: {
        display: 'width of vehicle', value: 2.5,
        help: 'width of vehicle in m'
    },
    length: {
        display: 'length of vehicle', value: 16.5,
        help: 'length of vehicle in m'
    },
    weight: {
        display: 'weight of vehicle', value: 10000,
        help: 'weight of vehicle in kg'
    },
}

const stateIdDisableHeuristic = 'disableHeuristic'
const stateIdUseParams = 'useParams'
const stateIdUseVehicleProps = 'useVehicleProps'
const stateIdMakeChart = 'makeChart'
const stateIdUseHeading = 'useHeading'
const stateIdUseDestinationHeading = 'useDestinationHeading'
const stateIdUseDestinationStreetName = 'useDestinationStreetName'
const stateIdShowTurnCommands = 'showTurnCommands'
const stateIdDebug = 'debug'
const stateIdIgnoreStaticCosts = 'ignoreStaticCosts'
const stateIdUseSwitchTimeout = 'useSwitchTimeout'
const stateIdUseRoutingLayers = 'useRoutingLayers'
const stateIdTruckSlider = 'truckSlider'
const stateIdOriginLevel = 'useOriginLevel'
const stateIdDestinationLevel = 'useDestinationLevel'
const stateIdCustomStartTime = 'customStartTime'
const stateIdStartTimeSlider = 'startTimeSlider'
const stateIdPreferOutdoorLevels = 'preferOutdoorLevels'
const stateIdUseSelfishRouting = 'useSelfishRouting'
const stateIdAvoidTolls = 'avoidTolls'
const stateIdIgnoreClosures = 'ignoreClosures'
const stateIdUseAvoidCountryName = 'useAvoidCountryName'
const stateIdUseStayCountryName = 'useStayCountryName'
const menuItems = [
    {
        display: 'routing options',
        subMenuItems: [
            {display: 'Use heading', stateId: stateIdUseHeading},
            {display: 'Use destination heading', stateId: stateIdUseDestinationHeading},
            {display: 'Use destination street name', stateId: stateIdUseDestinationStreetName},
            {display: 'Show Turn Commands', stateId: stateIdShowTurnCommands},
            {display: 'Use routing layers', stateId: stateIdUseRoutingLayers},
            {display: 'Use origin level', stateId: stateIdOriginLevel},
            {display: 'Use destination level', stateId: stateIdDestinationLevel},
            {display: 'Custom start time', stateId: stateIdCustomStartTime, disable: stateIdStartTimeSlider},
            {display: 'Start time slider', stateId: stateIdStartTimeSlider, disable: stateIdCustomStartTime},
            {display: 'Prefer outdoor levels', stateId: stateIdPreferOutdoorLevels},
            {display: 'Avoid tolls', stateId: stateIdAvoidTolls},
            {display: 'Ignore Closures', stateId: stateIdIgnoreClosures},
            {display: 'Use Avoid Country', stateId: stateIdUseAvoidCountryName, disable: stateIdUseStayCountryName},
            {display: 'Use Stay Country', stateId: stateIdUseStayCountryName, disable: stateIdUseAvoidCountryName},
        ]
    },
    {
        display: 'truck options',
        subMenuItems: [
            {display: 'Use vehicle props', stateId: stateIdUseVehicleProps},
            {display: 'Use params', stateId: stateIdUseParams},
            {display: 'Truck slider', stateId: stateIdTruckSlider, enable: stateIdUseParams},
            {display: 'Chart', stateId: stateIdMakeChart},
        ]
    },
    {
        display: 'debug options',
        subMenuItems: [
            {display: 'Disable heuristic', stateId: stateIdDisableHeuristic},
            {display: 'Debug', stateId: stateIdDebug},
            {display: 'Ignore static costs', stateId: stateIdIgnoreStaticCosts},
            {display: 'Set switch timeout', stateId: stateIdUseSwitchTimeout},
            {display: 'Session Id', stateId: 'setSessionId'},
            {display: 'Use selfish routing', stateId: stateIdUseSelfishRouting},
        ]
    }
];

const comparisonRoute = 'comparisonRoute'
const nugraphRoute = 'routeJson'

/*
*
*/
export class Routing extends React.Component<Props, State> {

    constructor(props) {
        super(props)

        // clone the default params (slice method doesn't work on array of objects)
        let truckProps = JSON.parse(JSON.stringify(defaultParams))
        let vehicleProps = JSON.parse(JSON.stringify(defaultVehicleProps))
        this.state = {
            selectedFeature: null,
            routeJson: null,
            routePbf: null,
            comparisonRoute: null,
            vehicleProps: vehicleProps,
            truckProps: truckProps,
            debug: false,
            disableHeuristic: false,
            useParams: false,
            useVehicleProps: false, // Usage of vehicle properties is false by default
            routingLayers: '',
            useRoutingLayers: false,
            featuredEventId: '',
            role: 'VISITOR',
            vehicleTypeEvent: 'CAR',
            parkingAreaId: '',
            tripDirection: 'ARRIVAL',
            useHeading: false,
            heading: 0,
            useDestinationHeading: false,
            destinationHeading: 0,
            useDestinationStreetName: false,
            destinationStreetName: '',
            sessionId: '',
            setSessionId: false,
            useAgents: false,
            nAgents: 1,
            agentStartInterval: '5s',
            startCoords: {},
            destCoords: new Array(),
            startStr: '',
            destStr: new Array(),
            gpx: "",
            geoJson: "",
            doCompare: false,
            comparisonTarget: compare.europeTruckRouterCompare,
            providerTarget: routingProvider.routerGatewayProvider,
            vehicleType: vehicleType.car,
            timeout: '30s',
            useSwitchTimeout: false,
            switchTimeout: '5s', // keep this default in sync with `router-gateway`'s softcoded default
            // https://github.com/Graphmasters/nugraph-bff/blob/main/devops/kubernetes/deployment.template.yaml#L40
            calculationInProgress: false,
            popupMsg: '',
            popupTitle: '',
            chartData: null,
            comparisonChartData: null,
            makeChart: false,
            useHistogram: false,
            useJourneyTime: true,
            showTurnCommands: false,
            menuCascadeAnchorEl: null,
            loaded: false,
            ignoreStaticCosts: false,
            truckSlider: false,
            truckSliderValue: 0,
            truckSliderRoutes: null,
            useOriginLevel: false,
            useDestinationLevel: false,
            originLevel: 0,
            destinationLevel: 0,
            customStartTime: false,
            startTime: new Date().toISOString().match(/\d+-\d+-\d+T\d+:\d+/g).toString(), // get the current time in the format "2006-01-02T15:04" (UTC)
            startTimeSlider: false,
            startTimes: [],
            startTimeSliderRoutes: null,
            startTimeSliderIndex: 0,
            useSelfishRouting: false,
            preferOutdoorLevels: false,
            avoidTolls: false,
            ignoreClosures: false,
            useAvoidCountryName: false,
            useStayCountryName: false,
            avoidCountryName: '',
            stayCountryName: '',
            showReservation: false,
            reservationAnchorEl: null,
            reservationData: null,
        }
        this.pinStart = null
        this.pinDest = []
        this.errorDialog = React.createRef()
        this.history = createBrowserHistory()
        this.client = null
        this.debugSources = []
        this.debugLayers = []
        this.debugIds = []
        this.gettingRouteFromURL = false // true if the route request comes from the url link, false if it was from clicking on the map
        this.hoveredStateId = {route: null, comparison: null}

        this.routePopup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        this.contractionPopup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        this.comparisonPopup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        this.turnInfoPopup = new mapboxgl.Popup({
            offset: 5,
            closeButton: false,
            closeOnClick: false
        });
        this.turnInfoPopups = new TurnInfoPopups;
        this.restrictionPopup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
    }

    componentDidMount() {
        const map = this.props.map
        this.addRouteLayers()

        map.on('dblclick', this.onMapDoubleClick)

        // handle hover over a route
        map.on('mouseenter', RouteLineLayer, this.onRouteEnter);
        map.on('mousemove', RouteLineLayer, this.onRouteMove);
        map.on('mouseleave', RouteLineLayer, this.onRouteLeave);
        map.on('click', RouteLineLayer, this.onRouteClick);

        map.on('mouseenter', ContractionBoundaryLayer, this.onContractionEnter);
        map.on('mouseleave', ContractionBoundaryLayer, this.onContractionLeave);

        map.on('mouseenter', RestrictionLayer, this.onRestrictionEnter);
        map.on('mouseleave', RestrictionLayer, this.onRestrictionLeave);
        map.on('mouseenter', ComparisonRestrictionLayer, this.onRestrictionEnter);
        map.on('mouseleave', ComparisonRestrictionLayer, this.onRestrictionLeave);

        // handle hover over the comparison route
        map.on('mouseenter', ComparisonLineLayer, this.onComparisonEnter);
        map.on('mouseleave', ComparisonLineLayer, this.onComparisonLeave);
        map.on('mousemove', ComparisonLineLayer, this.onComparisonRouteMove);

        map.on('mousemove', this.onMouseMove);
    }

    componentWillUnmount() {
        const map = this.props.map
        map.off('dblclick', this.onMapDoubleClick)
        this.clearRoute()
        this.removeLayers()
        this.turnInfoPopup.remove()
        this.turnInfoPopups.Clear()
    }

    setChart = (isComparisonRoute, data) => {
        if (isComparisonRoute) {
            this.setState({
                comparisonChartData: data
            })
        } else {
            this.setState({
                chartData: data
            })
        }
    }

    addRouteLayers = () => {
        const map = this.props.map

        this.removeLayers()

        map.addImage('toll', tollImg);
        map.addImage('no-entry', noEntryImg);
        map.addImage('road-closure', closureImg);
        map.addImage('barrier', barrierImg);
        map.addImage('physical', physicalImg);

        // add the route casing layer
        map.addSource(RouteCasingSource, emptyFC);
        map.addLayer({
            'id': RouteCasingLayer,
            'type': 'line',
            'source': RouteCasingSource,
            'paint': {
                'line-color': [
                    'match',
                    ['get', 'path'],
                    PathType.ACTUAL_PATH, '#1a1a1a',
                    PathType.COMPARISON_PATH, '#002a7e',
                    PathType.DEBUG_PATH, '#1a1a1a',
                    '#000000'      // debug vertex
                ],
                'line-opacity': zoomLineOpacity,
                'line-width': [
                    'match',
                    ['get', 'path'],
                    PathType.DOWNSAMPLED_PATH, 8,
                    PathType.ACTUAL_PATH, 8,
                    PathType.COMPARISON_PATH, 8,
                    PathType.DEBUG_PATH, 8,
                    0      // debug vertex
                ],
            },
        });

        // add the comparison casing layer
        map.addSource(ComparisonCasingSource, emptyFC);
        map.addLayer({
            'id': ComparisonCasingLayer,
            'type': 'line',
            'source': ComparisonCasingSource,
            'paint': {
                'line-color': '#8c0c00',
                'line-opacity': zoomLineOpacity,
                'line-width': [
                    'match',
                    ['get', 'path'],
                    PathType.DOWNSAMPLED_PATH, 8,
                    PathType.ACTUAL_PATH, 8,
                    PathType.COMPARISON_PATH, 8,
                    PathType.DEBUG_PATH, 8,
                    0      // debug vertex
                ],
            },
        });

        // add the comparison route layer
        map.addSource(ComparisonLineSource, emptyFC);
        map.addLayer({
            'id': ComparisonLineLayer,
            'type': 'line',
            'source': ComparisonLineSource,
            'paint': {
                'line-color': speedLineColor,
                'line-opacity': zoomLineOpacity,
                'line-width': [
                    'match',
                    ['get', 'path'],
                    PathType.DOWNSAMPLED_PATH, 5,
                    PathType.COMPARISON_PATH, 3,
                    PathType.ACTUAL_PATH, 5,
                    PathType.DEBUG_PATH, 5,
                    2      // debug vertex
                ],
            },
        });

        // add the nugraph route layer
        map.addSource(RouteLineSource, emptyFC);
        map.addLayer({
            'id': RouteLineLayer,
            'type': 'line',
            'source': RouteLineSource,
            'paint': {
                'line-color': speedLineColor,
                'line-opacity': zoomLineOpacity,
                'line-width': [
                    'match',
                    ['get', 'path'],
                    PathType.DOWNSAMPLED_PATH, 5,
                    PathType.COMPARISON_PATH, 3,
                    PathType.ACTUAL_PATH, 5,
                    PathType.DEBUG_PATH, 5,
                    2      // debug vertex
                ],
            },
        });

        map.addLayer({
            'id': RestrictionLayer,
            'type': 'line',
            'source': RouteLineSource,
            'paint': {
                'line-color': 'white',
                'line-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.8,
                    0
                ],
                'line-width': 5
            },
            'filter': ['==', ['get', 'path'], PathType.RESTRICTION_INFO]
        });
        map.addLayer({
            'id': ComparisonRestrictionLayer,
            'type': 'line',
            'source': ComparisonLineSource,
            'paint': {
                'line-color': 'white',
                'line-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.8,
                    0
                ],
                'line-width': 5
            },
            'filter': ['==', ['get', 'path'], PathType.RESTRICTION_INFO]
        });
        map.addLayer({
            'id': RestrictionIconsLayer,
            'type': 'symbol',
            'source': RouteLineSource,
            'layout': {
                'icon-image': ['get', 'icon'],
                'icon-size': 0.25,
            },
            'filter': ['==', ['get', 'path'], PathType.RESTRICTION_INFO]
        });

        map.addLayer({
            'id': ComparisonRestrictionIconsLayer,
            'type': 'symbol',
            'source': ComparisonLineSource,
            'layout': {
                'icon-image': ['get', 'icon'],
                'icon-size': 0.25,
            },
            'filter': ['==', ['get', 'path'], PathType.RESTRICTION_INFO]
        });

        map.addLayer({
            'id': ContractionBoundaryLayer,
            'type': 'circle',
            'source': RouteLineSource,
            'paint': {
                'circle-radius': 8,
                'circle-color': [
                    'match',
                    ['get', 'path'],
                    PathType.CONTRACTION_BOUNDARY, '#001d9e',
                    '#000000'      // start point
                ],
                'circle-opacity': [
                    'match',
                    ['get', 'path'],
                    PathType.CONTRACTION_BOUNDARY, 1,
                    0      // start point
                ],
            },
            'filter': ['==', ['get', 'path'], PathType.CONTRACTION_BOUNDARY]
        });

        // add the current location for turn commands
        map.addSource(CurrentLocationSource, emptyFC)
        map.addLayer({
            'id': CurrentLocationLayer,
            'type': 'circle',
            'source': CurrentLocationSource,
            'paint': {
                'circle-radius': 7,
                'circle-color': '#343434'
            }
        })

        // add the current location for acce-leration profile
        map.addSource(AccelerationLocationSource, emptyFC)
        map.addLayer({
            'id': AccelerationLocationLayer,
            'type': 'circle',
            'source': AccelerationLocationSource,
            'paint': {
                'circle-radius': 7,
                'circle-color': '#001d9e'
            }
        })
    }

    onRouteEnter = (e) => {
        if (this.state.comparisonRoute && this.hoveredStateId.route === null) {
            const map = this.props.map
            map.getCanvas().style.cursor = 'pointer';
            this.routePopup.setLngLat(e.lngLat)
                .setHTML('nugraph')
                .addTo(map);
            return
        }
        if (this.state.useAgents) {
            const map = this.props.map
            map.getCanvas().style.cursor = 'pointer';
            this.routePopup.setLngLat(e.lngLat)
              .setHTML((e.features[0].properties.frequencyDensity*this.state.nAgents).toFixed(0) + ' cars')
              .addTo(map);
            return
        }
    }

    onRouteLeave = (e) => {
        if (this.state.comparisonRoute || this.state.useAgents) {
            const map = this.props.map
            map.getCanvas().style.cursor = '';
            this.routePopup.remove()
        }
        if (this.hoveredStateId.route === null) {
            return
        }
        this.props.map.setFeatureState(
          { source: RouteLineSource, id: this.hoveredStateId.route},
          { hover: false }
        )
        this.hoveredStateId.route = null
    }

    onRouteMove = (e) => {
        if (e.features.length === 0) {
            return
        }
        if (e.features[0].properties.path !== PathType.RESTRICTION_INFO) {
            return
        }
        if (this.hoveredStateId.route !== null) {
            this.props.map.setFeatureState(
              { source: RouteLineSource, id: this.hoveredStateId.route },
              { hover: false }
            )
        }
        this.hoveredStateId.route = e.features[0].id
        this.props.map.setFeatureState(
          { source: RouteLineSource, id: e.features[0].id },
          { hover: true }
        )
        if (this.state.comparisonRoute) {
            this.routePopup.remove()
        }
    }

    onComparisonRouteMove = (e) => {
        if (e.features.length === 0) {
            return
        }
        if (e.features[0].properties.path !== PathType.RESTRICTION_INFO) {
            return
        }
        if (this.hoveredStateId.comparison !== null) {
            this.props.map.setFeatureState(
              { source: ComparisonLineSource, id: this.hoveredStateId.comparison },
              { hover: false }
            )
        }
        this.hoveredStateId.comparison = e.features[0].id
        this.props.map.setFeatureState(
          { source: ComparisonLineSource, id: e.features[0].id },
          { hover: true }
        )
        if (this.state.comparisonRoute) {
            this.comparisonPopup.remove()
        }
    }

    onRouteClick = (e) => {
        if (!this.state.useAgents) {
            return
        }
        let properties = e.features[0].properties
        properties.reservation = JSON.parse(properties.reservation) // the reservation buckets become a string for some reason
        let show = !this.state.showReservation
        let anchorEl = null
        if (show) {
            anchorEl = e.currentTarget
            // anchor the kalman to the card root
            while (anchorEl) {
                anchorEl = anchorEl.parentNode
                if (anchorEl.className.includes("root")) break
            }
        }
        this.setState({
            reservationData: properties,
            showReservation: show,
            reservationAnchorEl: anchorEl
        })
    }

    onReservationClose = () => {
        this.setState(({
            reservationData: null,
            showReservation: false,
            reservationAnchorEl: null
        }))
    }

    onContractionEnter = (e) => {
        const map = this.props.map
        map.getCanvas().style.cursor = 'pointer';
        this.contractionPopup.setLngLat(e.lngLat)
            .setHTML('contraction boundary')
            .addTo(map);
    }

    onContractionLeave = (e) => {
        const map = this.props.map
        map.getCanvas().style.cursor = '';
        this.contractionPopup.remove()
    }

    onRestrictionEnter = (e) => {
        const map = this.props.map
        map.getCanvas().style.cursor = 'pointer';
        this.restrictionPopup.setLngLat(e.lngLat)
            .setHTML(this.restrictionsInfoHtml(JSON.parse(e.features[0].properties.restrictions)))
            .addTo(map);
    }

    restrictionsInfoHtml = (restrictions) => {
        let html = ""
        if (restrictions.barrier) {
            html += "barrier: " + restrictions.barrier + "<br>"
        }
        if (restrictions.closure) {
            html += "closure: <br>" + restrictions.closure + "<br>"
        }
        if (restrictions.physicalRestrictions) {
            html += restrictions.physicalRestrictions + "<br>"
        }
        if (restrictions.accessRestriction) {
            html += "access: " + restrictions.accessRestriction + "<br>"
        }
        if (restrictions.toll) {
            html += "toll: " + restrictions.toll + "<br>"
        }
        if (restrictions.conditionalRestriction) {
            html += "conditional: " + restrictions.conditionalRestriction + "<br>"
        }
        return html
    }

    onRestrictionLeave = (e) => {
        const map = this.props.map
        map.getCanvas().style.cursor = '';
        this.restrictionPopup.remove()
    }

    onComparisonEnter = (e) => {
        if (this.state.comparisonRoute && this.hoveredStateId.comparison === null) {
            const map = this.props.map
            map.getCanvas().style.cursor = 'pointer';
            this.comparisonPopup.setLngLat(e.lngLat)
                .setHTML('comparison')
                .addTo(map);
        }
    }

    onComparisonLeave = (e) => {
        if (this.state.comparisonRoute) {
            const map = this.props.map
            map.getCanvas().style.cursor = '';
            this.comparisonPopup.remove()

            if (this.hoveredStateId.comparison === null) {
                return
            }
            this.props.map.setFeatureState(
              { source: ComparisonLineSource, id: this.hoveredStateId.comparison},
              { hover: false }
            )
            this.hoveredStateId.comparison = null
        }
    }

    onMouseMove = (e) => {
        const route = this.state.routePbf
        if (route && this.state.showTurnCommands) {
            const getCurrentTurnCommandRequest = new GetCurrentTurnCommandRequest()
            const location = new Coordinate()
            location.setLat(e.lngLat.lat)
            location.setLng(e.lngLat.lng)
            getCurrentTurnCommandRequest.setLocation(location)
            getCurrentTurnCommandRequest.setFeaturesList(this.state.routePbf)

            grpc.unary(RoutingService.RoutingService.GetCurrentTurnCommand, {
                request: getCurrentTurnCommandRequest,
                host: process.env.REACT_APP_BFF_GRPC,
                onEnd: res => {
                    const {status, statusMessage, headers, message, trailers} = res;
                    if (status === grpc.Code.OK && message) {
                        let location = message.getLocation()
                        let turnInfo = message.getTurnCommand()
                        let html = 'none'
                        if (turnInfo) {
                            html = api_conversion.turnInfoToHTML(turnInfo, message.getTurnCommandIdx())
                        }

                        this.turnInfoPopup.setLngLat({lng: location.getLng(), lat: location.getLat()})
                            .setHTML(html)
                            .addTo(this.props.map);

                        let currentLocation = this.props.map.getSource(CurrentLocationSource)
                        if (currentLocation) {
                            currentLocation.setData({
                                type: 'FeatureCollection',
                                features: [
                                    {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [
                                                location.getLng(),
                                                location.getLat()
                                            ]
                                        }
                                    }
                                ]
                            })
                        }
                    }
                }
            });
        }
    }

    onMapDoubleClick = (e) => {
        let coords = e.lngLat
        let locationStr = e.lngLat.lat + ', ' + e.lngLat.lng
        if (!this.pinStart) {
            this.setPin(PinStartName, coords, locationStr)
        } else {
            this.setPin(PinDestName, coords, locationStr)
        }
    }

    setPin = (pinName, coords, locationStr) => {
        const map = this.props.map

        if (pinName === PinStartName) {
            if (!this.pinStart) {
                this.pinStart = this.createMarker('ic-marker-go.png')
                this.pinStart.setLngLat(coords)
                this.pinStart.addTo(map)
                this.pinStart.on('dragend', () => {
                    let c = this.pinStart.getLngLat()
                    this.setPin(PinStartName, c, c.lat + ', ' + c.lng)
                })
            } else {
                this.pinStart.setLngLat(coords)
            }
            this.setState({
                startCoords: {'lat': coords.lat, 'lng': coords.lng},
                startStr: locationStr,
            }, function() {
                if (this.pinStart && this.pinDest.length !== 0) {
                    this.updateRoute()
                }
            })
        } else if (pinName === PinDestName) {
            let marker = this.createMarker('ic-marker-destination.png')
            marker.setLngLat(coords)
            marker.addTo(map)
            this.pinDest.push(marker)

            let idxTemp = this.pinDest.indexOf(marker)
            this.pinDest[idxTemp].on('dragend', () => {
                let c = this.pinDest[idxTemp].getLngLat()
                this.updatePin(idxTemp, c, c.lat + ', ' + c.lng)
            })

            let destCoords = this.state.destCoords.slice()
            destCoords.push({'lat': coords.lat, 'lng': coords.lng})
            let destStr = this.state.destStr.slice()
            destStr.push(locationStr)
            this.setState(({
                destCoords: destCoords,
                destStr: destStr,
            }), function() {
                if (this.pinStart && this.pinDest.length !== 0) {
                    this.updateRoute()
                }
            })

        }
    }

    updatePin = (pinIdx, coords, locationStr) => {
        this.pinDest[pinIdx].remove()
        const map = this.props.map
        let maker = this.createMarker('ic-marker-destination.png')
        maker.setLngLat(coords)
        maker.addTo(map)
        this.pinDest[pinIdx] = maker

        this.pinDest[pinIdx].on('dragend', () => {
            let c = this.pinDest[pinIdx].getLngLat()
            this.updatePin(pinIdx, c, c.lat + ', ' + c.lng)
        })

        let items = this.state.destCoords.slice()
        items[pinIdx] = {'lat': coords.lat, 'lng': coords.lng};

        let itemsDst = this.state.destStr.slice()
        itemsDst[pinIdx] = locationStr;

        this.setState(({
            destCoords: items,
            destStr: itemsDst,
        }), function() {
            if (this.pinStart && this.pinDest.length !== 0) {
                this.updateRoute()
            }
        })
    }

    createMarker = (iconName) => {
        let el = document.createElement('div')
        el.className = 'marker'
        el.style.backgroundImage = 'url(' + constants.resourcesURL + iconName + ')'
        el.style.width = '27px'
        el.style.height = '33px'
        let marker = new mapboxgl.Marker(el, {
            draggable: true,
            offset: [0, -33 / 2]
        })
        marker.setPopup(new mapboxgl.Popup({offset: 25}))
        return marker
    }

    updateRoute = () => {
        let doCompare = this.state.doCompare

        // clear the old route
        this.removeSources()

        this.turnInfoPopup.remove()
        this.turnInfoPopups.Clear()

        this.setState({
            routeJson: null,
            routePbf: null,
            comparisonRoute: null,
            truckSliderRoutes: null,
            startTimeSliderRoutes: null,
        })
        this.resetChartData('')
        this.debugIds = []
        this.debugLayers = []
        this.debugSources = []

        this.updateRouteWithTarget(doCompare, false)
        if (!doCompare) {
            this.setState({
                comparisonRoute: null
            })
        }
    }

    updateRouteWithTarget = (shouldDoComparison) => {
        // cancel any existing route
        if (this.state.calculationInProgress && this.client) {
            this.client.close()
        }

        // target = 'comparisonRoute' or 'route' for tomtom & nugraph respectively
        let src = this.state.startCoords
        let trg = this.state.destCoords
        if (!src || !src.lat || !src.lng || !trg[0] || !trg[0].lat || !trg[0].lng) {
            return
        }

        // build the url from the current state
        let query = ''
        let urlParamConverter = urlParamConversion(routingProvider, compare, vehicleType, this.state.truckProps, this.state.vehicleProps)
        let state = this.state
        Object.keys(urlParamConverter).map(function (key) {
            let converter = urlParamConverter[key]
            if (converter) {
                query += converter.setUrl(state)
            }
        })
        // trim the leading `&`
        if (query.length > 0 && query[0] === '&') {
            query = query.substring(1)
        }

        this.setState({
            calculationInProgress: true,
        })
        this.resetChartData();

        // update the url
        this.history.push({
            pathname: '/route',
            search: query
        })

        let origin = new Coordinate()
        origin.setLat(src.lat)
        origin.setLng(src.lng)

        let destination = []
        for (let i = 0; i < trg.length; i++) {
            let dst =  new Coordinate()
            dst.setLat(trg[i].lat)
            dst.setLng(trg[i].lng)
            destination.push(dst)
        }

        const getRouteRequest = new GetRouteRequest()
        getRouteRequest.setOrigin(origin)
        getRouteRequest.setDestinationsList(destination)
        getRouteRequest.setDisableHeuristic(this.state.disableHeuristic)
        getRouteRequest.setDebug(this.state.debug)
        getRouteRequest.setMakeChart(this.state.makeChart)
        getRouteRequest.setUseHeading(this.state.useHeading)
        getRouteRequest.setHeading(this.state.heading)
        getRouteRequest.setUseVehicleProperties(this.state.useVehicleProps)
        getRouteRequest.setPreferOutdoorLevels(this.state.preferOutdoorLevels)
        getRouteRequest.setUseSelfishRouting(this.state.useSelfishRouting)
        getRouteRequest.setAvoidTolls(this.state.avoidTolls)
        getRouteRequest.setIgnoreClosures(this.state.ignoreClosures)
        if (this.state.useAvoidCountryName){
            getRouteRequest.setAvoid(this.state.avoidCountryName)
        }
        if (this.state.useStayCountryName){
            getRouteRequest.setStay(this.state.stayCountryName)
        }
        getRouteRequest.setIgnoreStaticCosts(this.state.ignoreStaticCosts)
        getRouteRequest.setUseDestinationHeading(this.state.useDestinationHeading)
        getRouteRequest.setDestinationHeading(this.state.destinationHeading)
        getRouteRequest.setDestinationStreetName(this.state.destinationStreetName)
        getRouteRequest.setTimeout(this.state.timeout)
        if (this.state.setSessionId) {
            getRouteRequest.setSessionId(this.state.sessionId)
        }
        if (this.state.useSwitchTimeout) {
            getRouteRequest.setSwitchTimeout(this.state.switchTimeout)
        }
        if (this.state.useAgents) {
            getRouteRequest.setNumAgents(this.state.nAgents)
            getRouteRequest.setAgentStartInterval(this.state.agentStartInterval)
        }
        if (this.state.useOriginLevel) {
            let level = new google_protobuf_wrappers_pb.Int32Value()
            level.setValue(this.state.originLevel)
            getRouteRequest.setOriginLevel(level)
        }
        if (this.state.useDestinationLevel) {
            let level = new google_protobuf_wrappers_pb.Int32Value()
            level.setValue(this.state.destinationLevel)
            getRouteRequest.setDestinationLevel(level)
        }
        if (this.state.customStartTime) {
            getRouteRequest.setStartTime(this.state.startTime)
        }
        if (this.state.startTimeSlider) {
            getRouteRequest.setStartTimeSlider(new(google_protobuf_empty_pb.Empty))
        }

        if (this.state.useRoutingLayers) {
            getRouteRequest.setRoutingLayerIdsList(this.state.routingLayers.split(','))
        }

        if (this.state.providerTarget.val === routingProvider.eventRouter.val) {
            getRouteRequest.setFeaturedEventId(this.state.featuredEventId)
            getRouteRequest.setRole(this.state.role)
            getRouteRequest.setVehicleTypeEvent(this.state.vehicleTypeEvent)
            getRouteRequest.setParkingAreaId(this.state.parkingAreaId)
            getRouteRequest.setTripDirection(this.state.tripDirection)
        }

        let vehicleProps = new VehicleProperties()
        vehicleProps.setWidth(this.state.vehicleProps.width.value)
        vehicleProps.setHeight(this.state.vehicleProps.height.value)
        vehicleProps.setLength(this.state.vehicleProps.length.value)
        vehicleProps.setWeight(this.state.vehicleProps.weight.value)
        getRouteRequest.setVehicleProperties(vehicleProps)

        getRouteRequest.setUseParams(this.state.useParams)
        if (this.state.useParams) {
            let params = new TruckParameters()
            params.setAnglePenaltyFactor(this.state.truckProps.angleMultiplier.value)
            params.setMaxAngle(this.state.truckProps.maxAngle.value)
            params.setPrimaryPlusMultiplier(this.state.truckProps.primaryPlusMultiplier.value)
            params.setSecondaryMultiplier(this.state.truckProps.secondaryMultiplier.value)
            params.setServiceResidentialTertiaryMultiplier(this.state.truckProps.serviceResidentialTertiaryMultiplier.value)
            params.setTrackUnknownConstructionMultiplier(this.state.truckProps.trackUnknownConstructionMultiplier.value)
            params.setTruckSpeedLowerBoundKmh(this.state.truckProps.truckSpeedLowerBound.value)
            params.setTruckSpeedUpperBoundKmh(this.state.truckProps.truckSpeedUpperBound.value)
            params.setTruckSpeedTransformationFactor(this.state.truckProps.truckSpeedTransformationFactor.value)
            getRouteRequest.setTruckSlider(this.state.truckSlider)
            getRouteRequest.setTruckParameters(params)
        }

        let comparisonTarget = this.state.comparisonTarget
        if (shouldDoComparison) {
            let comparison = new Comparison()
            comparison.setComparisonType(ComparisonType.LEGACY) // default
            Object.keys(compare).map(function (key) {
                if (comparisonTarget.val === compare[key].val) {
                    comparison.setComparisonType(compare[key].rpc)
                }
            })
            comparison.setComparisonUrl(comparisonTarget.url)
            getRouteRequest.setComparison(comparison)
        }

        let provider = new Provider()
        provider.setProviderType(ProviderType.PROVIDER_NUGRAPH) // default
        let providerTarget = this.state.providerTarget
        Object.keys(routingProvider).map(function (key) {
            if (providerTarget.val === routingProvider[key].val) {
                provider.setProviderType(routingProvider[key].rpc)
            }
        })
        provider.setProviderUrl(providerTarget.url)
        getRouteRequest.setProvider(provider)

        getRouteRequest.setVehicleType(this.state.vehicleType.rpc)

        getRouteRequest.setAuthorization(this.props.loginCtx.token.accessToken)
        let metadata = new grpc.Metadata()
        metadata.set('Authorization', 'Bearer ' + this.props.loginCtx.token.accessToken)

        const client = grpc.client(RoutingService.RoutingService.GetRoute, {
            host: process.env.REACT_APP_BFF_GRPC,
        })
        this.client = client
        this.setMapBounds()

        client.onMessage((message: GetRouteResponse) => {
            // console.log('client.onMessage', message.toObject());
            let route = message.getRoute()
            if (route) {
                this.unpackRoute(route, false, this.state.debug, message.getGpx(), message.getGeojson());
            }
            let comparisonRoute = message.getComparisonRoute()
            if (comparisonRoute) {
                this.unpackRoute(comparisonRoute, true, false, "", "");
            }
            let truckSliderRoutes = message.getTruckSliderRoutesList()
            if (truckSliderRoutes) {
                this.setState({
                    truckSliderRoutes: truckSliderRoutes
                }, this.updateTruckSliderRoutes)
            }
            let startTimeSliderRoutes = message.getStartTimeSliderRoutesList()
            if (startTimeSliderRoutes) {
                this.setState({
                    startTimeSliderRoutes: startTimeSliderRoutes
                }, this.updateStartTimeSliderRoutes)
            }
            console.log('request id:', message.getRequestId())
        });
        client.onEnd((code: grpc.Code, msg: string, trailers: grpc.Metadata) => {
            console.log('client.onEnd', code, msg, trailers);
            this.setState({
                calculationInProgress: false,
            })
            if (code !== grpc.Code.OK) {
                this.onError(msg)
            } else if (this.state.routePbf === null) {
                this.onNoRoute()
            }
            this.client = null
        });
        client.start(metadata)
        client.send(getRouteRequest)
    }

    onError = (error) => {
        console.log(error)
        this.setState({
            popupMsg: error,
            popupTitle: 'Error getting route'
        })
        if (this.errorDialog.current != null) {
            this.errorDialog.current.show();
        }
    }

    onNoRoute = () => {
        this.setState({
            popupMsg: '',
            popupTitle: 'No route found'
        })
        if (this.errorDialog.current != null) {
            this.errorDialog.current.show();
        }
    }

    unpackRoute = (route, isComparisonRoute, isDebugRoute, gpx, geoJson) => {
        this.setState({
            gpx: gpx,
            geoJson: geoJson,
        })

        let fc = {
            type: 'FeatureCollection',
            features: []
        }

        let features = route.getFeaturesList()
        let batchFc = {
            type: 'FeatureCollection',
            features: new Array(features.length)
        }

        for (let i = 0; i < features.length; i++) {
            let feature = features[i]
            let geom = feature.getGeometry()
            let geomType = geom.getCoordinatesCase()

            let props = feature.getProperties().toObject()
            if (props.path === PathType.DEBUG_VERTEX) {
                props.anticipatedSpeed = null
            } else {
                isDebugRoute = false
            }
            let coordinates = []
            let isPoint = false
            switch (geomType) {
                case Geometry.CoordinatesCase.POINT:
                    coordinates = geom.getPoint().toObject().lngLatList
                    isPoint = true
                    break
                case Geometry.CoordinatesCase.LINE_STRING:
                    let list = geom.getLineString().toObject().pointsList
                    for (let i = 0; i < list.length; i++) {
                        coordinates.push(list[i].lngLatList)
                    }
                    break
                default:
                    console.log('unsupported geometry type', geomType)
                    return
            }
            batchFc.features[i] = {
                id: i,
                type: feature.getType(),
                properties: props,
                geometry: {
                    type: geom.toObject().type,
                    coordinates: coordinates,
                },
            }

            if (this.state[stateIdShowTurnCommands] && coordinates.length > 0) {
                // mobile-apps display and announce the turn at the END of a route-leg
                const coordLast = coordinates[coordinates.length -1]
                const turnInfo = feature.getProperties().getTurnInfo()
                this.turnInfoPopups.PushIfNotEmpty(
                    turnInfo,
                    i + 1,
                    {lng: coordLast[0], lat: coordLast[1]},
                    this.props.map,
                )
            }
        }

        fc.features = fc.features.concat(batchFc.features)
        this.state.routePbf = features
        this.setRoute(isComparisonRoute, fc, isDebugRoute)


        let chartData = []
        if (this.state.useAgents) {
            chartData = route.getAgentDataList()
        } else {
            chartData = route.getAccelerationProfileList()
        }
        if (chartData.length > 0) {
            this.setChart(isComparisonRoute, chartData)
        }
    }

    setRoute = (isComparisonRoute, route, debug) => {
        const map = this.props.map

        let sourceId = RouteLineSource

        if (debug) {
            let id = this.debugIds.length
            this.debugIds.push(id)
            this.debugSources.push(RouteLineSource + id)
            this.debugLayers.push(RouteLineLayer + id)
            map.addSource(RouteLineSource + id, {
                type: 'geojson',
                data: route
            });
            map.addLayer({
                'id': RouteLineLayer + id,
                'type': 'line',
                'source': RouteLineSource + id,
                'paint': {
                    'line-color': speedLineColor,
                    'line-opacity': debugZoomLineOpacity,
                    'line-width': 2,
                },
                'filter': ['==', '$type', 'LineString']
            });
            return
        }

        if (isComparisonRoute) {
            sourceId = ComparisonLineSource
            map.getSource(ComparisonCasingSource).setData(route)
        } else {
            map.getSource(RouteCasingSource).setData(route)
        }

        // check that the source exists on both the tiles view
        // and the OSM view, otherwise create a new layer
        let source = map.getSource(sourceId)
        if (!source) {
            this.addRouteLayers()
        }
        if (!isComparisonRoute) {
            if (this.state.useAgents) {
                map.setPaintProperty(RouteLineLayer, 'line-color', '#001d9e')
                map.setPaintProperty(RouteLineLayer, 'line-opacity', frequencyOpacity)
            } else {
                map.setPaintProperty(RouteLineLayer, 'line-color', speedLineColor)
                map.setPaintProperty(RouteLineLayer, 'line-opacity', zoomLineOpacity)
            }
        } else {
            if (this.state.useAgents) {
                map.setPaintProperty(ComparisonLineLayer, 'line-color', '#880c00')
                map.setPaintProperty(ComparisonLineLayer, 'line-opacity', frequencyOpacity)
            } else {
                map.setPaintProperty(ComparisonLineLayer, 'line-color', speedLineColor)
                map.setPaintProperty(ComparisonLineLayer, 'line-opacity', zoomLineOpacity)
            }
        }
        map.getSource(sourceId).setData(route)

        let target = nugraphRoute
        if (isComparisonRoute) {
            target = comparisonRoute
        }
        this.state[target] = route
    }

    setMapBounds = () => {
        // check if the source and destination are visible on the screen
        const map = this.props.map
        let bounds = map.getBounds()
        let start = this.state.startCoords
        let dest = this.state.destCoords[0]
        if (!(start.lat <= bounds._ne.lat && start.lat >= bounds._sw.lat && dest.lat <= bounds._ne.lat && dest.lat >= bounds._sw.lat &&
            start.lng <= bounds._ne.lng && start.lng >= bounds._sw.lng && dest.lng <= bounds._ne.lng && dest.lng >= bounds._sw.lng)) {
            let flyToOpts = {padding: 200}
            if (this.gettingRouteFromURL) {
                flyToOpts.duration = 0
                this.gettingRouteFromURL = false
            }
            map.fitBounds([[
                start.lng,
                start.lat
            ], [
                dest.lng,
                dest.lat
            ]], flyToOpts);
        }
    }

    removeLayers = () => {
        const map = this.props.map

        let routeSource = map.getSource(RouteLineSource)
        if (routeSource) {
            map.removeLayer(RouteLineLayer)
            map.removeLayer(ContractionBoundaryLayer)
            map.removeLayer(RestrictionLayer)
            map.removeLayer(RestrictionIconsLayer)
            map.removeSource(RouteLineSource)
        }

        let casingSource = map.getSource(RouteCasingSource)
        if (casingSource) {
            map.removeLayer(RouteCasingLayer)
            map.removeLayer(ComparisonRestrictionLayer)
            map.removeLayer(ComparisonRestrictionIconsLayer)
            map.removeSource(RouteCasingSource)
        }

        let comparisonSource = map.getSource(ComparisonLineSource)
        if (comparisonSource) {
            map.removeLayer(ComparisonLineLayer)
            map.removeSource(ComparisonLineSource)
        }

        let comparisonCasingSource = map.getSource(ComparisonCasingSource)
        if (comparisonCasingSource) {
            map.removeLayer(ComparisonCasingLayer)
            map.removeSource(ComparisonCasingSource)
        }

        let currentLocationSource = map.getSource(CurrentLocationSource)
        if (currentLocationSource) {
            map.removeLayer(CurrentLocationLayer)
            map.removeSource(CurrentLocationSource)
        }

        let accelerationLocationSource = map.getSource(AccelerationLocationSource)
        if (accelerationLocationSource) {
            map.removeLayer(AccelerationLocationLayer)
            map.removeSource(AccelerationLocationSource)
        }

        for (let i = 0; i < this.debugIds.length; i++) {
            map.removeLayer(this.debugLayers[i])
            map.removeSource(this.debugSources[i])
        }

        if (map.hasImage('toll')) {
            map.removeImage('toll')
        }
        if (map.hasImage('no-enty')) {
            map.removeImage('no-entry')
        }
        if (map.hasImage('road-closure')) {
            map.removeImage('road-closure')
        }
        if (map.hasImage('barrier')) {
            map.removeImage('barrier')
        }
        if (map.hasImage('physical')) {
            map.removeImage('physical')
        }
    }

    removeSources = () => {
        let map = this.props.map

        let routeSource = map.getSource(RouteLineSource)
        if (routeSource) {
            routeSource.setData(emptyFC.data)
        }

        let casingSource = map.getSource(RouteCasingSource)
        if (casingSource) {
            casingSource.setData(emptyFC.data)
        }

        let comparisonSource = map.getSource(ComparisonLineSource)
        if (comparisonSource) {
            comparisonSource.setData(emptyFC.data)
        }

        let comparisonCasingSource = map.getSource(ComparisonCasingSource)
        if (comparisonSource) {
            comparisonCasingSource.setData(emptyFC.data)
        }

        let currentLocationSource = map.getSource(CurrentLocationSource)
        if (currentLocationSource) {
            currentLocationSource.setData(emptyFC.data)
        }

        let accelerationLocationSource = map.getSource(AccelerationLocationSource)
        if (accelerationLocationSource) {
            accelerationLocationSource.setData(emptyFC.data)
        }

        for (let i = 0; i < this.debugIds.length; i++) {
            map.removeLayer(this.debugLayers[i])
            map.removeSource(this.debugSources[i])
        }
    }

    inverse = () => {
        if (this.state.calculationInProgress) {
            this.cancelRoute()
            this.resetChartData('')
        } else {
            let c = this.pinDest[0].getLngLat()
            let cs = this.pinStart.getLngLat()

            this.setPin(PinStartName, c, c.lat + ', ' + c.lng)
            this.updatePin(0, cs, cs.lat + ', ' + cs.lng)

            this.updateRoute()
        }
    }
    clearRoute = () => {
        if (this.pinStart) {
            this.pinStart.remove()
        }

        for (let i = 0; i < this.pinDest.length; i++) {
            this.pinDest[i].remove()
        }

        this.pinStart = null
        this.pinDest = []

        this.removeSources()

        this.turnInfoPopup.remove()
        this.turnInfoPopups.Clear()

        this.setState({
            routeJson: null,
            routePbf: null,
            comparisonRoute: null,
            truckSliderRoutes: null,
            startTimeSliderRoutes: null,
            startCoords: {},
            destCoords: [],
            startStr: '',
            destStr: []
        })
        this.resetChartData('')
        this.debugIds = []
        this.debugLayers = []
        this.debugSources = []

        // update the url
        this.history.push({
            pathname: '',
            search: ''
        })
    }

    setAccelerationLocation = location => {
        const map = this.props.map

        let accelerationLocation = map.getSource(AccelerationLocationSource)
        if (accelerationLocation) {
            accelerationLocation.setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [
                                location.lng,
                                location.lat
                            ]
                        }
                    }
                ]
            })
        }
    }

    onSuggestSelect = (suggest, name) => {
        if (suggest) {
            this.setPin(name, suggest.location, suggest.label)
            this.updateRoute()
        }
    }

    onGpx = () => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.gpx));
        element.setAttribute('download', "gpx.gpx");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    onGeoJson = () => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.geoJson));
        element.setAttribute('download', "geoJson.json");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    onTruckPropChange = name => event => {
        let props = this.state.truckProps
        props[name].value = event.target.value
        this.setState({
            truckProps: props,
        })
    }

    onVehiclePropChange = name => event => {
        let props = this.state.vehicleProps
        props[name].value = event.target.value
        this.setState({
            vehicleProps: props,
        })
    }

    onTargetUrlChange = name => event => {
        let comparison = this.state.comparisonTarget
        comparison.url = event.target.value
        this.setState({
            comparisonTarget: comparison
        })
    }

    onDestinationStreetNameChange = name => event => {
        this.setState({
            destinationStreetName: event.target.value
        })
    }

    onAvoidCountryNameChange = name => event => {
        this.setState({
            avoidCountryName: event.target.value
        })
    }
    onStayCountryNameChange = name => event => {
        this.setState({
            stayCountryName: event.target.value
        })
    }
    onOriginLevelChange = event => {
        this.setState({
            originLevel: parseInt(Number(event.target.value))
        })
    }

    onDestinationLevelChange = event => {
        this.setState({
            destinationLevel: parseInt(Number(event.target.value))
        })
    }

    onStartTimeChange = event => {
        this.setState({
            startTime: event.target.value
        })
    }

    onEndpointChange = event => {
        let comparisonTarget = {}
        Object.keys(compare).map(function (key) {
            if (event.target.value == compare[key].val) { // intentional `==`
                comparisonTarget = compare[key]
            }
        })
        this.setState({
            comparisonTarget: comparisonTarget,
        })
    }

    onRoutingProviderEndpointChange = event => {
        let providerTarget = {}
        let useAgents = false
        Object.keys(routingProvider).map(function (key) {
            if (event.target.value == routingProvider[key].val) { // intentional `==`
                providerTarget = routingProvider[key]
            }
            if (providerTarget.val === routingProvider.simulationRouter.val) {
                useAgents = true
            }
        })
        this.setState({
            providerTarget: providerTarget,
            useAgents: useAgents,
        })
    }

    onVehicleTypeChange = event => {
        let vehicle = {}
        Object.keys(vehicleType).map(function (key) {
            if (event.target.value == vehicleType[key].val) { // intentional `==`
                vehicle = vehicleType[key]
            }
        })
        this.setState({
            vehicleType: vehicle,
        })
    }

    onCheckboxTick = name => (event, checked) => {
        let current = this.state[name]
        this.setState({
            [name]: !current,
            targetUrl: 'nugraph'
        })
    }

    onTimeoutChange = event => {
        this.setState({
            timeout: event.target.value,
        })
    }

    onSwitchTimeoutChange = event => {
        this.setState({
            switchTimeout: event.target.value,
        })
    }

    onAgentsChange = event => {
        this.setState({
            nAgents: event.target.value,
        })
    }

    onAgentStartIntervalChange = event => {
        this.setState({
            agentStartInterval: event.target.value,
        })
    }

    onRoutingLayersChange = event => {
        this.setState({
            routingLayers: event.target.value,
        })
    }

    onSessionIdChange = event => {
        this.setState({
            sessionId: event.target.value,
        })
    }

    handleInputChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    resetChartData = (target) => {
        this.setState({
            chartData: null,
            comparisonChartData: null
        })
    }

    onRecalculateClick = () => {
        if (this.state.calculationInProgress) {
            this.cancelRoute()
            this.resetChartData('')
        } else {
            this.updateRoute()
        }
    }

    cancelRoute = () => {
        if (this.client) {
            this.client.close()
            this.setState({
                calculationInProgress: false
            })
        }
    }

    onHeadingChange = (val) => {
        this.setState({
            heading: val
        })
    }

    onDestinationHeadingChange = (val) => {
        this.setState({
            destinationHeading: val
        })
    }

    onToggleChange = (stateId, enable, disable) => {
        if (this.state.stateId && disable !== undefined) {
            this.setState({
                [disable]: false,
            })
        }
        if (this.state.stateId && enable !== undefined) {
            this.setState({
                [enable]: true,
            })
        }
        let newState = !this.state[stateId]
        this.setState({
            [stateId]: newState
        })
    }

    onDotsMenuOpen = (e) => {
        this.setState({
            menuCascadeAnchorEl: e.currentTarget
        })
    }

    onDotsMenuClosed = () => {
        this.setState({
            menuCascadeAnchorEl: null
        })
    }

    onTruckSliderChange = (e, val) => {
        this.setState({
            truckSliderValue: val,
        }, this.updateTruckSliderRoutes)
    }

    onStartTimeSliderChange = (e, val) => {
        this.setState({
            startTimeSliderIndex: val,
        }, this.updateStartTimeSliderRoutes)
    }

    updateTruckSliderRoutes = () => {
        let routes = this.state.truckSliderRoutes
        let fraction = this.state.truckSliderValue
        let numRoutes = routes.length - 1
        let routeIdx = Math.floor(fraction * numRoutes)
        if (routes && numRoutes >= 0) {
            this.unpackRoute(routes[routeIdx], false, false, "", "")

            let truckProps = this.state.truckProps
            let usedParams = routes[routeIdx].getTruckParameters()
            truckProps.maxAngle.value = usedParams.getMaxAngle()
            truckProps.angleMultiplier.value = usedParams.getAnglePenaltyFactor()
            truckProps.primaryPlusMultiplier.value = usedParams.getPrimaryPlusMultiplier()
            truckProps.secondaryMultiplier.value = usedParams.getSecondaryMultiplier()
            truckProps.serviceResidentialTertiaryMultiplier.value = usedParams.getServiceResidentialTertiaryMultiplier()
            truckProps.trackUnknownConstructionMultiplier.value = usedParams.getTrackUnknownConstructionMultiplier()
            truckProps.truckSpeedLowerBound.value = usedParams.getTruckSpeedLowerBoundKmh()
            truckProps.truckSpeedUpperBound.value = usedParams.getTruckSpeedUpperBoundKmh()
            truckProps.truckSpeedTransformationFactor.value = usedParams.getTruckSpeedTransformationFactor()
            this.setState({
                truckProps: truckProps
            })
        }
    }

    updateStartTimeSliderRoutes = () => {
        let routes = this.state.startTimeSliderRoutes
        if (routes && routes.length > 0) {
            this.unpackRoute(routes[this.state.startTimeSliderIndex], false, false, "", "")
        }
    }

    resetParams = () => {
        // clone the default params (slice method doesn't work on array of objects)
        let truckProps = JSON.parse(JSON.stringify(defaultParams))
        this.setState({
            truckProps: truckProps
        })
    }

    resetVehicleProps = () => {
        let vehicleProps = JSON.parse(JSON.stringify(defaultVehicleProps))
        this.setState({
            vehicleProps: vehicleProps
        })
    }

    // get the route request parameters from the url
    parseUrl = ({location}) => {
        // this is called on every render update, so only do this the first time
        if (this.state.loaded) {
            return null
        }

        // get the query params and initialise object to hold state updates
        let opts = qs.parse(location.search)
        let newStates = {loaded: true}

        // update state values from the query
        let urlParamConverter = urlParamConversion(routingProvider, compare, vehicleType, this.state.truckProps, this.state.vehicleProps)
        Object.keys(opts).map(function (key) {
            let converter = urlParamConverter[key]
            if (converter) {
                converter.parseUrl(opts[key], newStates)
            }
        })

        this.setState(newStates, () => {
            if (this.state.startCoords && this.state.newDestCoords && this.state.newDestCoords[0] &&
                this.state.startCoords.lat && this.state.newDestCoords[0].lat) {
                this.gettingRouteFromURL = true
                this.setPin(PinStartName, this.state.startCoords, opts.origin)
                this.setPin(PinDestName, this.state.newDestCoords[0], opts.destination)
            }
        })

        return null
    }

    render() {
        let routeColorMarker = null
        let comparisonRouteColorMarker = null
        let routeContent = null
        if (this.state.routeJson) {
            let color = null
            if (this.state.comparisonRoute) {
                color = 'blue'
                routeColorMarker = (
                    <div style={{
                        'width': '5px',
                        'height': '20px',
                        'marginRight': '5px',
                        'backgroundColor': color
                    }}></div>
                )
            }

            routeContent = (<Card className={styles.routeCard}>
                <CardContent className={styles.routeCardContent}>
                    <div className={styles.route}>
                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Length</Typography>
                            <Typography
                                variant={'subtitle1'}>{this.state.routeJson.features[0].properties.length}</Typography>
                        </div>
                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Travel time</Typography>
                            <Typography
                                variant={'subtitle1'}>{this.state.routeJson.features[0].properties.duration}</Typography>
                        </div>
                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Dijkstra cost</Typography>
                            <Typography
                                variant={'subtitle1'}>{this.state.routeJson.features[0].properties.dijkstraCost}</Typography>
                        </div>

                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Response JSON</Typography>
                        </div>
                        <ReactJson style={{
                            'height': '100%',
                            'width': '100%',
                            'overflow': 'scroll',
                            'boxSizing': 'border-box'
                        }} src={this.state.routeJson} collapsed={1}/>
                    </div>
                </CardContent>
            </Card>)
        }

        let comparisonRouteContent = null
        if (this.state.comparisonRoute) {
            comparisonRouteColorMarker = (
                <div style={{'width': '5px', 'height': '20px', 'marginRight': '5px', 'backgroundColor': 'red'}}></div>
            )

            comparisonRouteContent = (<Card className={styles.routeCard}>
                <CardContent className={styles.routeCardContent}>
                    <div className={styles.route}>
                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Length</Typography>
                            <Typography
                                variant={'subtitle1'}>{this.state.comparisonRoute.features[0].properties.length} m</Typography>
                        </div>
                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Travel time</Typography>
                            <Typography
                                variant={'subtitle1'}>{this.state.comparisonRoute.features[0].properties.duration}</Typography>
                        </div>

                        <div className={styles.infoBox}>
                            <Typography variant={'subtitle1'} color='primary'>Response JSON</Typography>
                        </div>
                        <ReactJson style={{
                            'height': '100%',
                            'width': '100%',
                            'overflow': 'scroll',
                            'boxSizing': 'border-box'
                        }} src={this.state.comparisonRoute} collapsed={1}/>
                    </div>
                </CardContent>
            </Card>)
        }

        let recalculateButton = null
        if (this.state.calculationInProgress) {
            recalculateButton = <Button className={styles.recalculateButton} variant='contained'
                                        onClick={this.onRecalculateClick}>Cancel</Button>
        } else {
            recalculateButton = <Button className={styles.recalculateButton} variant='contained'
                                        onClick={this.onRecalculateClick}>Recalculate</Button>
        }

        let truckParametersCard = null
        if (this.state.useParams) {
            truckParametersCard = <ParametersCard
                title={'Truck routing parameters'}
                disabled={this.state.truckSlider}
                properties={this.state.truckProps}
                defaultProperties={defaultParams}
                onChangeFunc={this.onTruckPropChange}
                resetFunc={this.resetParams}
            />
        }

        let vehicleParametersCard = null
        if (this.state.useVehicleProps) {
            vehicleParametersCard = <ParametersCard
                title={'Vehicle parameters'}
                properties={this.state.vehicleProps}
                defaultProperties={defaultVehicleProps}
                onChangeFunc={this.onVehiclePropChange}
                resetFunc={this.resetVehicleProps}
            />
        }

        let chart = null
        if (this.state.makeChart && this.state.chartData) {
            if (this.state.useAgents) {
                let data = []
                let comparisonData = null
                let label = 'journey distance (km)'
                let objectId = 'distanceKm'
                let timeXAxis = false
                if (this.state.useJourneyTime) {
                    label = 'journey time'
                    objectId = 'journeyTimeSeconds'
                    timeXAxis = true
                }
                for (let i = 0; i < this.state.chartData.length; i++) {
                    data.push(this.state.chartData[i].toObject()[objectId])
                }
                if (this.state.comparisonChartData) {
                    comparisonData = []
                    for (let i = 0; i < this.state.comparisonChartData.length; i++) {
                        comparisonData.push(this.state.comparisonChartData[i].toObject()[objectId])
                    }
                }
                chart = (<MyHistogram
                    data={data}
                    comparisonData={comparisonData}
                    normalized={true}
                    xLabel={label}
                    timeXAxis={timeXAxis}
                    height={400}
                    width={500}
                    showStats={true}
                />)
            } else {
                chart = (<AccelerationChartStacked
                    chartClickCallback={this.setAccelerationLocation}
                    data={this.state.chartData}
                    height={400}
                    width={500}
                />)
            }
        }

        let knob = null
        if (this.state.useHeading) {
            knob = (<div>
                <Knob
                    style={{display: 'inline-block'}}
                    min={0}
                    max={360}
                    preciseMode={false}
                    unlockDistance={5}
                    onChange={val => {
                        this.onHeadingChange(val);
                    }}
                    value={this.state.heading}
                />
            </div>)
        }

        let destinationKnob = null
        if (this.state.useDestinationHeading) {
            destinationKnob = (<div className={styles.destinationKnob}>
                <div>
                    <Typography>dst</Typography>
                </div>
                <div>
                    <Knob
                        style={{display: 'inline-block'}}
                        min={0}
                        max={360}
                        preciseMode={false}
                        unlockDistance={5}
                        onChange={val => {
                            this.onDestinationHeadingChange(val);
                        }}
                        value={this.state.destinationHeading}
                    />
                </div>
            </div>)
        }

        let journeyTimeToggle = null
        if (this.state.makeChart && this.state.useAgents) {
            journeyTimeToggle = (
              <div>
                <Typography gutterBottom> use journey time </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.useJourneyTime}
                            onChange={() => this.onToggleChange('useJourneyTime')}
                            color='primary'
                            value='dynamic-class-name'
                        />
                    }
                    className={styles.materialSlider}
                />
              </div>
            )
        }

        let agents = null
        if (this.state.useAgents) {
            agents = (<div className={styles.toggleRow}>
                <TextField
                  style={{'width':'26%', 'paddingRight':'20px'}}
                  value={this.state.nAgents}
                  type={'number'}
                  label='number of agents'
                  onChange={this.onAgentsChange}/>
                <div className={styles.agentsText} style={{'display': 'flex', 'alignItems': 'center', 'height':'40px', 'paddingRight':'10px'}}>agent start interval:</div>
                <input className={styles.agentsInput} style={{'height':'40px'}} type='input' value={this.state.agentStartInterval}
                       onChange={this.onAgentStartIntervalChange}/>
            </div>)
        }

        let switchTimeout = null
        if (this.state.useSwitchTimeout) {
            switchTimeout = (<div className={styles.timeout}>
                <div className={styles.timeoutText}>switch timeout:</div>
                <input className={styles.timeoutInput} type='input' value={this.state.switchTimeout}
                       onChange={this.onSwitchTimeoutChange}/>
            </div>)
        }

        let routingLayersInput = null
        if (this.state.useRoutingLayers) {
            routingLayersInput = (<div className={styles.routingLayers}>
                <div className={styles.routingLayersText}>routing layer (comma separated):</div>
                <input className={styles.routingLayersInput} type='input' value={this.state.routingLayers}
                       onChange={this.onRoutingLayersChange}/>
            </div>)
        }

        let sessionIdInput = null
        if (this.state.setSessionId) {
            sessionIdInput = (<div className={styles.routingLayers}>
                <TextField className={styles.routingLayersInput} variant='standard' value={this.state.sessionId}
                       onChange={this.onSessionIdChange} label='session id'/>
            </div>)
        }

        let eventRoutingInput = null
        if (this.state.providerTarget.val === routingProvider.eventRouter.val) {
            eventRoutingInput = (<div>
                <div className={styles.eventRouting}>
                    <div>
                        <div className={styles.routingLayersText}>Feature event id:</div>
                        <input name='featuredEventId' className={styles.routingLayersInput} type='input'
                               value={this.state.featuredEventId} onChange={this.handleInputChange}/>
                    </div>
                    <div>
                        <div className={styles.routingLayersText}>Role:</div>
                        <input name='role' className={styles.routingLayersInput} type='input' value={this.state.role}
                               onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className={styles.eventRouting}>
                    <div>
                        <div className={styles.routingLayersText}>Vehicle type:</div>
                        <input name='vehicleTypeEvent' className={styles.routingLayersInput} type='input'
                               value={this.state.vehicleTypeEvent} onChange={this.handleInputChange}/>
                    </div>
                    <div>
                        <div className={styles.routingLayersText}>Trip direction:</div>
                        <input name='tripDirection' className={styles.routingLayersInput} type='input'
                               value={this.state.tripDirection} onChange={this.handleInputChange}/>
                    </div>
                    <div>
                        <div className={styles.routingLayersText}>Parking area id:</div>
                        <input name='parkingAreaId' className={styles.routingLayersInput} type='input'
                               value={this.state.parkingAreaId} onChange={this.handleInputChange}/>
                    </div>
                </div>
            </div>)
        }

        let routingEndpointOptions = null
        if (this.state.comparisonTarget.val == compare.customCompare.val) {  // intentionally `==`
            routingEndpointOptions = (
                <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.comparisonTarget.url}
                    onChange={this.onTargetUrlChange()}/>
            )
        }

        let destinationStreetName = null
        if (this.state.useDestinationStreetName) {  // intentionally `==`
            destinationStreetName = (
                <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.destinationStreetName}
                    label='destination street name'
                    onChange={this.onDestinationStreetNameChange()}/>
            )
        }

        let avoidCountryName = null
        if (this.state.useAvoidCountryName) {
            if (this.state.useStayCountryName){
                this.state.stayCountryName = ''
                this.state.useStayCountryName = false
            }
            avoidCountryName = (
                <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.avoidCountryName}
                    label='Avoid Country name'
                    onChange={this.onAvoidCountryNameChange()}/>
            )
        }
        let stayCountryName = null
        if (this.state.useStayCountryName) {
            if (this.state.useAvoidCountryName){
                this.state.avoidCountryName = ''
                this.state.useAvoidCountryName = false
            }
            stayCountryName = (
                <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.stayCountryName}
                    label='Stay Country name'
                    onChange={this.onStayCountryNameChange()}/>
            )
        }
        let truckSliderStep = 0.1
        if (this.state.truckSliderRoutes) {
            let numSteps = this.state.truckSliderRoutes.length
            numSteps = Math.max(numSteps, 2)
            truckSliderStep = 1 / (numSteps - 1)
        }

        let truckSlider = null
        if (this.state.truckSlider) {
            truckSlider = (<div className={styles.toggleRow}>
                <LocalShippingIcon color='primary' style={{marginRight: '10px'}}/>
                <Slider
                  defaultValue={this.state.truckSliderValue}
                  onChange={this.onTruckSliderChange}
                  style={{marginRight: '10px'}}
                  min={0}
                  max={1}
                  step={truckSliderStep}
                />
                <LocalShippingIcon color='primary' style={{width: '40px', height: '40px', marginTop: '-8px'}}/>
            </div>)
        }

        let startTimeSlider = null
        let startTimesChart = null
        if (this.state.startTimeSlider && this.state.startTimeSliderRoutes && this.state.startTimeSliderRoutes.length > 0) {
            let max = this.state.startTimeSliderRoutes.length - 1
            let times = []
            for (let i = 0; i < this.state.startTimeSliderRoutes.length; i++) {
                times.push(this.state.startTimeSliderRoutes[i].getStartTime())
            }
            let getText = function valuetext(value) {
                return `${times[value]}`
            }
            startTimeSlider = (<div className={styles.toggleRow}>
                <ClockIcon color='primary' style={{marginRight: '10px'}}/>
                <Slider
                  value={this.state.startTimeSliderIndex}
                  onChange={this.onStartTimeSliderChange}
                  getAriaValueText={getText}
                  valueLabelFormat={getText}
                  aria-label="Temperature"
                  valueLabelDisplay="auto"
                  style={{marginRight: '10px'}}
                  min={0}
                  max={max}
                  step={1}
                />
            </div>)
            let xData = []
            let yData = []
            for (let i = 0; i < this.state.startTimeSliderRoutes.length; i++) {
                xData.push(this.state.startTimeSliderRoutes[i].getStartTime())
                yData.push(this.state.startTimeSliderRoutes[i].getDuration().getSeconds())
            }
            startTimesChart = <StartTimesChart xData={xData} yData={yData} currentIdx={this.state.startTimeSliderIndex}/>
        }

        let originLevel = null
        if (this.state.useOriginLevel) {
            originLevel = (
              <div className={styles.toggleRow}>
                  <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.originLevel}
                    type={'number'}
                    label='origin level (for multi-storey)'
                    onChange={this.onOriginLevelChange}/>
              </div>
            )
        }

        let destinationLevel = null
        if (this.state.useDestinationLevel) {
            destinationLevel = (
              <div className={styles.toggleRow}>
                  <TextField
                    style={{'flexGrow': '1'}}
                    value={this.state.destinationLevel}
                    type={'number'}
                    label='destination level (for multi-storey)'
                    onChange={this.onDestinationLevelChange}/>
              </div>
            )
        }

        let timePicker = null
        if (this.state.customStartTime) {
            timePicker = (
              <div className={styles.toggleRow}>
                  <TextField
                    label="Route start time (UTC)"
                    type="datetime-local"
                    value={this.state.startTime}
                    InputLabelProps={{shrink: true}}
                    onChange={this.onStartTimeChange}
                  />
              </div>
            )
        }

        let reservationChart = null
        if (this.state.showReservation && this.state.reservationData) {
            reservationChart = (
              <div>
                <ReservationChart id={this.state.reservationData.id} properties={this.state.reservationData}/>
              </div>
            )
        }

        let { menuCascadeAnchorEl } = this.state;

        return (
            <div className={styles.body}>
                <Router>
                    <Route path='/:opts' component={this.parseUrl}/>
                </Router>
                <SkyLight hideOnOverlayClicked
                          dialogStyles={{width: '30%', height: '10px', marginTop: '-300px', marginLeft: '0%'}}
                          ref={this.errorDialog} title={this.state.popupTitle}>
                    <div style={{'white-space': 'pre-wrap'}}>
                        {this.state.popupMsg}
                    </div>
                </SkyLight>
                <Card className={styles.inputCard}>
                    <CardContent className={styles.inputCardContent}>
                        <div className={styles.inputRow}>
                            <img src={startIcon} alt={''} className={styles.image}/>
                            <Geosuggest
                                placeholder='Start typing!'
                                initialValue={this.state.startStr}
                                onSuggestSelect={(suggest) => this.onSuggestSelect(suggest, PinStartName)}
                                style={geosuggestStyle}
                                className={styles.geosuggest}
                            />
                            <Button className={styles.recalculateButton} variant='contained'
                                    onClick={this.clearRoute}>Clear</Button>
                            <IconButton
                                aria-owns={menuCascadeAnchorEl ? 'features-menu' : undefined}
                                aria-label='More'
                                aria-haspopup='true'
                                onClick={this.onDotsMenuOpen}
                                className={styles.dotsMenuButton}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <CascadeMenu
                              open={Boolean(menuCascadeAnchorEl)}
                              anchorElement={menuCascadeAnchorEl}
                              anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                              onClose={this.onDotsMenuClosed}
                              menuItems={menuItems}
                              componentFunc={(menuItem) => {
                                  return <FormControlLabel
                                      control={
                                          <Switch
                                            checked={this.state[menuItem.stateId]}
                                            onChange={() => this.onToggleChange(menuItem.stateId, menuItem.enable, menuItem.disable)}
                                            color='primary'
                                            value='dynamic-class-name'
                                          />
                                      }
                                      label={menuItem.display}
                                      className={styles.materialSlider}
                                    />
                              }}
                              extraDebugItems={
                                  <div>
                                    <Typography>download route:</Typography>
                                    <Button className={styles.recalculateButton} variant='contained' onClick={this.onGpx}>GPX</Button>
                                    <Button className={styles.recalculateButton} variant='contained' onClick={this.onGeoJson}>GeoJson</Button>
                                  </div>
                                }
                            />
                        </div>
                        <div className={styles.inputRow}>
                            <img src={destIcon} alt={''} className={styles.image}/>
                            <Geosuggest
                                placeholder='Start typing!'
                                initialValue={this.state.destStr}
                                onSuggestSelect={(suggest) => this.onSuggestSelect(suggest, PinDestName)}
                                style={geosuggestStyle}
                                className={styles.geosuggest}
                            />
                            {recalculateButton}
                        </div>

                        <div className={styles.toggleRow}>
                            <div className={styles.timeout}>
                                <div className={styles.timeoutText}>timeout:</div>
                                <input className={styles.timeoutInput} type='input' value={this.state.timeout}
                                       onChange={this.onTimeoutChange}/>
                            </div>

                            <Button className={styles.recalculateButton} variant='contained'
                                    onClick={this.inverse}>inverse</Button>

                            {switchTimeout}
                            {journeyTimeToggle}
                            {knob}
                            {destinationKnob}
                        </div>
                        {agents}
                        {truckSlider}
                        {startTimeSlider}
                        {startTimesChart}
                        {routingLayersInput}
                        {sessionIdInput}
                        {eventRoutingInput}
                        <div className={styles.toggleRow}>
                            {destinationStreetName}
                        </div>
                        <div className={styles.toggleRow}>
                            {avoidCountryName}
                        </div>
                        <div className={styles.toggleRow}>
                            {stayCountryName}
                        </div>
                        {originLevel}
                        {destinationLevel}
                        {timePicker}
                        {chart}
                        <ExpansionPanel className={styles.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Routing endpoint config</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{'flexDirection': 'column'}}>
                                <div className={styles.targetUrlRow}>
                                    <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                                                noWrap>Vehicle type</Typography>
                                    <FormControl>
                                        <NativeSelect
                                          defaultValue={vehicleType.car.val}
                                          value={this.state.vehicleType.val}
                                          onChange={this.onVehicleTypeChange}
                                          input={<Input name='name' id='uncontrolled-native'/>}
                                        >
                                            {
                                                Object.keys(vehicleType).map(function (key) {
                                                    return (
                                                      <option
                                                        value={vehicleType[key].val}>{vehicleType[key].text}</option>
                                                    )
                                                })
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </div>

                                <div className={styles.targetUrlRow}>
                                    {routeColorMarker}
                                    <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                                                noWrap>Routing provider</Typography>
                                    <FormControl>
                                        <NativeSelect
                                            defaultValue={routingProvider.europeRouterProvider.val}
                                            value={this.state.providerTarget.val}
                                            onChange={this.onRoutingProviderEndpointChange}
                                            input={<Input name='name' id='uncontrolled-native'/>}
                                        >
                                            {
                                                Object.keys(routingProvider).map(function (key) {
                                                    return (
                                                        <option
                                                            value={routingProvider[key].val}>{routingProvider[key].text}</option>
                                                    )
                                                })
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </div>

                                <div className={styles.targetUrlRow}>
                                    {comparisonRouteColorMarker}
                                    <Checkbox checked={this.state.doCompare} style={{padding: '0px'}}
                                              onChange={this.onCheckboxTick('doCompare')}/>
                                    <Typography style={{'marginRight': '20px'}} variant={'subtitle1'} color='primary'
                                                noWrap>Compare with</Typography>
                                    <FormControl>
                                        <NativeSelect
                                            defaultValue={compare.europeTruckRouterCompare.val}
                                            value={this.state.comparisonTarget.val}
                                            onChange={this.onEndpointChange}
                                            input={<Input name='name' id='uncontrolled-native'/>}
                                        >
                                            {
                                                Object.keys(compare).map(function (key) {
                                                    return (
                                                        <option
                                                            value={compare[key].val}>{compare[key].text}</option>
                                                    )
                                                })
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </div>

                                <div className={styles.targetUrlRow}>
                                    {routingEndpointOptions}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        {truckParametersCard}
                        {vehicleParametersCard}

                    </CardContent>
                </Card>
                <div className={styles.routeRow}>
                    {reservationChart}
                </div>
                <div className={styles.routeRow}>
                    {routeContent}
                    {comparisonRouteContent}
                </div>
            </div>
        )
    }
}
