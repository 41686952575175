export const SALZBURG_LOCATION = [
    { lng: 13.020095, lat: 47.804860 , name: '00092'},
    { lng: 13.041810, lat: 47.810855 , name: '00005'},
    { lng: 13.006352, lat: 47.828490 , name: '00052'},
    { lng: 13.021898, lat: 47.797180 , name: '00078'},
    { lng: 13.038872, lat: 47.811017 , name: '00087'},
    { lng: 13.053686, lat: 47.813404 , name: '00128'},
    { lng: 12.991750, lat: 47.792242 , name: '00201'},
    { lng: 13.040904, lat: 47.799455 , name: '00012'},
    { lng: 13.039302, lat: 47.805356 , name: '00030'},
    { lng: 13.067264, lat: 47.797829 , name: '00096'},
    { lng: 13.039173, lat: 47.806410 , name: '00138'},
    { lng: 13.042847, lat: 47.802638 , name: '00008'},
    { lng: 13.043195, lat: 47.805304 , name: '00032'},
    { lng: 13.030329, lat: 47.815406 , name: '00066'},
    { lng: 13.057595, lat: 47.785805 , name: '00121'},
    { lng: 13.066412, lat: 47.803521 , name: '00125'},
    { lng: 13.016537, lat: 47.786152 , name: '00135'},
    { lng: 13.021429, lat: 47.825400 , name: '00110'},
    { lng: 13.028604, lat: 47.791481 , name: '00116'},
    { lng: 13.065878, lat: 47.809117 , name: '00034'},
    { lng: 13.033221, lat: 47.809160 , name: '00036'},
    { lng: 13.066326, lat: 47.786030 , name: '00038'},
    { lng: 13.056234, lat: 47.826906 , name: '00060'},
    { lng: 13.063996, lat: 47.809754 , name: '00063'},
    { lng: 13.018869, lat: 47.801736 , name: '00062'},
    { lng: 13.030043, lat: 47.815462 , name: '00066'},
    { lng: 13.057755, lat: 47.809150 , name: '00086'},
    { lng: 13.036416, lat: 47.793638 , name: '00090'},
    { lng: 13.008059, lat: 47.798516 , name: '00120'},
    { lng: 13.041105, lat: 47.818751 , name: '00150'},
    { lng: 13.033295, lat: 47.811221 , name: '00007'},
    { lng: 13.042969, lat: 47.802469 , name: '00008'},
    { lng: 13.050913, lat: 47.805310 , name: '00126'},
    { lng: 13.018719, lat: 47.803048 , name: '00139'},
    { lng: 13.040418, lat: 47.810679 , name: '00010'},
    { lng: 13.064046, lat: 47.809478 , name: '00063'},
    { lng: 13.059947, lat: 47.809594 , name: '00064'},
    { lng: 13.053023, lat: 47.805929 , name: '00082'},
    { lng: 13.066853, lat: 47.797836 , name: '00096'},
    { lng: 13.026128, lat: 47.811623 , name: '00009'},
    { lng: 13.026447, lat: 47.808738 , name: '00037'},
    { lng: 13.022469, lat: 47.812663 , name: '00061'},
    { lng: 13.022307, lat: 47.797017 , name: '00078'},
    { lng: 13.019944, lat: 47.798258 , name: '00136'},
    { lng: 13.026733, lat: 47.811590 , name: '00009'},
    { lng: 13.040911, lat: 47.814218 , name: '00024'},
    { lng: 13.033917, lat: 47.806882 , name: '00079'},
    { lng: 13.046733, lat: 47.807108 , name: '00100'},
    { lng: 12.992257, lat: 47.792065 , name: '00201'},
    { lng: 13.023034, lat: 47.805379 , name: '00058'},
    { lng: 13.069251, lat: 47.814886 , name: '00067'},
    { lng: 13.038412, lat: 47.810932 , name: '00087'},
    { lng: 13.046607, lat: 47.807346 , name: '00100'},
    { lng: 13.055261, lat: 47.799060 , name: '00044'},
    { lng: 13.052196, lat: 47.792783 , name: '00065'},
    { lng: 13.041661, lat: 47.774287 , name: '00083'},
    { lng: 13.061616, lat: 47.809620 , name: '00130'},
    { lng: 13.042230, lat: 47.817961 , name: '00150'},
    { lng: 13.044709, lat: 47.801489 , name: '00001'},
    { lng: 13.018742, lat: 47.802053 , name: '00062'},
    { lng: 13.069097, lat: 47.773701 , name: '00018'},
    { lng: 13.042042, lat: 47.814266 , name: '00019'},
    { lng: 13.063235, lat: 47.791366 , name: '00048'},
    { lng: 13.053807, lat: 47.815581 , name: '00054'},
    { lng: 13.052820, lat: 47.797460 , name: '00006'},
    { lng: 13.043214, lat: 47.802469 , name: '00008'},
    { lng: 13.041831, lat: 47.807411 , name: '00025'},
    { lng: 13.064563, lat: 47.785847 , name: '00088'},
    { lng: 13.056660, lat: 47.829251 , name: '00202'},
    { lng: 13.043958, lat: 47.804258 , name: '00003'},
    { lng: 13.030818, lat: 47.797901 , name: '00017'},
    { lng: 13.065639, lat: 47.808884 , name: '00034'},
    { lng: 13.027790, lat: 47.796334 , name: '00093'},
    { lng: 13.048632, lat: 47.799395 , name: '00004'},
    { lng: 13.067482, lat: 47.781559 , name: '00050'},
    { lng: 13.064410, lat: 47.809612 , name: '00063'},
    { lng: 13.022504, lat: 47.828021 , name: '00123'},
    { lng: 13.042677, lat: 47.806112 , name: '00031'},
    { lng: 13.061297, lat: 47.809749 , name: '00130'},
    { lng: 13.042198, lat: 47.800861 , name: '00014'},
    { lng: 13.021855, lat: 47.820293 , name: '00075'},
    { lng: 13.018443, lat: 47.818823 , name: '00076'},
    { lng: 13.067757, lat: 47.781756 , name: '00050'},
    { lng: 13.056285, lat: 47.826908 , name: '00060'},
    { lng: 13.053204, lat: 47.820730 , name: '00073'},
    { lng: 13.027582, lat: 47.796250 , name: '00093'},
    { lng: 13.044108, lat: 47.822739 , name: '00124'},
    { lng: 12.991714, lat: 47.792087 , name: '00201'},
    { lng: 13.043274, lat: 47.802723 , name: '00008'},
    { lng: 13.066058, lat: 47.788520 , name: '00049'},
    { lng: 13.004623, lat: 47.817239 , name: '00059'},
    { lng: 13.080224, lat: 47.786047 , name: '00152'},
    { lng: 13.072598, lat: 47.793217 , name: '00112'},
    { lng: 13.047792, lat: 47.789428 , name: '00115'},
    { lng: 13.053443, lat: 47.797093 , name: '00006'},
    { lng: 13.026827, lat: 47.808810 , name: '00037'},
    { lng: 13.062915, lat: 47.791517 , name: '00048'},
    { lng: 13.051747, lat: 47.786644 , name: '00053'},
    { lng: 13.064155, lat: 47.809902 , name: '00063'},
    { lng: 13.047389, lat: 47.824968 , name: '00098'},
    { lng: 13.023608, lat: 47.796607 , name: '00131'},
    { lng: 13.068634, lat: 47.776249 , name: '00154'},
    { lng: 12.992337, lat: 47.792101 , name: '00201'},
    { lng: 13.042160, lat: 47.814143 , name: '00019'},
    { lng: 13.069475, lat: 47.814932 , name: '00067'},
    { lng: 13.047118, lat: 47.824852 , name: '00098'},
    { lng: 13.054231, lat: 47.826019 , name: '00204'},
    { lng: 13.012699, lat: 47.824857 , name: '00210'},
    { lng: 13.065984, lat: 47.788896 , name: '00049'},
    { lng: 13.005942, lat: 47.828535 , name: '00052'},
    { lng: 13.028339, lat: 47.791490 , name: '00116'},
    { lng: 13.018672, lat: 47.803213 , name: '00139'},
    { lng: 13.041420, lat: 47.818886 , name: '00150'},
    { lng: 13.027015, lat: 47.807568 , name: '00155'},
    { lng: 13.044205, lat: 47.800528 , name: '00001'},
    { lng: 13.065369, lat: 47.809047 , name: '00034'},
    { lng: 13.071747, lat: 47.813399 , name: '00070'},
    { lng: 13.000387, lat: 47.831904 , name: '00105'},
    { lng: 13.042333, lat: 47.800916 , name: '00014'},
    { lng: 13.031018, lat: 47.797709 , name: '00017'},
    { lng: 13.020318, lat: 47.798001 , name: '00136'},
    { lng: 12.991734, lat: 47.792061 , name: '00201'},
    { lng: 13.026632, lat: 47.811626 , name: '00009'},
    { lng: 13.041673, lat: 47.807578 , name: '00025'},
    { lng: 13.033020, lat: 47.809303 , name: '00036'},
    { lng: 13.006352, lat: 47.828465 , name: '00052'},
    { lng: 13.025315, lat: 47.794227 , name: '00122'},
    { lng: 13.050905, lat: 47.805144 , name: '00126'},
    { lng: 13.027128, lat: 47.805995 , name: '00016'},
    { lng: 13.029817, lat: 47.811424 , name: '00057'},
    { lng: 13.064293, lat: 47.809848 , name: '00063'},
    { lng: 13.081179, lat: 47.779113 , name: '00097'},
    { lng: 13.034418, lat: 47.811129 , name: '00109'},
    { lng: 13.033135, lat: 47.811438 , name: '00007'},
    { lng: 13.040342, lat: 47.810681 , name: '00010'},
    { lng: 13.057606, lat: 47.808987 , name: '00086'},
    { lng: 13.045042, lat: 47.801247 , name: '00001'},
    { lng: 13.040540, lat: 47.799161 , name: '00012'},
    { lng: 13.042173, lat: 47.806828 , name: '00099'},
    { lng: 13.068951, lat: 47.773480 , name: '00018'},
    { lng: 13.067236, lat: 47.812525 , name: '00022'},
    { lng: 13.041956, lat: 47.810756 , name: '00005'},
    { lng: 13.065868, lat: 47.809290 , name: '00034'},
    { lng: 13.059564, lat: 47.793407 , name: '00047'},
    { lng: 13.015319, lat: 47.801672 , name: '00129'},
    { lng: 13.071592, lat: 47.818406 , name: '00145'},
    { lng: 13.050763, lat: 47.808528 , name: '00039'},
    { lng: 13.059581, lat: 47.793580 , name: '00047'},
    { lng: 13.022149, lat: 47.812679 , name: '00061'},
    { lng: 13.044407, lat: 47.800659 , name: '00001'},
    { lng: 13.026395, lat: 47.811738 , name: '00009'},
    { lng: 13.067839, lat: 47.813161 , name: '00022'},
    { lng: 13.053396, lat: 47.815477 , name: '00054'},
    { lng: 13.032086, lat: 47.794488 , name: '00089'},
    { lng: 13.005354, lat: 47.797420 , name: '00207'},
    { lng: 13.040556, lat: 47.810796 , name: '00010'},
    { lng: 13.038961, lat: 47.807376 , name: '00029'},
    { lng: 13.029716, lat: 47.811493 , name: '00057'},
    { lng: 13.064347, lat: 47.809849 , name: '00063'},
    { lng: 13.021613, lat: 47.814007 , name: '00041'},
    { lng: 13.048446, lat: 47.806159 , name: '00055'},
    { lng: 13.041648, lat: 47.774070 , name: '00083'},
    { lng: 13.057334, lat: 47.809093 , name: '00086'},
    { lng: 13.054405, lat: 47.825512 , name: '00204'},
    { lng: 13.042837, lat: 47.802675 , name: '00008'},
    { lng: 13.064436, lat: 47.809631 , name: '00063'},
    { lng: 13.006123, lat: 47.811384 , name: '00072'},
    { lng: 13.074034, lat: 47.790900 , name: '00091'},
    { lng: 13.005680, lat: 47.797404 , name: '00207'},
    { lng: 13.053188, lat: 47.818747 , name: '00084'},
    { lng: 13.029472, lat: 47.794194 , name: '00127'},
    { lng: 13.040576, lat: 47.817039 , name: '00132'},
    { lng: 13.054128, lat: 47.824118 , name: '00046'},
    { lng: 13.053380, lat: 47.820502 , name: '00073'},
    { lng: 13.032470, lat: 47.797492 , name: '00035'},
    { lng: 13.021589, lat: 47.820563 , name: '00075'},
    { lng: 13.037603, lat: 47.826791 , name: '00077'},
    { lng: 13.033688, lat: 47.806693 , name: '00079'},
    { lng: 13.067660, lat: 47.813055 , name: '00022'},
    { lng: 13.042744, lat: 47.810716 , name: '00015'},
    { lng: 13.027356, lat: 47.805675 , name: '00016'},
    { lng: 13.024305, lat: 47.780408 , name: '00045'},
    { lng: 13.028964, lat: 47.792694 , name: '00056'},
    { lng: 13.059775, lat: 47.809397 , name: '00064'},
    { lng: 13.044395, lat: 47.822694 , name: '00124'},
    { lng: 12.992152, lat: 47.792277 , name: '00201'},
    { lng: 13.054282, lat: 47.825152 , name: '00204'},
    { lng: 13.012794, lat: 47.824808 , name: '00210'},
    { lng: 13.005941, lat: 47.828562 , name: '00052'},
    { lng: 13.051938, lat: 47.792498 , name: '00065'},
    { lng: 13.053158, lat: 47.820710 , name: '00073'},
    { lng: 13.021870, lat: 47.797009 , name: '00078'},
    { lng: 13.057508, lat: 47.809329 , name: '00086'},
    { lng: 13.039068, lat: 47.813136 , name: '00143'},
    { lng: 13.029153, lat: 47.802588 , name: '00021'},
    { lng: 13.043468, lat: 47.805004 , name: '00032'},
    { lng: 13.065942, lat: 47.788777 , name: '00049'},
    { lng: 13.048138, lat: 47.809038 , name: '00068'},
    { lng: 13.032124, lat: 47.794509 , name: '00089'},
    { lng: 13.054054, lat: 47.813327 , name: '00128'},
    { lng: 13.016566, lat: 47.786210 , name: '00135'},
    { lng: 13.026188, lat: 47.811595 , name: '00009'},
    { lng: 13.050986, lat: 47.808377 , name: '00039'},
    { lng: 13.068300, lat: 47.777683 , name: '00051'},
    { lng: 13.047444, lat: 47.790218 , name: '00085'},
    { lng: 13.054077, lat: 47.826168 , name: '00204'},
    { lng: 13.066722, lat: 47.786077 , name: '00038'},
    { lng: 13.042311, lat: 47.806679 , name: '00099'},
    { lng: 13.066292, lat: 47.803604 , name: '00125'},
    { lng: 13.035316, lat: 47.829470 , name: '00153'},
    { lng: 13.049493, lat: 47.800572 , name: '00028'},
    { lng: 13.069187, lat: 47.815051 , name: '00067'},
    { lng: 13.026856, lat: 47.807379 , name: '00155'},
    { lng: 13.021882, lat: 47.820504 , name: '00075'},
    { lng: 13.019013, lat: 47.817748 , name: '00081'},
    { lng: 13.000117, lat: 47.831813 , name: '00105'},
    { lng: 13.025017, lat: 47.794471 , name: '00122'},
    { lng: 13.054756, lat: 47.825564 , name: '00204'},
    { lng: 13.030979, lat: 47.797740 , name: '00017'},
    { lng: 13.055119, lat: 47.809017 , name: '00040'},
    { lng: 13.068476, lat: 47.777667 , name: '00051'},
    { lng: 13.028765, lat: 47.792926 , name: '00056'},
    { lng: 13.056397, lat: 47.829092 , name: '00202'},
    { lng: 13.054331, lat: 47.825498 , name: '00204'},
    { lng: 13.041608, lat: 47.810749 , name: '00005'},
    { lng: 13.052221, lat: 47.786419 , name: '00053'},
    { lng: 13.036181, lat: 47.793644 , name: '00090'},
    { lng: 13.004182, lat: 47.829306 , name: '00137'},
    { lng: 13.004111, lat: 47.828979 , name: '00137'},
    { lng: 13.025020, lat: 47.794264 , name: '00122'},
    { lng: 13.080850, lat: 47.783294 , name: '00148'},
    { lng: 13.029048, lat: 47.793008 , name: '00056'},
    { lng: 13.034329, lat: 47.806786 , name: '00079'},
    { lng: 13.023841, lat: 47.796594 , name: '00131'},
    { lng: 13.033103, lat: 47.806256 , name: '00144'},
    { lng: 13.053275, lat: 47.795794 , name: '00149'},
    { lng: 13.015603, lat: 47.801664 , name: '00129'},
    { lng: 13.041186, lat: 47.819107 , name: '00150'},
    { lng: 13.043991, lat: 47.804305 , name: '00003'},
    { lng: 13.042932, lat: 47.810510 , name: '00015'},
    { lng: 13.006173, lat: 47.811409 , name: '00072'},
    { lng: 13.052855, lat: 47.805852 , name: '00082'},
    { lng: 13.053244, lat: 47.818646 , name: '00084'},
    { lng: 13.038927, lat: 47.810711 , name: '00087'},
    { lng: 13.052520, lat: 47.797379 , name: '00006'},
    { lng: 13.033103, lat: 47.808976 , name: '00036'},
    { lng: 13.075446, lat: 47.820228 , name: '00118'},
    { lng: 13.067201, lat: 47.812539 , name: '00022'},
    { lng: 13.052366, lat: 47.792522 , name: '00065'},
    { lng: 13.068784, lat: 47.776435 , name: '00154'},
    { lng: 13.038693, lat: 47.810860 , name: '00087'},
    { lng: 13.028911, lat: 47.802989 , name: '00021'},
    { lng: 13.038853, lat: 47.807212 , name: '00029'},
    { lng: 13.053370, lat: 47.820463 , name: '00073'},
    { lng: 13.047792, lat: 47.800721 , name: '00151'},
    { lng: 13.040674, lat: 47.799432 , name: '00012'},
    { lng: 13.055633, lat: 47.807005 , name: '00042'},
    { lng: 13.028460, lat: 47.791371 , name: '00116'},
    { lng: 13.040254, lat: 47.810789 , name: '00010'},
    { lng: 13.060079, lat: 47.809303 , name: '00064'},
    { lng: 13.020963, lat: 47.826328 , name: '00110'},
    { lng: 13.026661, lat: 47.808669 , name: '00037'},
    { lng: 13.026028, lat: 47.784879 , name: '00071'},
    { lng: 13.005786, lat: 47.811555 , name: '00072'},
    { lng: 13.052920, lat: 47.820511 , name: '00073'},
    { lng: 13.054082, lat: 47.796832 , name: '00006'},
    { lng: 13.033376, lat: 47.811187 , name: '00007'},
    { lng: 13.051631, lat: 47.786444 , name: '00053'},
    { lng: 13.029334, lat: 47.794096 , name: '00127'},
    { lng: 13.071436, lat: 47.818146 , name: '00145'},
    { lng: 13.021592, lat: 47.813832 , name: '00041'},
    { lng: 13.068375, lat: 47.777480 , name: '00051'},
    { lng: 13.030287, lat: 47.815188 , name: '00066'},
    { lng: 13.071488, lat: 47.813257 , name: '00070'},
    { lng: 13.068173, lat: 47.813115 , name: '00022'},
    { lng: 13.015660, lat: 47.821295 , name: '00033'},
    { lng: 13.052140, lat: 47.792534 , name: '00065'},
    { lng: 13.050173, lat: 47.789878 , name: '00117'},
    { lng: 13.054555, lat: 47.825248 , name: '00204'},
    { lng: 13.005726, lat: 47.797609 , name: '00207'},
    { lng: 13.023091, lat: 47.805434 , name: '00058'},
    { lng: 13.047298, lat: 47.790489 , name: '00085'},
    { lng: 13.015637, lat: 47.801765 , name: '00129'},
    { lng: 13.043571, lat: 47.805208 , name: '00032'},
    { lng: 13.048385, lat: 47.805920 , name: '00055'},
    { lng: 13.047229, lat: 47.825090 , name: '00098'},
    { lng: 13.020003, lat: 47.798074 , name: '00136'},
    { lng: 12.992169, lat: 47.792256 , name: '00201'},
    { lng: 13.045047, lat: 47.801276 , name: '00001'},
    { lng: 13.066571, lat: 47.785936 , name: '00038'},
    { lng: 13.051257, lat: 47.808574 , name: '00039'},
    { lng: 13.047007, lat: 47.825086 , name: '00098'},
    { lng: 13.068535, lat: 47.776550 , name: '00154'},
    { lng: 13.046194, lat: 47.809978 , name: '00020'},
    { lng: 13.037643, lat: 47.826795 , name: '00077'},
    { lng: 13.057752, lat: 47.785819 , name: '00121'},
    { lng: 13.026803, lat: 47.807643 , name: '00155'},
    { lng: 13.059838, lat: 47.793396 , name: '00047'},
    { lng: 13.054230, lat: 47.825242 , name: '00204'},
    { lng: 13.033105, lat: 47.811045 , name: '00007'},
    { lng: 13.043383, lat: 47.810624 , name: '00015'},
    { lng: 13.029260, lat: 47.802818 , name: '00021'},
    { lng: 13.077780, lat: 47.822675 , name: '00103'},
    { lng: 13.034248, lat: 47.811107 , name: '00109'},
    { lng: 13.056622, lat: 47.828941 , name: '00202'},
    { lng: 13.046006, lat: 47.810217 , name: '00020'},
    { lng: 13.032580, lat: 47.797628 , name: '00035'},
    { lng: 13.068127, lat: 47.778484 , name: '00051'},
    { lng: 13.047860, lat: 47.789397 , name: '00115'},
    { lng: 13.007781, lat: 47.798496 , name: '00120'},
    { lng: 13.027196, lat: 47.806028 , name: '00016'},
    { lng: 13.026590, lat: 47.808923 , name: '00037'},
    { lng: 13.069442, lat: 47.815084 , name: '00067'},
    { lng: 13.077885, lat: 47.822887 , name: '00103'},
    { lng: 13.055846, lat: 47.806913 , name: '00042'},
    { lng: 13.042667, lat: 47.815281 , name: '00069'},
    { lng: 13.018403, lat: 47.818809 , name: '00076'},
    { lng: 13.070090, lat: 47.816141 , name: '00113'},
    { lng: 13.081086, lat: 47.783018 , name: '00148'},
    { lng: 13.080005, lat: 47.786167 , name: '00152'},
    { lng: 13.053107, lat: 47.820292 , name: '00073'},
    { lng: 13.061234, lat: 47.807580 , name: '00146'},
    { lng: 13.041384, lat: 47.819200 , name: '00150'},
    { lng: 13.080196, lat: 47.826078 , name: '00208'},
    { lng: 13.065347, lat: 47.809028 , name: '00034'},
    { lng: 13.048720, lat: 47.805879 , name: '00055'},
    { lng: 13.047030, lat: 47.790368 , name: '00085'},
    { lng: 13.036439, lat: 47.793563 , name: '00090'},
    { lng: 13.007684, lat: 47.798352 , name: '00120'},
    { lng: 13.004335, lat: 47.829081 , name: '00137'},
    { lng: 13.065795, lat: 47.788701 , name: '00049'},
    { lng: 13.066099, lat: 47.788956 , name: '00049'},
    { lng: 13.042161, lat: 47.823915 , name: '00119'},
    { lng: 13.044806, lat: 47.801212 , name: '00001'},
    { lng: 13.043783, lat: 47.804344 , name: '00003'},
    { lng: 13.067801, lat: 47.813280 , name: '00022'},
    { lng: 13.004724, lat: 47.816941 , name: '00059'},
    { lng: 13.008015, lat: 47.798345 , name: '00120'},
    { lng: 13.053466, lat: 47.797117 , name: '00006'},
    { lng: 13.050912, lat: 47.808719 , name: '00039'},
    { lng: 13.024347, lat: 47.780620 , name: '00045'},
    { lng: 13.006202, lat: 47.828658 , name: '00052'},
    { lng: 13.052574, lat: 47.797538 , name: '00006'},
    { lng: 13.043193, lat: 47.802521 , name: '00008'},
    { lng: 13.072913, lat: 47.793150 , name: '00112'},
    { lng: 13.044314, lat: 47.822521 , name: '00124'},
    { lng: 13.033305, lat: 47.806367 , name: '00144'},
    { lng: 13.037698, lat: 47.826985 , name: '00077'},
    { lng: 13.038695, lat: 47.810887 , name: '00087'},
    { lng: 13.061158, lat: 47.809553 , name: '00130'},
    { lng: 13.071339, lat: 47.818294 , name: '00145'},
    { lng: 13.061380, lat: 47.807538 , name: '00146'},
    { lng: 13.032904, lat: 47.811214 , name: '00007'},
    { lng: 13.045833, lat: 47.809907 , name: '00020'},
    { lng: 13.048390, lat: 47.809088 , name: '00068'},
    { lng: 13.081235, lat: 47.779508 , name: '00097'},
    { lng: 13.000055, lat: 47.832096 , name: '00105'},
    { lng: 13.080141, lat: 47.825940 , name: '00208'},
    { lng: 13.069202, lat: 47.773367 , name: '00018'},
    { lng: 13.075387, lat: 47.820141 , name: '00118'},
    { lng: 13.042412, lat: 47.806872 , name: '00099'},
    { lng: 13.042399, lat: 47.823850 , name: '00119'},
    { lng: 13.015412, lat: 47.801871 , name: '00129'},
    { lng: 13.068106, lat: 47.813082 , name: '00022'},
    { lng: 13.036916, lat: 47.797506 , name: '00023'},
    { lng: 13.028929, lat: 47.792700 , name: '00056'},
    { lng: 13.066917, lat: 47.797708 , name: '00096'},
    { lng: 13.044091, lat: 47.822590 , name: '00124'},
    { lng: 13.040597, lat: 47.816893 , name: '00132'},
    { lng: 13.006130, lat: 47.828397 , name: '00052'},
    { lng: 13.018647, lat: 47.801740 , name: '00062'},
    { lng: 13.029958, lat: 47.815226 , name: '00066'},
    { lng: 13.075622, lat: 47.813611 , name: '00102'},
    { lng: 13.029445, lat: 47.794009 , name: '00127'},
    { lng: 13.070129, lat: 47.816329 , name: '00113'},
    { lng: 13.053687, lat: 47.815341 , name: '00054'},
    { lng: 13.006117, lat: 47.811653 , name: '00072'},
    { lng: 13.019145, lat: 47.817553 , name: '00081'},
    { lng: 13.053988, lat: 47.813571 , name: '00128'},
    { lng: 13.040381, lat: 47.814208 , name: '00024'},
    { lng: 13.041601, lat: 47.807462 , name: '00025'},
    { lng: 13.051942, lat: 47.786262 , name: '00053'},
    { lng: 13.064224, lat: 47.785791 , name: '00088'},
    { lng: 13.081140, lat: 47.779121 , name: '00097'},
    { lng: 13.027033, lat: 47.805722 , name: '00016'},
    { lng: 13.041911, lat: 47.814168 , name: '00019'},
    { lng: 13.065829, lat: 47.809129 , name: '00034'},
    { lng: 13.048295, lat: 47.809271 , name: '00068'},
    { lng: 13.036355, lat: 47.793502 , name: '00090'},
    { lng: 13.067681, lat: 47.812977 , name: '00022'},
    { lng: 13.038654, lat: 47.807519 , name: '00029'},
    { lng: 13.042550, lat: 47.806169 , name: '00031'},
    { lng: 13.038936, lat: 47.811041 , name: '00087'},
    { lng: 13.026764, lat: 47.807638 , name: '00155'},
    { lng: 13.015811, lat: 47.821123 , name: '00033'},
    { lng: 13.021351, lat: 47.814051 , name: '00041'},
    { lng: 13.052788, lat: 47.820575 , name: '00073'},
    { lng: 13.047217, lat: 47.790502 , name: '00085'},
    { lng: 13.080744, lat: 47.783122 , name: '00148'},
    { lng: 12.991796, lat: 47.792288 , name: '00201'},
    { lng: 13.044486, lat: 47.800671 , name: '00001'},
    { lng: 13.042584, lat: 47.815373 , name: '00069'},
    { lng: 13.071321, lat: 47.813456 , name: '00070'},
    { lng: 13.050041, lat: 47.789992 , name: '00117'},
    { lng: 13.061128, lat: 47.807453 , name: '00146'},
    { lng: 13.056663, lat: 47.828929 , name: '00202'},
    { lng: 13.069132, lat: 47.773705 , name: '00018'},
    { lng: 13.081041, lat: 47.779364 , name: '00097'},
    { lng: 13.039106, lat: 47.812991 , name: '00143'},
    { lng: 12.992066, lat: 47.792352 , name: '00201'},
    { lng: 13.032364, lat: 47.797489 , name: '00035'},
    { lng: 13.054861, lat: 47.808847 , name: '00040'},
    { lng: 13.065861, lat: 47.789106 , name: '00049'},
    { lng: 13.054394, lat: 47.824248 , name: '00046'},
    { lng: 13.067900, lat: 47.778250 , name: '00051'},
    { lng: 13.055061, lat: 47.799101 , name: '00044'},
    { lng: 13.076257, lat: 47.813551 , name: '00102'},
    { lng: 13.066066, lat: 47.809164 , name: '00034'},
    { lng: 13.036105, lat: 47.793623 , name: '00090'},
    { lng: 13.051171, lat: 47.805271 , name: '00126'},
    { lng: 13.072892, lat: 47.792926 , name: '00112'},
    { lng: 13.042369, lat: 47.823986 , name: '00119'},
    { lng: 13.053617, lat: 47.796892 , name: '00006'},
    { lng: 13.043182, lat: 47.810818 , name: '00015'},
    { lng: 13.045683, lat: 47.810131 , name: '00020'},
    { lng: 13.067930, lat: 47.813228 , name: '00022'},
    { lng: 13.055915, lat: 47.806888 , name: '00042'},
    { lng: 13.042394, lat: 47.815278 , name: '00069'},
    { lng: 13.053516, lat: 47.799857 , name: '00141'},
    { lng: 13.071627, lat: 47.818391 , name: '00145'},
    { lng: 13.052389, lat: 47.792687 , name: '00065'},
    { lng: 13.022238, lat: 47.797168 , name: '00078'},
    { lng: 13.055214, lat: 47.808747 , name: '00040'},
    { lng: 13.054256, lat: 47.824363 , name: '00046'},
    { lng: 13.022241, lat: 47.812892 , name: '00061'},
    { lng: 13.026007, lat: 47.784927 , name: '00071'},
    { lng: 13.029710, lat: 47.811315 , name: '00057'},
    { lng: 13.018854, lat: 47.801983 , name: '00062'},
    { lng: 13.060258, lat: 47.809454 , name: '00064'},
    { lng: 13.054742, lat: 47.825525 , name: '00204'},
    { lng: 13.026490, lat: 47.811471 , name: '00009'},
    { lng: 13.069402, lat: 47.773579 , name: '00018'},
    { lng: 13.037491, lat: 47.827101 , name: '00077'},
    { lng: 13.055472, lat: 47.806776 , name: '00042'},
    { lng: 13.062964, lat: 47.791327 , name: '00048'},
    { lng: 13.053600, lat: 47.815777 , name: '00054'},
    { lng: 13.039107, lat: 47.806462 , name: '00138'},
    { lng: 13.046039, lat: 47.800971 , name: '00001'},
    { lng: 13.026812, lat: 47.805691 , name: '00016'},
    { lng: 13.056511, lat: 47.827139 , name: '00060'},
    { lng: 13.069491, lat: 47.815092 , name: '00067'},
    { lng: 13.028895, lat: 47.802697 , name: '00021'},
    { lng: 13.055321, lat: 47.808911 , name: '00040'},
    { lng: 13.071193, lat: 47.813313 , name: '00070'},
    { lng: 13.046259, lat: 47.807235 , name: '00100'},
    { lng: 13.069983, lat: 47.816254 , name: '00113'},
    { lng: 13.053330, lat: 47.795883 , name: '00149'},
    { lng: 13.026399, lat: 47.811400 , name: '00009'},
    { lng: 13.040650, lat: 47.814400 , name: '00024'},
    { lng: 13.018681, lat: 47.801856 , name: '00062'},
    { lng: 13.018121, lat: 47.819127 , name: '00076'},
    { lng: 13.000289, lat: 47.832153 , name: '00105'},
    { lng: 13.041887, lat: 47.801067 , name: '00014'},
    { lng: 13.040627, lat: 47.814113 , name: '00024'},
    { lng: 13.004028, lat: 47.829194 , name: '00137'},
    { lng: 13.013534, lat: 47.823483 , name: '00147'},
    { lng: 13.052160, lat: 47.792737 , name: '00065'},
    { lng: 13.033080, lat: 47.806038 , name: '00144'},
    { lng: 13.040932, lat: 47.799300 , name: '00012'},
    { lng: 13.027395, lat: 47.805685 , name: '00016'},
    { lng: 13.056603, lat: 47.826899 , name: '00060'},
    { lng: 13.074338, lat: 47.790887 , name: '00091'},
    { lng: 13.040686, lat: 47.799396 , name: '00012'},
    { lng: 13.032832, lat: 47.809144 , name: '00036'},
    { lng: 13.068205, lat: 47.778146 , name: '00051'},
    { lng: 13.019793, lat: 47.804683 , name: '00092'},
    { lng: 13.047038, lat: 47.824810 , name: '00098'},
    { lng: 12.994767, lat: 47.793230 , name: '00156'},
    { lng: 13.065645, lat: 47.809069 , name: '00034'},
    { lng: 13.029211, lat: 47.792805 , name: '00056'},
    { lng: 13.032417, lat: 47.797489 , name: '00035'},
    { lng: 13.068411, lat: 47.778321 , name: '00051'},
    { lng: 13.021517, lat: 47.820347 , name: '00075'},
    { lng: 13.007719, lat: 47.798306 , name: '00120'},
    { lng: 13.054215, lat: 47.813490 , name: '00128'},
    { lng: 13.031706, lat: 47.800811 , name: '00080'},
    { lng: 13.074089, lat: 47.791086 , name: '00091'},
    { lng: 13.067250, lat: 47.797589 , name: '00096'},
    { lng: 13.069237, lat: 47.773370 , name: '00018'},
    { lng: 13.033092, lat: 47.811396 , name: '00007'},
    { lng: 13.039571, lat: 47.805335 , name: '00030'},
    { lng: 13.067418, lat: 47.781876 , name: '00050'},
    { lng: 13.019238, lat: 47.817683 , name: '00081'},
    { lng: 13.054254, lat: 47.825219 , name: '00204'},
    { lng: 13.032218, lat: 47.797590 , name: '00035'},
    { lng: 13.029586, lat: 47.811391 , name: '00057'},
    { lng: 13.018448, lat: 47.819082 , name: '00076'},
    { lng: 13.042108, lat: 47.806839 , name: '00099'},
    { lng: 13.056998, lat: 47.829115 , name: '00202'},
    { lng: 13.074363, lat: 47.791049 , name: '00091'},
    { lng: 13.046253, lat: 47.807113 , name: '00100'},
    { lng: 13.030589, lat: 47.797765 , name: '00017'},
    { lng: 13.045669, lat: 47.810097 , name: '00020'},
    { lng: 13.039430, lat: 47.805531 , name: '00030'},
    { lng: 13.066483, lat: 47.786195 , name: '00038'},
    { lng: 13.048070, lat: 47.809197 , name: '00068'},
    { lng: 13.047015, lat: 47.790404 , name: '00085'},
    { lng: 13.028514, lat: 47.791640 , name: '00116'},
    { lng: 13.051098, lat: 47.805118 , name: '00126'},
    { lng: 13.080140, lat: 47.786350 , name: '00152'},
    { lng: 13.043756, lat: 47.804515 , name: '00003'},
    { lng: 13.046073, lat: 47.810237 , name: '00020'},
    { lng: 13.029016, lat: 47.793016 , name: '00056'},
    { lng: 13.033754, lat: 47.806696 , name: '00079'},
    { lng: 13.081065, lat: 47.782993 , name: '00148'},
    { lng: 12.992296, lat: 47.792083 , name: '00201'},
    { lng: 13.067210, lat: 47.781679 , name: '00050'},
    { lng: 13.039141, lat: 47.810897 , name: '00087'},
    { lng: 13.022561, lat: 47.828252 , name: '00123'},
    { lng: 13.056707, lat: 47.829244 , name: '00202'},
];