import React from "react";
import styles from "./Routing.module.css";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

type Props = {
  id: "",
  properties: {},
}

export class ReservationChart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  twoDP(val) {
    return Math.round(val * 100) / 100
  }

  formatDuration(val) {
    if (val >= 60) {
      let minutes = Math.floor(val / 60)
      let seconds = Math.floor(val)%60
      return minutes + 'm' + seconds + 's'
    }
    return val.toFixed(0) + 's'
  }

  render() {
    let startTimeUnixNano = this.props.properties.reservation.startTimeUnixNano
    let bucketWidthSeconds = this.props.properties.reservation.bucketWidthNanoseconds / 1e9
    let buckets = this.props.properties.reservation.bucketsList.slice()

    let minTravelTime = 1e6
    for (let i = 0; i < buckets.length-1; i++) {
      if (buckets[i].travelTimeSeconds < minTravelTime && buckets[i].reservations >= 0) {
        minTravelTime = buckets[i].travelTimeSeconds
      }
    }

    let firstZeroIdx = 0
    for (firstZeroIdx = 0; firstZeroIdx < buckets.length; firstZeroIdx++) {
      if (buckets[firstZeroIdx].reservations === 0) {
        continue
      }
      if (buckets[firstZeroIdx].travelTimeSeconds > minTravelTime * 1.05) {
        break
      }
    }
    let lastZeroIdx = 0
    for (lastZeroIdx = buckets.length - 1; lastZeroIdx >= 0; lastZeroIdx--) {
      if (buckets[lastZeroIdx].reservations === 0) {
        continue
      }
      if (buckets[lastZeroIdx].travelTimeSeconds > minTravelTime * 1.05) {
        break
      }
    }

    let yData = []
    let yData2 = []
    let xData = []
    if (lastZeroIdx < buckets.length-1) {
      lastZeroIdx++
    }
    for (let i = firstZeroIdx; i <= lastZeroIdx; i++) {
      yData.push(buckets[i].reservations)
      yData2.push(buckets[i].travelTimeSeconds)
      xData.push(this.formatDuration(i * bucketWidthSeconds))
    }

    if (buckets.length === 0) {
      return
    }

    const options = {
      title: {
        text: 'Reservations'
      },
      tooltip: {
        formatter: function () {
          return this.y
        }
      },
      series: [
        {
          name: 'reservations',
          data: yData,
          color: 'orange',
          yAxis: 0
        },
        {
          name: 'travel time',
          data: yData2,
          color: 'green',
          yAxis: 1
        },
      ],
      chart: {
        height: '50%',
        type: 'column'
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      xAxis: {
        title: {
          text: null
        },
        labels: {
          enabled: true,
        },
        categories: xData,
      },
      yAxis: [{
        title: {
          text: null
        },
        labels: {
          format: '{value} cars'
        }
      },{
        title: {
          text: null
        },
        labels: {
          format: '{value} s'
        },
        opposite: true
      }]
    }

    let properties = {
      'id': this.props.id,
    }

    return (
      <div className={styles.chartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
        <Divider light className={styles.divider}/>
        <div className={styles.infoRow}>
          {Object.keys(properties).map(function (key) {
            return (
              <div className={styles.infoBox}>
                <Typography variant={'subtitle1'} color="primary">{key}</Typography>
                <Typography
                  variant={'subtitle1'}>{properties[key]}</Typography>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}