import React from "react";
import styles from "./NearestNeighbour.module.css";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {HistoricTurnCostHighchart} from "./HistoricTurnCostHighchart";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  history: {},
  flow: {},
  signalHistory: {},
  current: null,
  longTerm: null,
  totalMeasurements: 0,
  lastChanged: "",
  closeCallback: null,
}

type State = {
  useFlow: boolean
}

export class HistoricTurnCostChart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      useFlow: false
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onToggleChange = () => {
    let newState = !this.state.useFlow
    this.setState({
      useFlow: newState
    })
  }

  twoDP(val) {
    return Math.round(val * 100) / 100
  }

  render() {
    let historicPattern = this.props.history.historic_pattern_in_seconds
    let signalPattern = []
    let signalRecent = []
    if (this.props.signalHistory) {
      signalPattern = this.props.signalHistory.historic_pattern_in_seconds
      signalRecent = this.props.signalHistory.recent_measurements_in_seconds
    }
    let recentMeasurements = this.props.history.recent_measurements_in_seconds
    let slotDuration = this.props.history.time_slot_duration_seconds
    let recentTimeSlotIndex = this.props.history.recent_time_slot_index
    let unit = 's'
    if (this.state.useFlow) {
      historicPattern = this.props.flow.historic_pattern_in_vehicles_per_hour
      signalPattern = []
      signalRecent = []
      recentMeasurements = this.props.flow.recent_measurements_in_vehicles_per_hour
      slotDuration = this.props.flow.time_slot_duration_seconds
      recentTimeSlotIndex = this.props.flow.recent_time_slot_index
      unit = 'veh/h'
    }

    let highchart = <HistoricTurnCostHighchart
      historicPattern={historicPattern}
      signalHistoricPattern={signalPattern}
      recentMeasurements={recentMeasurements}
      signalRecentMeasurements={signalRecent}
      slotDuration={slotDuration}
      recentTimeSlotIndex={recentTimeSlotIndex}
      unit={unit}
    />

    let histAvg = 0
    for (let i = 0; i < historicPattern.length; i++) {
      histAvg += historicPattern[i]
    }
    histAvg = histAvg / historicPattern.length

    let properties = {
      'Current turn cost': this.twoDP(this.props.current) + 's',
      'Long term turn cost': this.twoDP(this.props.longTerm) + 's',
      'Historic avg': this.twoDP(histAvg) + 's',
      'Measurements': this.props.totalMeasurements,
      'Last changed': this.props.lastChanged,
    }

    return (
      <div className={styles.chartContainer}>
        <IconButton aria-label="close" onClick={this.props.closeCallback}>
          <CloseIcon/>
        </IconButton>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.useFlow}
              onChange={this.onToggleChange}
              color='primary'
              value='dynamic-class-name'
            />
          }
          label='vehicle flow'
          className={styles.materialSlider}
        />
        {highchart}
        <Divider light className={styles.divider}/>
        <div className={styles.infoRow}>
          {Object.keys(properties).map(function (key) {
            return (
              <div className={styles.infoBox}>
                <Typography variant={'subtitle1'} color="primary">{key}</Typography>
                <Typography
                  variant={'subtitle1'}>{properties[key]}</Typography>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}