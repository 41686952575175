
export class LineSelector {
  constructor(map, layer, source, cb) {
    this.map = map
    this.source = source
    this.layer = layer
    this.callback = cb
  }

  getSelectionLayerId = () => {
    return this.source + "selection"
  }

  getSelectionHighlightLayerId = () => {
    return this.source + "selection-highlight"
  }

  getHighlightLayerId = () => {
    return this.source + "highlight"
  }

  activate = () => {
    this.map.addLayer(this.getSelectionHighlightLayer())
    this.map.addLayer(this.getSelectedLayer())
    this.map.addLayer(this.getHighlightLayer())

    this.map.on('mousemove', this.layer, this.onMouseMove)

    // register onClick event on highlight layer to select single feature
    this.map.on('click', this.getSelectionHighlightLayerId(), this.selectFeature)
  }

  deactivate = () => {
    this.map.off('mousemove', this.layer, this.onMouseMove)

    // unregister onClick event on highlight layer to select single feature
    this.map.off('click', this.getSelectionHighlightLayerId(), this.selectFeature)

    this.map.removeLayer(this.getHighlightLayerId())
    this.map.removeLayer(this.getSelectionHighlightLayerId())
    this.map.removeLayer(this.getSelectionLayerId())
  }

  selectFeature = (e) => {
    if (e.features) {
      let f = e.features[0]
      this.map.setFilter(this.getSelectionLayerId(), ['==', 'id', f.properties.id])
      this.callback(f)
    } else {
      console.log('no feature')
      this.map.setFilter(this.getSelectionLayerId(), ['==', 'id', ''])
      this.callback(null)
    }
  }

  onMouseMove = (e) => {
    let f = e.features[0]
    if (f) {
      this.map.setFilter(this.getSelectionHighlightLayerId(), ['==', 'id', f.properties.id])
    }
  }

  getSelectionHighlightLayer () {
    return {
      id: this.getSelectionHighlightLayerId(),
      type: 'line',
      source: this.source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#FF00FF',
        'line-width': 8,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }

  getSelectedLayer () {
    return {
      id: this.getSelectionLayerId(),
      type: 'line',
      source: this.source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#3909ff',
        'line-width': 8,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }

  getHighlightLayer () {
    return {
      id: this.getHighlightLayerId(),
      type: 'line',
      source: this.source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#19ff20',
        'line-width': 3,
        'line-opacity': 0.3,
        'line-offset': {
          property: 'isOneway',
          type: 'categorical',
          stops: [
            [true, 0],
            [false, 5],
          ]
        },
      },
      filter: ['==', 'id', '']
    }
  }
}