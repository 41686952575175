// returns a map from the stateId to callbacks that set and parse the url strings
export function urlParamConversion(routingProviderMap, compareMap, vehicleTypeMap, truckParams, vehicleProps) {
  let conversionMap = {
    origin: {
      setUrl: function (state) {
        return '&origin=' + state.startCoords.lat + ',' + state.startCoords.lng
      },
      parseUrl: function (queryStr, states) {
        states.startCoords = {lat: parseFloat(queryStr.split(',')[0]), lng: parseFloat(queryStr.split(',')[1])}
      }
    },
    destination: {
      setUrl: function (state) {
        return '&destination=' + state.destCoords[0].lat + ',' + state.destCoords[0].lng
      },
      parseUrl: function (queryStr, states) {
        states.newDestCoords = [{lat: parseFloat(queryStr.split(',')[0]), lng: parseFloat(queryStr.split(',')[1])}]
      }
    },
    routingProvider: {
      setUrl: function (state) {
        return '&routingProvider=' + state.providerTarget.text
      },
      parseUrl: function (queryStr, states) {
        // loop over the routingProviderMap to match the correct key
        Object.keys(routingProviderMap).map(function (key) {
          let val = routingProviderMap[key]
          if (compareLower(queryStr, val.text)) {
            states.providerTarget = val
          }
        })
      }
    },
    comparisonTarget: {
      setUrl: function (state) {
        let str = ''
        if (state.doCompare) {
          str += '&comparisonTarget=' + state.comparisonTarget.text
          if (state.comparisonTarget.url !== '') {
            str += '&comparisonUrl=' + state.comparisonTarget.url
          }
        }
        return str
      },
      parseUrl: function (queryStr, states) {
        states.doCompare = true
        // loop over the compareMap to match the correct key
        Object.keys(compareMap).map(function (key) {
          let val = compareMap[key]
          if (compareLower(queryStr, val.text)) {
            states.comparisonTarget = val
            if (states.comparisonTargetUrl) {
              states.comparisonTarget.url = states.comparisonTargetUrl
            }
          }
        })
      }
    },
    comparisonTargetUrl: {
      setUrl: function () {return ''}, // set in `comparisonTarget`
      parseUrl: function (queryStr, states) {
        states.comparisonTargetUrl = queryStr
        if (states.comparisonTarget) {
          states.comparisonTarget.url = queryStr
        }
      }
    },
    vehicleType: {
      setUrl: function (state) {
        return '&vehicleType=' + state.vehicleType.text
      },
      parseUrl: function (queryStr, states) {
        // loop over the vehicleType to match the correct key
        Object.keys(vehicleTypeMap).map(function (key) {
          let val = vehicleTypeMap[key]
          if (compareLower(queryStr, val.text)) {
            states.vehicleType = val
          }
        })
      }
    },
    disableHeuristic: {
      setUrl: function (state) {
        if (state.disableHeuristic) {
          return '&disableHeuristic'
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.disableHeuristic = true
      }
    },
    debug: {
      setUrl: function(state) {
        if (state.debug) {
          return '&debug'
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.debug = true
      }
    },
    preferOutdoorLevels: {
      setUrl: function(state) {
        if (state.preferOutdoorLevels) {
          return '&preferOutdoorLevels'
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.preferOutdoorLevels = true
      }
    },
    heading: {
      setUrl: function (state) {
        if (state.useHeading) {
          return '&heading=' + state.heading
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useHeading = true
        states.heading = queryStr
      }
    },
    destinationHeading: {
      setUrl: function (state) {
        if (state.useDestinationHeading) {
          return '&destinationHeading=' + state.destinationHeading
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useDestinationHeading = true
        states.destinationHeading = queryStr
      }
    },
    destinationStreetName: {
      setUrl: function (state) {
        if (state.useDestinationStreetName) {
          return '&destinationStreetName=' + state.destinationStreetName
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useDestinationStreetName = true
        states.destinationStreetName = queryStr
      }
    },
    timeout: {
      setUrl: function (state) {
        return '&timeout=' + state.timeout
      },
      parseUrl: function (queryStr, states) {
        states.timeout = queryStr
      }
    },
    switchTimeout: {
      setUrl: function (state) {
        if (state.useSwitchTimeout) {
          return '&switchTimeout=' + state.switchTimeout
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useSwitchTimeout = true
        states.switchTimeout = queryStr
      }
    },
    makeChart: {
      setUrl: function(state) {
        if (state.makeChart) {
          return '&makeChart'
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.makeChart = true
      }
    },
    routingLayers: {
      setUrl: function (state) {
        if (state.useRoutingLayers) {
          return '&routingLayers=' + state.routingLayers
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useRoutingLayers = true
        states.routingLayers = queryStr
      }
    },
    featuredEventId: {
      setUrl: function (state) {
        if (state.providerTarget.val === routingProviderMap.eventRouter.val) {
          return '&featuredEventId=' + state.featuredEventId
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.featuredEventId = queryStr
      }
    },
    role: {
      setUrl: function (state) {
        if (state.providerTarget.val === routingProviderMap.eventRouter.val) {
          return '&role=' + state.role
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.role = queryStr
      }
    },
    vehicleTypeEvent: {
      setUrl: function (state) {
        if (state.providerTarget.val === routingProviderMap.eventRouter.val) {
          return '&vehicleTypeEvent=' + state.vehicleTypeEvent
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.vehicleTypeEvent = queryStr
      }
    },
    parkingAreaId: {
      setUrl: function (state) {
        if (state.providerTarget.val === routingProviderMap.eventRouter.val) {
          return '&parkingAreaId=' + state.parkingAreaId
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.parkingAreaId = queryStr
      }
    },
    tripDirection: {
      setUrl: function (state) {
        if (state.providerTarget.val === routingProviderMap.eventRouter.val) {
          return '&tripDirection=' + state.tripDirection
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.tripDirection = queryStr
      }
    },
    originLevel: {
      setUrl: function (state) {
        if (state.useOriginLevel) {
          return '&originLevel=' + state.originLevel
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useOriginLevel = true
        states.originLevel = queryStr
      }
    },
    destinationLevel: {
      setUrl: function (state) {
        if (state.useDestinationLevel) {
          return '&destinationLevel=' + state.destinationLevel
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useDestinationLevel = true
        states.destinationLevel = queryStr
      }
    },
    startTime: {
      setUrl: function (state) {
        if (state.customStartTime) {
          return '&startTime=' + state.startTime
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.customStartTime = true
        states.startTime = queryStr
      }
    },
    avoidCountryName: {
      setUrl: function (state) {
        if (state.useAvoidCountryName) {
          return '&avoidCountryName=' + state.avoidCountryName
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useAvoidCountryName = true
        states.avoidCountryName = queryStr
      }
    },
    stayCountryName: {
      setUrl: function (state) {
        if (state.useStayCountryName) {
          return '&stayCountryName=' + state.stayCountryName
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        states.useStayCountryName = true
        states.stayCountryName = queryStr
      }
    },
  }

  // set the truck parameters
  Object.keys(truckParams).map(function (key) {
    conversionMap[key] = {
      setUrl: function (state) {
        if (state.useParams) {
          return '&' + key + '=' + truckParams[key].value
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        truckParams[key].value = queryStr
        states.truckProps = truckParams
        states.useParams = true
      }
    }
  })

  // set the vehicle properties
  Object.keys(vehicleProps).map(function (key) {
    conversionMap[key] = {
      setUrl: function (state) {
        if (state.useVehicleProps) {
          return '&' + key + '=' + vehicleProps[key].value
        }
        return ''
      },
      parseUrl: function (queryStr, states) {
        vehicleProps[key].value = queryStr
        states.vehicleProps = vehicleProps
        states.useVehicleProps = true
      }
    }
  })

  return conversionMap
}

function compareLower(val, other) {
  return val.toLowerCase() === other.toLowerCase()
}