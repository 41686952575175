//@flow
import React from 'react'
import styles from '../routing/Routing.module.css'
import mapboxgl from 'mapbox-gl'
import Geosuggest from 'react-geosuggest'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Button from '@material-ui/core/Button/Button'
import type {LoginContextType} from '../../LoginContext'
import SkyLight from 'react-skylight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Knob} from 'react-rotary-knob'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {grpc} from '@improbable-eng/grpc-web';
import {
  Coordinate,
  Feature,
  Geometry,
  GetRouteRequest,
  Provider,
  ProviderType,
  SimulationRequest,
  SimulationResponse,
  Point,
  Polygon,
  SimulationSettings
} from '../routing/routing_pb';
import {MyHistogram} from '../routing/Histogram';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {StatsTable} from './StatsTable';
import './Simulation.css';
import TextField from "@material-ui/core/TextField/TextField";
import * as constants from '../../constants'

const RoutingService = require('../routing/routing_service')
const myTransport = grpc.CrossBrowserHttpTransport({withCredentials: true});
grpc.setDefaultTransport(myTransport);

type Props = {
  map: mapboxgl.Map,
  loginCtx: LoginContextType
}

const PinDestName = 'dest'
const SimulationSource = 'simulationSource'
const SimulationLayer = 'simulationLayer'

const geosuggestStyle = {
  'input': {
    'width': '210px',
    'height': '25px'
  },
  'suggests': {
    'position': 'relative',
    'backgroundColor': 'white',
    'zIndex': '99'
  }
}
const emptyFC = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  }
}

const featureToggles = [
  {display: 'Chart', stateId: 'makeChart'},
  {display: 'Use heading', stateId: 'useHeading'},
  {display: 'Event route', stateId: 'eventRoute'},
  {display: 'Colourful dots', stateId: 'colourfulDots'}
]

const chartTypes = {
  journeyDistance: {protoId: 'distanceKm', label: 'journey distance (km)', timeXAxis: false},
  journeyTime: {protoId: 'journeyTimeSeconds', label: 'journey time (s)', timeXAxis: true},
  eta: {protoId: 'etaErrorPercent', label: 'eta error (%)', timeXAxis: false},
  pathChanges: {protoId: 'pathChanges', label: 'path changes per session divided by total requests', timeXAxis: false},
}

const emptySources = {
  features: [],
}

const accelerationRates = [
  {display: "x1", val: 1.0, idx: 0},
  {display: "x2", val: 2.0, idx: 1},
  {display: "x5", val: 5.0, idx: 2},
  {display: "x10", val: 10.0, idx: 3},
  {display: "x20", val: 20.0, idx: 4},
  {display: "x50", val: 50.0, idx: 5},
  {display: "x100", val: 100.0, idx: 6}
]

const colourfulVehicles = [
  'interpolate',
  ['linear'],
  ['get', 'vehicleColourIndex'],
  -1, '#9e0002', // selfish vehicles aren't colour coded
  0, 'cyan',
  60, '#003388',
  120, 'cyan'
]

const nonColourfulVehicles = [
  'match',
  ['get', 'selfish'],
  1, '#9e0002',
  '#003388'
]

/*
*
*/
export class Simulation extends React.Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
      useHeading: false,
      heading: 0,
      dest: '',
      calculationInProgress: false,
      popupMsg: '',
      popupTitle: '',
      eventRoute: false,
      featuredEventId: '',
      role: 'VISITOR',
      vehicleTypeEvent: 'CAR',
      parkingAreaId: '',
      tripDirection: 'ARRIVAL',
      chartType: chartTypes.journeyTime,
      chartData: null,
      comparisonChartData: null,
      makeChart: true,
      useHistogram: false,
      useJourneyTime: true,
      colourfulDots: true,
      nextRefreshIntervalSeconds: 30, // each vehicle will request a new route every 30 seconds
      rateSliderVal: 50,
      rate: 1,
      accelerationRate: accelerationRates[0],
      activeSessions: 0,
      newSessionsRate: 0.0,
      targetNewSessionsRate: 0.0,
      routeRequestRate: 0.0,
      targetRouteRequestRate: 0.0,
      updateRequestRate: 0.0,
      targetUpdateRequestRate: 0.0,
      dotsMenuAnchorEl: null, // the html anchor of the routing options menu (the three dots `⋮`) - it is either anchored to the button, or set to null (hidden)
      loaded: false,
      selfishRatio: 0.0
    }
    this.sources = JSON.parse(JSON.stringify(emptySources))
    this.pinDest = null
    this.errorDialog = React.createRef()
    this.client = null
    this.mapboxDraw = null
    this.instanceId = new Date().valueOf().toString()
  }

  componentDidMount() {
    const map = this.props.map

    this.addRouteLayers()

    map.on('dblclick', this.onMapDoubleClick)

    this.mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        polygon: true,
        trash: true
      },
      className: 'foobar',
    });
    map.addControl(this.mapboxDraw, 'top-right');

    map.on('draw.create', this.onDrawCreate);
    map.on('draw.update', this.onDrawUpdate);
    map.on('draw.delete', this.onDrawDelete);
  }

  componentWillUnmount() {
    const map = this.props.map
    map.off('dblclick', this.onMapDoubleClick)
    this.clearRoute()
    this.removeLayers()
    map.removeControl(this.mapboxDraw)

    // cancel any existing route
    if (this.state.calculationInProgress && this.client) {
      this.client.close()
    }
  }

  setChart = (data, selfishData) => {
    this.setState({
      chartData: data,
      comparisonChartData: selfishData
    })
  }

  // after the map on the web page is loaded, add the layers to mapbox so that we can add the points/polylines to it later when we get the route
  addRouteLayers = () => {
    const map = this.props.map

    // remove the source if it already exists
    let simulationSource = map.getSource(SimulationSource)
    if (simulationSource) {
      map.removeLayer(SimulationLayer)
      map.removeSource(SimulationSource)
    }

    map.addSource(SimulationSource, emptyFC)
    map.addLayer({
      'id': SimulationLayer,
      'type': 'circle',
      'source': SimulationSource,
      'paint': {
        'circle-color': colourfulVehicles,
      },
    });
  }

  onMapDoubleClick = (e) => {
    if (this.pinDest) {
      return
    }

    let coords = e.lngLat
    this.setPin(PinDestName, coords)
  }

  setPin = (pinName, coords) => {
    const map = this.props.map

    if (pinName === PinDestName) {
      if (!this.pinDest) {
        this.pinDest = this.createMarker('ic-marker-destination.png')
        this.pinDest.setLngLat(coords)
        this.pinDest.addTo(map)
        this.pinDest.on('dragend', () => this.setPin(PinDestName, this.pinDest.getLngLat()))
      }
      this.setState({
        dest: coords.lat + ',' + coords.lng,
      })
    }

    if (this.sources.features.length > 0) {
      this.updateRoute()
    }
  }

  createMarker = (iconName) => {
    let el = document.createElement('div')
    el.className = 'marker'
    el.style.backgroundImage = 'url(' + constants.resourcesURL + iconName + ')'
    el.style.width = '27px'
    el.style.height = '33px'
    let marker = new mapboxgl.Marker(el, {
      draggable: true,
      offset: [0, -33 / 2]
    })
    marker.setPopup(new mapboxgl.Popup({offset: 25}))
    return marker
  }

  updateRoute = () => {
    // cancel any existing route
    if (this.state.calculationInProgress && this.client) {
        this.client.close()
    }

    if (this.sources.features.length === 0) {
      return
    }
    if (!this.pinDest) {
      return
    }
    let trg = this.pinDest.getLngLat()

    this.setState({
      calculationInProgress: true,
    })
    this.resetChartData();

    let destination = new Array(1)
    let dst =  new Coordinate()
    dst.setLat(trg.lat)
    dst.setLng(trg.lng)
    destination[0] = dst

    let fc = {
      type: 'FeatureCollection',
      features: []
    }

    let sources = []

    for (let i = 0; i < this.sources.features.length; i++) {
      let geojson = this.sources.features[i]
      let geom = new Geometry()
      switch (geojson.type) {
        case 'Point':
          let point = new Point()
          point.setLngLatList(geojson.geometry.coordinates)
          geom.setPoint(point)
          break
        case 'Polygon':
          let polygon = new Polygon()
          let points = []
          for (let j = 0; j < geojson.geometry.coordinates.length; j++) {
            let p = new Point()
            p.setLngLatList(geojson.geometry.coordinates[j])
            points.push(p)
          }
          polygon.setPointsList(points)
          geom.setPolygon(polygon)
          break
      }

      geom.setType(geojson.geometry.type)

      let feature = new Feature()
      feature.setType(geojson.type)
      feature.setGeometry(geom)

      sources.push(feature)
    }

    let simulationRequest = new SimulationRequest()
    simulationRequest.setSourcesList(sources)

    const getRouteRequest = new GetRouteRequest()
    getRouteRequest.setDestinationsList(destination)
    getRouteRequest.setMakeChart(this.state.makeChart)
    getRouteRequest.setUseHeading(this.state.useHeading)
    getRouteRequest.setHeading(this.state.heading)
    getRouteRequest.setAuthorization(this.props.loginCtx.token.accessToken)

    if (this.state.eventRoute) {
      let provider = new Provider()
      provider.setProviderType(ProviderType.PROVIDER_EVENT)
      getRouteRequest.setProvider(provider)

      getRouteRequest.setFeaturedEventId(this.state.featuredEventId)
      getRouteRequest.setRole(this.state.role)
      getRouteRequest.setVehicleTypeEvent(this.state.vehicleTypeEvent)
      getRouteRequest.setParkingAreaId(this.state.parkingAreaId)
      getRouteRequest.setTripDirection(this.state.tripDirection)
    }

    let settings = new SimulationSettings()
    settings.setRequestRate(this.state.rate)
    settings.setAccelerationRate(this.state.accelerationRate.val)
    settings.setInstanceId(this.instanceId)
    settings.setNextRefreshIntervalSeconds(this.state.nextRefreshIntervalSeconds)
    settings.setSelfishRatio(this.state.selfishRatio)

    simulationRequest.setRequest(getRouteRequest)
    simulationRequest.setSettings(settings)

    const client = grpc.client(RoutingService.RoutingService.Simulate, {
      host: process.env.REACT_APP_BFF_GRPC,
    });
    this.client = client

    // define the callback for the grpc response first, before sending the request
    client.onMessage((message: SimulationResponse) => {
      // console.log('client.onMessage', message.toObject());

      let featureCollection = message.getFeatureCollection()
      let features = featureCollection.getFeaturesList()
      let batchFc = {
        type: 'FeatureCollection',
        features: new Array(features.length)
      }

      for (let i = 0; i < features.length; i++) {
        let feature = features[i]
        let geom = feature.getGeometry()
        let geomType = geom.getCoordinatesCase()

        let coordinates = []
        switch (geomType) {
          case Geometry.CoordinatesCase.POINT:
            coordinates = geom.getPoint().toObject().lngLatList
            break
          case Geometry.CoordinatesCase.LINE_STRING:
            let list = geom.getLineString().toObject().pointsList
            for (let i = 0; i < list.length; i++) {
              coordinates.push(list[i].lngLatList)
            }
            break
          default:
            console.log('unsupported geometry type', geomType)
            return
        }

        let props = feature.getProperties().toObject()
        // if the car is a selfish one, then colour it red - mapbox can only interpret ints so convert bool -> int
        if (props.selfish === true) {
          props.selfish = 1
        }
        batchFc.features[i] = {
          type: feature.getType(),
          properties: props,
          geometry: {
            type: geom.toObject().type,
            coordinates: coordinates,
          },
        }
      }
      fc.features = batchFc.features
      this.setRoute(fc, false)

      let chartData = null
      let selfishChartData = null
      if (featureCollection.getAgentDataList().length > 0) {
        chartData = featureCollection.getAgentDataList()
      }
      if (featureCollection.getSelfishAgentDataList().length > 0) {
        selfishChartData = featureCollection.getSelfishAgentDataList()
      }
      if (chartData || selfishChartData) {
        this.setChart(chartData, selfishChartData)
      }
      if (message.getStats()) {
        let stats = message.getStats()
        this.setState({
          activeSessions: stats.getActiveSessions(),
          newSessionsRate: stats.getNewSessionsRate(),
          targetNewSessionsRate: stats.getTargetNewSessionsRate(),
          routeRequestRate: stats.getRouteRequestRate(),
          targetRouteRequestRate: stats.getTargetRouteRequestRate(),
          updateRequestRate: stats.getUpdateRequestRate(),
          targetUpdateRequestRate: stats.getTargetUpdateRequestRate(),
        })
      }
    });
    client.onEnd((code: grpc.Code, msg: string, trailers: grpc.Metadata) => {
      console.log('client.onEnd', code, msg, trailers);
      this.setState({
        calculationInProgress: false
      })
      if (code !== grpc.Code.OK) {
        this.onError(msg)
      }
      this.client = null
    });
    client.start();
    // send the actual request
    client.send(simulationRequest);
  }

  onError = (error) => {
    console.log(error)
    this.setState({
      popupMsg:   error,
      popupTitle: 'Error getting route'
    })
    if (this.errorDialog.current != null) {
      this.errorDialog.current.show();
    }
  }

  setRoute = (route) => {
    const map = this.props.map
    let source = map.getSource(SimulationSource)
    if (source) {
      source.setData(route)
      if (this.state.colourfulDots) {
        map.setPaintProperty(SimulationLayer, 'circle-color', colourfulVehicles)
      } else {
        map.setPaintProperty(SimulationLayer, 'circle-color', nonColourfulVehicles)
      }
    }
  }

  removeLayers = () => {
    const map = this.props.map

    let simulationSource = map.getSource(SimulationSource)
    if (simulationSource) {
      map.removeLayer(SimulationLayer)
      map.removeSource(SimulationSource)
    }
  }

  clearRoute = () => {
    const map = this.props.map

    this.sources = JSON.parse(JSON.stringify(emptySources))
    if (this.pinDest) {
      this.pinDest.remove()
    }

    if (this.mapboxDraw) {
      this.mapboxDraw.deleteAll()
    }

    this.pinDest = null

    let simulationSource = map.getSource(SimulationSource)
    if (simulationSource) {
      simulationSource.setData(emptyFC.data)
    }

    this.setState({
      start: '',
      dest: ''
    })
    this.resetChartData()
  }

  onSuggestSelect = (suggest, name) => {
    if (suggest) {
      this.setPin(name, suggest.location)
      this.updateRoute()
      this.setState({
        [name]: suggest.label
      })
    }
  }

  resetChartData = () => {
    this.setState({
      chartData: null,
      comparisonChartData: null,
    })
  }

  onRecalculateClick = () => {
    if (this.state.calculationInProgress) {
      this.cancelRoute()
      this.resetChartData('')
    } else {
      this.updateRoute()
    }
  }

  cancelRoute = () => {
    if (this.client) {
      this.client.close()
      this.setState({
        calculationInProgress: false
      })
    }
  }

  onHeadingChange = (val) => {
    this.setState({
      heading: val
    })
  }

  onToggleChange = (stateId) => {
    let newState = !this.state[stateId]
    this.setState({
      [stateId]: newState
    }, () => {
      if (stateId === 'rate') {
        this.updateSimulationSettings()
      }
    })
  }

  onSliderChange = (e, val) => {
    let rateHz = this.sliderToHz(val)
    this.setState({
      rateSliderVal: val,
      rate: rateHz,
    }, this.updateSimulationSettings)
  }

  // val is between 0 and 100
  sliderToHz = (val) => {
    let steps = [0, 0.1, 0.2, 0.5, 1, 2, 5, 10, 50, 100]
    let result = 0
    let dx = 100 / (steps.length-1)

    for (let i = 0; i < steps.length-1; i++) {
      let currY = steps[i]
      let nextY = steps[i+1]
      let currX = i*dx
      let nextX = (i+1)*dx

      if (val <= nextX) {
        result = currY + (nextY - currY) / dx * (val - currX)
        break
      }
    }

    return result
  }

  onSelfishSliderChange = (e, val) => {
    this.setState({
      selfishRatio: val
    }, this.updateSimulationSettings)
  }

  onChartTypeChange = event => {
    let type = {}
    Object.keys(chartTypes).map(function (key) {
      if (event.target.value == chartTypes[key].protoId) { // intentional `==`
        type = chartTypes[key]
      }
    })
    this.setState({
      chartType: type,
    })
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  updateSimulationSettings = () => {
    const simulationSettings = new SimulationSettings()
    simulationSettings.setRequestRate(this.state.rate)
    simulationSettings.setAccelerationRate(this.state.accelerationRate.val)
    simulationSettings.setInstanceId(this.instanceId)
    simulationSettings.setNextRefreshIntervalSeconds(this.state.nextRefreshIntervalSeconds)
    simulationSettings.setSelfishRatio(this.state.selfishRatio)

    grpc.unary(RoutingService.RoutingService.SimulationUpdate, {
      request: simulationSettings,
      host: process.env.REACT_APP_BFF_GRPC,
      onEnd: res => {
        const {status, statusMessage, headers, message, trailers} = res;
        console.log('client.onEnd', status, message, trailers);
        if (status !== grpc.Code.OK) {
          this.onError(message)
        }
      },
    });
  }

  onDotsMenuOpen = (e) => {
    this.setState({
      dotsMenuAnchorEl: e.currentTarget
    })
  }

  onDotsMenuClosed = () => {
    this.setState({
      dotsMenuAnchorEl: null
    })
  }

  onDrawCreate = featureCollection => {
    for (let i = 0; i < featureCollection.features.length; i++) {
      this.sources.features.push(this.convertFeature(featureCollection.features[i]))
    }

    if (this.pinDest) {
      this.updateRoute()
    }
  }

  convertFeature = (geojson) => {
    let coords = []
    switch (geojson.geometry.type) {
      case 'Point':
        coords = [geojson.geometry.coordinates[0], geojson.geometry.coordinates[1]]
        break
      case 'Polygon':
        for (let j = 0; j < geojson.geometry.coordinates[0].length; j++) {
          let geom = geojson.geometry.coordinates[0][j]
          coords.push([geom[0], geom[1]])
        }
        break
    }

    return {
      type: geojson.geometry.type,
      id: geojson.id,
      geometry: {
        coordinates: coords
      }
    }
  }

  onDrawUpdate = featureCollection => {
    if (featureCollection.features.length === 0) {
      return
    }

    for (let i = 0; i < this.sources.features.length; i++) {
      if (this.sources.features[i].id === featureCollection.features[0].id) {
        this.sources.features.splice(i, 1)
        this.sources.features.push(this.convertFeature(featureCollection.features[0]))
        break
      }
    }

    if (this.pinDest) {
      this.updateRoute()
    }
  }

  onDrawDelete = featureCollection => {
    for (let i = 0; i < this.sources.features.length; i++) {
      if (this.sources.features[i].id === featureCollection.features[0].id) {
        this.sources.features.splice(i, 1)
      }
    }

    if (this.sources.features.length === 0) {
      this.cancelRoute()
    } else if (this.pinDest) {
      this.updateRoute()
    }
  }

  onAccelerationRateChange = () => {
    let currentIdx = this.state.accelerationRate.idx
    let newIdx = (currentIdx+1)%accelerationRates.length

    this.setState({
      accelerationRate: accelerationRates[newIdx]
    }, this.updateSimulationSettings)
  }

  render() {
    let recalculateButton = null
    if (this.state.calculationInProgress) {
      recalculateButton = <Button style={{marginRight: '10px'}} className={styles.recalculateButton} variant='contained' onClick={this.onRecalculateClick}>Cancel</Button>
    } else {
      recalculateButton = <Button style={{marginRight: '10px'}} className={styles.recalculateButton} variant='contained' onClick={this.onRecalculateClick}>Recalculate</Button>
    }

    let chart = null
    if (this.state.makeChart && (this.state.chartData || this.state.comparisonChartData)) {
      let data = []
      let selfishData = []
      let label = chartTypes.journeyTime.label
      let objectId = chartTypes.journeyTime.protoId
      let timeXAxis = false
      let currentChartType = this.state.chartType
      Object.keys(chartTypes).map(function (key) {
        if (chartTypes[key].protoId == currentChartType.protoId) { // intentional `==`
          label = chartTypes[key].label
          objectId = chartTypes[key].protoId
          timeXAxis = chartTypes[key].timeXAxis
        }
      })
      if (this.state.chartData) {
        for (let i = 0; i < this.state.chartData.length; i++) {
          data.push(this.state.chartData[i].toObject()[objectId])
        }
      }
      if (this.state.comparisonChartData) {
        for (let i = 0; i < this.state.comparisonChartData.length; i++) {
          selfishData.push(this.state.comparisonChartData[i].toObject()[objectId])
        }
      }
      chart = (<MyHistogram
        data={data}
        comparisonData={selfishData}
        normalized={true}
        simulation={true}
        showStats={true}
        xLabel={label}
        timeXAxis={timeXAxis}
        height={400}
        width={500}
      />)
    }

    let knob = null
    if (this.state.useHeading) {
      knob = (<div>
        <Knob
          style={{display: 'inline-block'}}
          min={0}
          max={360}
          preciseMode={false}
          unlockDistance={5}
          onChange={val => {
            this.onHeadingChange(val);
          }}
          value={this.state.heading}
        />
      </div>)
    }

    let eventRoutingInput = null
    if (this.state.eventRoute) {
      eventRoutingInput = (<div>
        <div className={styles.eventRouting}>
          <div>
            <div className={styles.routingLayersText}>Feature event id:</div>
            <input name='featuredEventId' className={styles.routingLayersInput} type='input'
                   value={this.state.featuredEventId} onChange={this.handleInputChange}/>
          </div>
          <div>
            <div className={styles.routingLayersText}>Role:</div>
            <input name='role' className={styles.routingLayersInput} type='input' value={this.state.role}
                   onChange={this.handleInputChange}/>
          </div>
        </div>
        <div className={styles.eventRouting}>
          <div>
            <div className={styles.routingLayersText}>Vehicle type:</div>
            <input name='vehicleTypeEvent' className={styles.routingLayersInput} type='input'
                   value={this.state.vehicleTypeEvent} onChange={this.handleInputChange}/>
          </div>
          <div>
            <div className={styles.routingLayersText}>Trip direction:</div>
            <input name='tripDirection' className={styles.routingLayersInput} type='input'
                   value={this.state.tripDirection} onChange={this.handleInputChange}/>
          </div>
          <div>
            <div className={styles.routingLayersText}>Parking area id:</div>
            <input name='parkingAreaId' className={styles.routingLayersInput} type='input'
                   value={this.state.parkingAreaId} onChange={this.handleInputChange}/>
          </div>
        </div>
      </div>)
    }

    let sliderToHz = this.sliderToHz
    // a callback to determine the text value to show above the slider
    function ValueLabelComponent(props) {
      const { children, open, value } = props;

      const popperRef = React.useRef(null);
      React.useEffect(() => {
        if (popperRef.current) {
          popperRef.current.update();
        }
      });

      // convert slider value of 0-100% to a Hz
      let hz = sliderToHz(value)
      let title = ''
      if (hz < 1) {
        title = hz.toFixed(1) + ' Hz'
      } else if (hz < 10) {
        title = hz.toFixed(0) + ' Hz'
      } else {
        title = parseFloat(hz.toPrecision(1)).toFixed(0) + ' Hz'
      }

      return (
        <Tooltip
          PopperProps={{
            popperRef,
          }}
          open={open}
          enterTouchDelay={0}
          placement="top"
          title={title}
        >
          {children}
        </Tooltip>
      );
    }

    ValueLabelComponent.propTypes = {
      children: PropTypes.element.isRequired,
      open: PropTypes.bool.isRequired,
      value: PropTypes.number.isRequired,
    };

    let statsTable = null
    if (this.state.activeSessions !== 0) {
      let tableData = [
        {title: 'Active Sessions', val: this.state.activeSessions, unit: '', parse: function (val) {return val}},
        {title: 'New Sessions', val: this.state.newSessionsRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
        {title: 'Target', val: this.state.targetNewSessionsRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
        {title: 'Request rate', val: this.state.routeRequestRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
        {title: 'Target', val: this.state.targetRouteRequestRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
        {title: 'Update rate', val: this.state.updateRequestRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
        {title: 'Target', val: this.state.targetUpdateRequestRate, unit: 'Hz', parse: function (val) {return val.toFixed(1)}},
      ]
      statsTable = (
        <StatsTable tableData={tableData}/>
      )
    }

    return (
      <div className={styles.body}>
        <SkyLight hideOnOverlayClicked
                  dialogStyles={{width: '30%', height: '10px', marginTop: '-300px', marginLeft: '0%'}}
                  ref={this.errorDialog} title={this.state.popupTitle}>
          <div style={{'white-space': 'pre-wrap'}}>
            {this.state.popupMsg}
          </div>
        </SkyLight>
        <Card className={styles.inputCard}>
          <CardContent className={styles.inputCardContent}>
            <div className={styles.inputRow}>
              <Geosuggest
                placeholder='Start typing!'
                initialValue={this.state.dest}
                onSuggestSelect={(suggest) => this.onSuggestSelect(suggest, PinDestName)}
                style={geosuggestStyle}
                className={styles.geosuggest}
              />
              {recalculateButton}
              <Button className={styles.recalculateButton} variant='contained' onClick={this.clearRoute}>Clear</Button>
              <IconButton
                aria-owns={this.state.dotsMenuAnchorEl ? 'features-menu' : undefined}
                aria-label='More'
                aria-haspopup='true'
                onClick={this.onDotsMenuOpen}
                className={styles.dotsMenuButton}
              >
                <MoreVertIcon/>
              </IconButton>
              <Menu id='features-menu'
                    anchorEl={this.state.dotsMenuAnchorEl}
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                    open={Boolean(this.state.dotsMenuAnchorEl)}
                    onClose={this.onDotsMenuClosed}
              >
                {featureToggles.map(option => (
                  <MenuItem className={styles.dotsMenuItem} onClick={() => this.onToggleChange(option.stateId)}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state[option.stateId]}
                          onChange={() => this.onToggleChange(option.stateId)}
                          color='primary'
                          value='dynamic-class-name'
                        />
                      }
                      label={option.display}
                      className={styles.materialSlider}
                    />
                  </MenuItem>
                ))}
                <MenuItem className={styles.dotsMenuItem} onClick={() => this.onAccelerationRateChange}>
                  <Button className={styles.accelerationRateButton} variant="outlined" color="primary" onClick={this.onAccelerationRateChange}>{this.state.accelerationRate.display}</Button>
                  Fast forward
                </MenuItem>
              </Menu>
            </div>

            <div className={styles.toggleRow}>
              <Typography style={{marginRight: '10px'}}> Rate: </Typography>
              <Slider
                ValueLabelComponent={ValueLabelComponent}
                aria-label="custom thumb label"
                defaultValue={this.state.rateSliderVal}
                onChangeCommitted={this.onSliderChange}
                style={{marginRight: '10px'}}
              />
              <div className={styles.targetUrlRow}>
                <FormControl>
                  <NativeSelect
                    defaultValue={chartTypes.journeyTime}
                    value={this.state.chartType.protoId}
                    onChange={this.onChartTypeChange}
                    input={<Input name='name' id='uncontrolled-native'/>}
                  >
                    {
                      Object.keys(chartTypes).map(function (key) {
                        return (
                          <option
                            value={chartTypes[key].protoId}>{chartTypes[key].label}</option>
                        )
                      })
                    }
                  </NativeSelect>
                </FormControl>
              </div>
              {knob}
            </div>

            <div className={styles.toggleRow}>
              <Typography style={{marginRight: '10px'}}>Nunav</Typography>
              <Slider
                defaultValue={0}
                onChangeCommitted={this.onSelfishSliderChange}
                style={{marginRight: '10px', width: '40%', thumbColorPrimary: 'black'}}
                min={0} max={1} step={0.1}
              />
              <Typography style={{marginRight: '10px'}}>Selfish</Typography>
              <TextField
                style={{'width':'30%', 'paddingRight':'20px'}}
                value={this.state.nextRefreshIntervalSeconds}
                type={'number'}
                label='next refresh interval (s)'
                name='nextRefreshIntervalSeconds'
                onChange={this.handleInputChange}/>
            </div>
            {statsTable}
            {eventRoutingInput}
            {chart}
          </CardContent>
        </Card>
      </div>
    )
  }
}
