import React from 'react'
import { SelectedEdgeContext } from '../../components/traffic/EdgeCard'
import LoginContext from '../../LoginContext'
import type { LoginContextType } from '../../LoginContext'
import TopMenu from '../../components/TopMenu'
import MapContainer from '../MapContainer'

type Props = {}

type State = {}

export function withTrafficLayerData (TrafficLayerComponent) {

  return class extends React.Component<Props, State> {

    render () {
      return (
        <LoginContext.Consumer>
          {(loginContext: LoginContextType) => {
            if (!loginContext.isLoggedIn()) {
              return
            }
            return (
              <SelectedEdgeContext.Consumer>
                {
                  (context) => {
                    return (
                      <TrafficLayerComponent {...this.props} selectedEdgeContext={context} loginCtx={loginContext}/>
                    )
                  }
                }
              </SelectedEdgeContext.Consumer>
            )
          }}
        </LoginContext.Consumer>
      )
    }
  }

}
