import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

type Props = {
  historicPattern: [],
  signalHistoricPattern: [],
  recentMeasurements: [],
  signalRecentMeasurements: [],
  slotDuration: 0,
  recentTimeSlotIndex: 0,
  unit: ''
}

type State = {
  useFlow: boolean
}

let dayStrings = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export class HistoricTurnCostHighchart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      useFlow: false
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onToggleChange = () => {
    let newState = !this.state.useFlow
    this.setState({
      useFlow: newState
    })
  }

  getFormattedHistoricData = (historicPattern, recentMeasurements) => {
    let now = new Date()
    let day = now.getUTCDay()
    let hour = now.getUTCHours()
    let minute = now.getUTCMinutes()
    let historicData = historicPattern.slice()
    let historicSlot = Math.floor((day * 24 * 3600 + hour * 3600 + minute * 60) / this.props.slotDuration)
    let paddedRecentData = []
    if (historicSlot < historicData.length) {
      for (let i = 0; i < this.props.historicPattern.length; i++) {
        paddedRecentData.push(null)
      }
      for (let recentIndex = 0; recentIndex < this.props.recentMeasurements.length; recentIndex++) {
        let historicPatternIndex = recentIndex + this.props.recentTimeSlotIndex - this.props.recentMeasurements.length + 1
        if (historicPatternIndex < 0) {
          historicPatternIndex += this.props.historicPattern.length
        }
        paddedRecentData[historicPatternIndex] = recentMeasurements[recentIndex]
      }

      historicData[historicSlot] = {
        y: historicData[historicSlot],
        color: '#fb00aa'
      }
    }

    let defaultXAxisStartIdx = this.props.recentTimeSlotIndex - this.props.recentMeasurements.length + 1
    let defaultXAxisEndIdx = historicSlot
    if (defaultXAxisStartIdx < 0) {
      defaultXAxisStartIdx = 0
    }

    return {
      historicData: historicData,
      paddedRecentData: paddedRecentData,
      defaultXAxisStartIdx: defaultXAxisStartIdx,
      defaultXAxisEndIdx: defaultXAxisEndIdx
    }
  }

  render() {
    if (!this.props.historicPattern) {
      return (<div>empty data</div>)
    }

    let formattedData = this.getFormattedHistoricData(this.props.historicPattern, this.props.recentMeasurements)
    let signalFormattedData = []
    let dataIsTrailAndSignal = this.props.signalHistoricPattern && this.props.signalHistoricPattern.length > 0
    if (dataIsTrailAndSignal) {
      signalFormattedData = this.getFormattedHistoricData(this.props.signalHistoricPattern, this.props.signalRecentMeasurements)
    }

    if (formattedData.paddedRecentData.length > 0) {
      let indexOfOneDay = 24 * 3600 / this.props.slotDuration
      let dayMarkers = []
      for (let day = 1; day < 7; day++) {
        dayMarkers.push({
          color: 'red', // Color value
          value: day * indexOfOneDay, // Value of where the line will appear
          width: 2 // Width of the line
        })
      }

      let slotDuration = this.props.slotDuration
      let unit = this.props.unit
      let historicName = 'historic'
      let currentName = 'current'
      if (dataIsTrailAndSignal) {
        historicName = 'trails'
        currentName = 'current trails'
      }
      let series = [
        {
          name: historicName,
          type: 'column',
          data: formattedData.historicData,
          color: '#ffb700'
        },
        {
          name: currentName,
          type: 'column',
          data: formattedData.paddedRecentData,
          color: '#64c964'
        }
      ]
      if (dataIsTrailAndSignal) {
        let sum = []
        let sumRecent = []
        for (let i = 0; i < formattedData.historicData.length; i++) {
          sum.push(formattedData.historicData[i] + signalFormattedData.historicData[i])
          let paddedValueOrNull = null
          if (formattedData.paddedRecentData[i] !== null) {
            paddedValueOrNull = formattedData.paddedRecentData[i] + signalFormattedData.paddedRecentData[i]
          }
          sumRecent.push(paddedValueOrNull)
        }
        series.splice(1, 0, {
          name: 'signal',
          type: 'column',
          data: signalFormattedData.historicData,
          color: '#ff8000'
        })
        series.push({
          name: 'current signal',
          type: 'column',
          data: signalFormattedData.paddedRecentData,
          color: '#558155'
        }, {
          name: 'trails + signal',
          type: 'scatter',
          data: sum,
          marker: {
            lineWidth: 2,
            lineColor: '#b4562e',
            fillColor: 'white'
          }
        }, {
          name: 'current trails + signal',
          type: 'scatter',
          data: sumRecent,
          marker: {
            lineWidth: 2,
            lineColor: '#316918',
            fillColor: 'white'
          }
        })
      }

      const options = {
        title: {
          text: 'Historic pattern'
        },
        tooltip: {
          formatter: function () {
            const day = this.x / (24 * 3600 / slotDuration)
            let dayString = dayStrings[Math.floor(day)]

            let hour = Math.floor(this.x / (3600 / slotDuration) - Math.floor(day) * 24)
            let minutes = Math.floor(this.x / (60 / slotDuration) - Math.floor(day) * 24 * 60 - Math.floor(hour) * 60)

            return dayString + ', ' + String(hour).padStart(2, '0') + '.' + String(minutes).padStart(2, '0') + ' : <b>' + this.y + ' ' + unit + '</b>'
          }
        },
        series: series,
        chart: {
          height: '50%',
          type: 'column',
          zoomType: 'x',
          events: {
            load: function () {
              let xAxis = this.xAxis[0]
              xAxis.setExtremes(formattedData.defaultXAxisStartIdx, formattedData.defaultXAxisEndIdx)
              this.showResetZoom()
            }
          }
        },
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            groupPadding: 0,
            pointPadding: 0,
          }
        },
        xAxis: {
          title: {
            text: null
          },
          plotLines: dayMarkers,
          labels: {
            enabled: false,
          },
          tickInterval: indexOfOneDay,
        },
        yAxis: {
          title: {
            text: null
          },
          labels: {
            format: '{value} ' + this.props.unit
          }
        }
      }

      return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      )
    }
  }
}