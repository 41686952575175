//@flow
import React from 'react'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import styles from './NearestNeighbour.module.css'
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import Button from '@material-ui/core/Button/Button'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Menu from '@material-ui/core/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import {HistoricChart} from "./HistoricChart";
import {PropertyTable} from "./PropertyTable";
import {HistoricTurnCostChart} from "./HistoricTurnCostChart";

type Props = {
  map: mapboxgl.Map,
}

type State = {
  selectedVertices: {},
  selectedEdges: {},
  headingGeom: {},
  time: 0,
  vertexTab: 0,
  edgeTab: 0,
  canvas: {},
  isCursorOverPoint: boolean,
  isCursorOverEdge: boolean,
}

const NearestNeighbourPointSource = "nearestNeighbourPointSource"
const NearestNeighbourPointLayer = "nearestNeighbourPointLayer"
const NearestNeighbourLineSource = "nearestNeighbourLineSource"
const NearestNeighbourLineLayer = "nearestNeighbourLineLayer"
const NearestNeighbourHeadingSource = "nearestNeighbourHeadingSource"
const NearestNeighbourHeadingLayer = "nearestNeighbourHeadingLayer"
const NearestNeighbourTrgSource = "nearestNeighbourTrgSource"
const NearestNeighbourTrgLayer = "nearestNeighbourTrgLayer"
const animationFrames = 100 // sets the animation speed, lower is faster
const animationInterval = 0  // ms between frames, lower is smoother
const emptyFC = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: []
  }
}

/*
*
*/
export class NearestNeighbour extends React.Component<Props, State> {

  constructor(props) {
    super(props)
    this.state = {
      selectedVertices: null,
      selectedEdges: null,
      headingGeom: null,
      vertexTab: 0,
      edgeTab: 0,
      showKalman: false,
      kalmanAnchorEl: null,
      showTurnHistory: false,
      turnHistoryAnchorEl: null
    }
    this.pin = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    }
    this.time = 0
    this.prevTime = 0
    this.isAnimationRunning = false
  }

  componentDidMount() {
    const map = this.props.map

    this.addPointLayer()
    this.addNearestNeighbourLayer()
    map.on('click', this.onMapClick)
    map.on('click', NearestNeighbourTrgLayer, this.onEdgeClick)

    let canvas = map.getCanvasContainer();
    this.canvas = canvas;

    // When the cursor enters a feature in the point layer, prepare for dragging.
    map.on('mouseenter', NearestNeighbourPointLayer, this.onPointEnter);
    map.on('mouseleave', NearestNeighbourPointLayer, this.onPointLeave);

    // handle hover over an edge
    map.on('mouseenter', NearestNeighbourTrgLayer, this.onEdgeEnter);
    map.on('mouseleave', NearestNeighbourTrgLayer, this.onEdgeLeave);

    map.on('mousedown', NearestNeighbourPointLayer, this.onPointMouseDown);
  }

  componentWillUnmount() {
    const map = this.props.map
    map.off('click', this.onMapClick)

    map.off('mouseenter', NearestNeighbourPointLayer, this.onPointEnter);
    map.off('mouseleave', NearestNeighbourPointLayer, this.onPointLeave);

    map.off('mouseenter', NearestNeighbourTrgLayer, this.onEdgeEnter);
    map.off('mouseleave', NearestNeighbourTrgLayer, this.onEdgeLeave);

    map.removeLayer(NearestNeighbourPointLayer)
    map.removeSource(NearestNeighbourPointSource)
    map.removeLayer(NearestNeighbourLineLayer)
    map.removeSource(NearestNeighbourLineSource)
    map.removeLayer(NearestNeighbourHeadingLayer)
    map.removeSource(NearestNeighbourHeadingSource)
    map.removeLayer(NearestNeighbourTrgLayer)
    map.removeSource(NearestNeighbourTrgSource)
  }

  onPointEnter = () => {
    const map = this.props.map
    map.setPaintProperty(NearestNeighbourPointLayer, 'circle-color', '#3bb2d0');
    this.canvas.style.cursor = 'move';
    this.setState({
      isCursorOverPoint: true,
      isCursorOverEdge: false
    });
    map.dragPan.disable();
  }

  onPointLeave = () => {
    const map = this.props.map
    map.setPaintProperty(NearestNeighbourPointLayer, 'circle-color', '#3887be');
    this.canvas.style.cursor = '';
    this.setState({
      isCursorOverPoint: false
    });
    map.dragPan.enable();
  }

  onPointMouseDown = () => {
    // Set a cursor indicator
    this.canvas.style.cursor = 'grab';

    this.lastUpdate = new Date()

    // Mouse events
    this.props.map.on('mousemove', this.onMove);
    this.props.map.once('mouseup', this.onUp);
  }

  onEdgeEnter = () => {
    const map = this.props.map
    map.getCanvas().style.cursor = 'pointer';
    this.setState({
      isCursorOverEdge: true
    });
  }

  onEdgeLeave = () => {
    const map = this.props.map
    map.getCanvas().style.cursor = '';
    this.setState({
      isCursorOverEdge: false
    });
  }

  onMove = (e) => {
    const {
      map
    } = this.props;

    let coords = e.lngLat;
    // Set a UI indicator for dragging.
    this.canvas.style.cursor = 'grabbing';
    this.pin.geometry.coordinates = [coords.lng, coords.lat];
    map.getSource(NearestNeighbourPointSource).setData(this.pin);
    map.getSource(NearestNeighbourTrgSource).setData(emptyFC.data);
    if (new Date() - this.lastUpdate > 50) {
      this.lastUpdate = new Date()
      this.nearestNeighbour(coords, true)
    }
  }

  onUp = (e) => {
    const {
      map
    } = this.props;

    if (this.canvas.style.cursor === 'grabbing') {
      this.nearestNeighbour(e.lngLat, false)
    }

    this.canvas.style.cursor = '';

    // Unbind mouse events
    map.off('mousemove', this.onMove);
    map.off('mouseup', this.onUp)
  }

  onMapClick = (e) => {
    const {
      map
    } = this.props;

    // get the nearest neighbour at the click location, unless we are clicking on an edge
    if (!this.state.isCursorOverEdge) {
      let coords = e.lngLat;
      this.pin = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [coords.lng, coords.lat]
        }
      }
      map.getSource(NearestNeighbourPointSource).setData(this.pin);

      this.nearestNeighbour(coords)
    }
  }

  onEdgeClick = (e, i) => {
    const map = this.props.map

    // if we clicked on the 'current' edge, then traverse the graph to the target vertex
    if (e.features[0].properties.idx === this.state.edgeTab) {
      let config = {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + this.props.loginCtx.token.accessToken
        },
        url: process.env.REACT_APP_BFF_URL + "/v1/getVertex?id=" +
          this.state.selectedVertices.features[this.state.vertexTab].properties['out edges'].edges[this.state.edgeTab].properties.target,
      };
      axios(config)
        .then((resp) => {
          // this.unselect()
          this.setNearestNeighbour(resp.data, false, false)
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      // get the index of the edge we clicked on and update the strokeOpacity of all the edges
      let idx = 0
      let edges = this.state.selectedEdges[this.state.vertexTab]
      for (let j = 0; j < this.state.selectedEdges[this.state.vertexTab].features.length; j++) {
        let strokeOpacity = 0.3
        if (e.features[0].properties.idx === j) {
          strokeOpacity = 0.8
          idx = j
        }
        edges.features[j].properties = {'idx': j, 'stroke-opacity': strokeOpacity}
      }
      map.getSource(NearestNeighbourTrgSource).setData(edges);

      this.setState({
        edgeTab: idx,
      })
    }
  }

  addPointLayer() {
    const map = this.props.map

    // Add a single point to the map
    map.addSource(NearestNeighbourPointSource, this.pin);

    map.addLayer({
      id: NearestNeighbourPointLayer,
      type: "circle",
      source: NearestNeighbourPointSource,
      paint: {
        'circle-radius': 10,
        'circle-color': "#3887be"
      }
    });
  }

  addNearestNeighbourLayer() {
    const map = this.props.map

    // add the nearest neighbour road layer
    map.addSource(NearestNeighbourLineSource, emptyFC);
    map.addLayer({
      "id": NearestNeighbourLineLayer,
      "type": "line",
      "source": NearestNeighbourLineSource,
      "paint": {
        "line-color": "#21d300",
        "line-width": 3,
        "line-opacity": 0.5,
      },
    });

    // layer containing the out edges
    map.addSource(NearestNeighbourTrgSource, emptyFC);
    map.addLayer({
      "id": NearestNeighbourTrgLayer,
      "type": "line",
      "source": NearestNeighbourTrgSource,
      "paint": {
        "line-color": "#9e0002",
        "line-width": 3,
        "line-opacity": ['get', 'stroke-opacity']
      },
    });

    // add the nearest neighbour road animation layer
    map.addSource(NearestNeighbourHeadingSource, emptyFC);
    map.addLayer({
      "id": NearestNeighbourHeadingLayer,
      "type": "line",
      "source": NearestNeighbourHeadingSource,
      "paint": {
        "line-color": "#00650d",
        "line-width": 3,
      },
    });
  }

  nearestNeighbour = (point, dragging) => {
    let config = {
      method: "GET",
      url: process.env.REACT_APP_BFF_URL + "/v1/nearestNeighbours.geojson?point=" + point.lat + "," + point.lng,
    };
    axios(config)
      .then((resp) => {
        this.setNearestNeighbour(resp.data, dragging, false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setNearestNeighbour = (val, dragging, setBounds) => {
    const map = this.props.map

    // remove drawn edges from the map
    if (this.state.selectedEdges) {
      map.getSource(NearestNeighbourTrgSource).setData(emptyFC.data);
    }

    // draw the vertex
    if (val.vertices && val.vertices.features && val.vertices.features.length > 0) {
      let vertex = null
      if (val.vertices.features[0]) {
        vertex = {
          type: "FeatureCollection",
          features: [val.vertices.features[0]]
        }
      }
      map.getSource(NearestNeighbourLineSource).setData(vertex);

      // draw the out edges
      if (!dragging && val.edges) {
        let edges = val.edges[0]
        for (let i = 0; i < val.edges[0].features.length; i++) {
          let strokeOpacity = 0.3
          if (i === 0) {
            strokeOpacity = 0.8
          }
          edges.features[i].properties = {'idx': i, 'stroke-opacity': strokeOpacity}
        }
        map.getSource(NearestNeighbourTrgSource).setData(edges);
      }

      // set up the animation of the road direction
      let geom = null
      if (!dragging) {
        let segDists = this.segmentDistances(val.vertices.features[0])
        geom = this.getAllFrames(val.vertices.features[0], segDists.distances, segDists.totalDistance)
      }

      this.setState({
        selectedVertices: val.vertices,
        selectedEdges: val.edges,
        headingGeom: geom,
        vertexTab: 0,
        edgeTab: 0,
        showKalman: false,
        showTurnHistory: false,
      })
      this.time = 0
      this.prevTime = 0

      if (!dragging) {
        let time = 0
        if (!this.isAnimationRunning) {
          this.isAnimationRunning = true
          // disabling for performance reasons
          // this.animateHeading(time)
        }
      }

      if (setBounds) {
        let bounds = map.getBounds()
        let vertGeom = val.vertices.features[0].geometry.coordinates
        let start = {lat: vertGeom[0][1], lng: vertGeom[0][0]}
        let end = {lat: vertGeom[vertGeom.length-1][1], lng: vertGeom[vertGeom.length-1][0]}
        if (!(start.lat <= bounds._ne.lat && start.lat >= bounds._sw.lat && end.lat <= bounds._ne.lat && end.lat >= bounds._sw.lat &&
          start.lng <= bounds._ne.lng && start.lng >= bounds._sw.lng && end.lng <= bounds._ne.lng && end.lng >= bounds._sw.lng)) {
          map.fitBounds([[
            start.lng,
            start.lat
          ], [
            end.lng,
            end.lat
          ]], {padding: 400});
        }
      }
    } else {
      map.getSource(NearestNeighbourLineSource).setData(emptyFC.data);
      map.getSource(NearestNeighbourHeadingSource).setData(emptyFC.data);
      if (this.state.selectedEdges) {
        map.getSource(NearestNeighbourTrgSource).setData(emptyFC.data)
      }
      this.setState({
        selectedVertices: null,
        selectedEdges: null,
        headingGeom: null,
        vertexTab: 0,
        edgeTab: 0,
        showKalman: false,
        showTurnHistory: false,
      })
      this.time = 0
      this.prevTime = 0
      this.isAnimationRunning = false
    }
  }

  // animate the road direction
  animateHeading = () => {
    if (this.state.headingGeom) {
      if (this.time - this.prevTime > animationInterval || this.prevTime === 0) {
        let source = this.props.map.getSource(NearestNeighbourHeadingSource)
        if (source) {
          source.setData(this.state.headingGeom[this.time])
        } else {
          return
        }
        this.prevTime = this.time
      }
      if (this.time + 1 >= animationFrames) {
        this.prevTime = 0
      }
      this.time = (this.time + 1) % animationFrames
      if (this.isAnimationRunning) {
        window.requestAnimationFrame(this.animateHeading);
      }
    }
  }

  // get all the animation points for every time step
  getAllFrames = (feature, segDists, totalDist) => {
    let coords = []
    for (let t = 0; t < animationFrames; t++) {
      coords.push(this.interpLine(t, feature, segDists, totalDist))
    }
    return coords
  }

  // get the cumulative distance of each of the points in the vertex geometry
  segmentDistances = (feature) => {
    let coords = feature.geometry.coordinates
    let dists = [0];
    let totalDist = 0;
    for (let i = 0; i < coords.length - 1; i++) {
      let dist = this.distance(coords[i][1], coords[i + 1][1], coords[i][0], coords[i + 1][0])
      totalDist += dist
      dists.push(totalDist)
    }
    return {distances: dists, totalDistance: totalDist}
  }

  // Get a sub-line within a vertex for a 'progress-bar' effect.
  // Interpolate the position along the vertex based on the time and the length of each segment.
  interpLine = (time, feature, segDists, totalDist) => {
    let delay = animationFrames / 3
    let binStart = this.getBin(Math.max(0, animationFrames * (time - delay) / (animationFrames - delay)), segDists, totalDist)
    let binEnd = this.getBin(Math.min(animationFrames / (animationFrames - delay) * time, animationFrames), segDists, totalDist)
    let coords = feature.geometry.coordinates

    let startPoints = this.interpPoints(time, binStart, coords, segDists)
    let endPoints = this.interpPoints(time, binEnd, coords, segDists)

    let points = [[startPoints.lng, startPoints.lat]]
    // mid points
    for (let i = binStart.idx + 1; i < binEnd.idx + 1; i++) {
      points.push([coords[i][0], coords[i][1]])
    }
    points.push([endPoints.lng, endPoints.lat])

    return {
      "type": "LineString",
      "coordinates": points
    };
  }

  // interpolate the lat and lng of the geometry in coords, based on the distance between each coordinate, segDists
  interpPoints = (time, bin, coords, segDists) => {
    let dist1 = segDists[bin.idx]
    let dist2 = segDists[bin.idx + 1]

    let lat1 = coords[bin.idx][1]
    let lng1 = coords[bin.idx][0]
    let lat2 = coords[bin.idx + 1][1]
    let lng2 = coords[bin.idx + 1][0]

    return {
      lat: this.interp1(dist1, dist2, lat1, lat2, bin.currentDist),
      lng: this.interp1(dist1, dist2, lng1, lng2, bin.currentDist)
    }
  }

  // get which segment we are in based on the current time
  getBin = (time, segDists, totalDist) => {
    let fraction = time / animationFrames
    let currDist = fraction * totalDist
    let segments = segDists

    for (var i = 0; i < segments.length; i++) {
      if (currDist < segments[i]) {
        break
      } else if (currDist >= segments[segments.length - 1]) {
        return {idx: segments.length - 2, currentDist: currDist}
      }
    }

    // the first segment distance is zero
    return {idx: i - 1, currentDist: currDist}
  }

  distance = (lat1, lat2, lng1, lng2) => {
    return (lat2 - lat1) * (lat2 - lat1) + (lng2 - lng1) * (lng2 - lng1)
  }

  interp1 = (x1, x2, y1, y2, xq) => {
    return y1 + (y2 - y1) / (x2 - x1) * (xq - x1)
  }

  unselect = (e) => {
    this.setNearestNeighbour({}, false, false)
  }

  handleChangeVertexTab = (event, value) => {
    let vertex = null
    let geom = null

    const map = this.props.map

    // remove drawn edges from the map
    map.getSource(NearestNeighbourTrgSource).setData(emptyFC.data);

    // update the vertex
    if (this.state.selectedVertices.features[value]) {
      vertex = {
        type: "FeatureCollection",
        features: [this.state.selectedVertices.features[value]]
      }
      map.getSource(NearestNeighbourLineSource).setData(vertex)

      // show the out edges of the new vertex
      if (this.state.selectedEdges && this.state.selectedEdges[value]) {
        let edges = this.state.selectedEdges[value]
        for (let i = 0; i < this.state.selectedEdges[value].features.length; i++) {
          let strokeOpacity = 0.3
          if (i === 0) {
            strokeOpacity = 0.8
          }
          edges.features[i].properties = {'idx': i, 'stroke-opacity': strokeOpacity}
        }
        map.getSource(NearestNeighbourTrgSource).setData(edges);
      }

      // set up the animation
      let segDists = this.segmentDistances(this.state.selectedVertices.features[value])
      geom = this.getAllFrames(vertex.features[0], segDists.distances, segDists.totalDistance)
    }

    this.setState({
      headingGeom: geom,
      vertexTab: value,
      edgeTab: 0,
      showKalman: false,
      showTurnHistory: false,
    })
    this.time = 0
    this.prevTime = 0
  }

  handleChangeEdgeTab = (event, value) => {
    const map = this.props.map
    if (this.state.selectedEdges && this.state.selectedEdges[this.state.vertexTab] != null) {
      let edges = this.state.selectedEdges[this.state.vertexTab]
      for (let i = 0; i < this.state.selectedEdges[this.state.vertexTab].features.length; i++) {
        let strokeOpacity = 0.3
        if (i === value) {
          strokeOpacity = 0.8
        }
        edges.features[i].properties = {'idx': i, 'stroke-opacity': strokeOpacity}
      }
      map.getSource(NearestNeighbourTrgSource).setData(edges);
    }

    this.setState({
      edgeTab: value
    });
  };

  onUidSearchEnter = (e) => {
    if (e.key === 'Enter') {
      let config = {
        method: "GET",
        url: process.env.REACT_APP_BFF_URL + "/v1/getVertex?id=" +
          e.target.value,
      };
      axios(config)
        .then((resp) => {
          this.setNearestNeighbour(resp.data, false, true)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  onKalmanClick = (e) => {
    let show = !this.state.showKalman
    let anchorEl = null
    if (show) {
      anchorEl = e.currentTarget
      // anchor the kalman to the card root
      while (anchorEl) {
        anchorEl = anchorEl.parentNode
        if (anchorEl.className.includes("root")) break
      }
    }
    this.setState({
      showKalman: show,
      kalmanAnchorEl: anchorEl
    })
  }

  onKalmanClose = () => {
    this.setState(({
      showKalman: false,
      kalmanAnchorEl: null
    }))
  }

  onTurnHistoryClick = (e) => {
    let show = !this.state.showTurnHistory
    let anchorEl = null
    if (show) {
      anchorEl = e.currentTarget
      // anchor the kalman to the card root
      while (anchorEl) {
        anchorEl = anchorEl.parentNode
        if (anchorEl.className.includes("root")) break
      }
    }
    this.setState({
      showTurnHistory: show,
      turnHistoryAnchorEl: anchorEl
    })
  }

  onTurnHistoryClose = () => {
    this.setState(({
      showTurnHistory: false,
      turnHistoryAnchorEl: null
    }))
  }

  render() {
    let edgeContent = null
    let kalmanChart = null

    let vertices = null
    if (this.state.selectedVertices && this.state.selectedVertices.features) {
      vertices = this.state.selectedVertices.features
    }

    let edges = null
    if (this.state.selectedVertices && this.state.selectedVertices.features[this.state.vertexTab]) {
      edges = this.state.selectedVertices.features[this.state.vertexTab].properties['out edges'].edges
    }

    if (edges && edges.length > 0) {
      let turnCostChart = null
      let edgeProps = edges[this.state.edgeTab].properties
      if (edgeProps.turnCostHistory && edgeProps.turnCostHistory.turn_cost_history.historic_pattern_in_seconds) {
        turnCostChart = <HistoricTurnCostChart
          history={edgeProps.turnCostHistory.turn_cost_history}
          flow={edgeProps.turnCostHistory.vehicle_flow_history}
          signalHistory={edgeProps.turnCostHistory.signal_cost_history}
          current={edgeProps.turnCostHistory.current}
          longTerm={edgeProps.turnCostHistory.long_term}
          totalMeasurements={edgeProps.turnCostHistory.total_measurements}
          lastChanged={edgeProps.turnCostHistory.last_changed}
          closeCallback={this.onTurnHistoryClose}
        />
      }

      let historicTurnCostMenu = null
      if (turnCostChart) {
        let buttonText = this.state.showTurnCost ? 'Hide historic turn cost' : 'Show historic turn cost'
        historicTurnCostMenu = (
          <div>
            <Button
              className={styles.kalmanButton}
              aria-owns={this.state.showTurnHistory ? 'simple-menu' : null}
              aria-haspopup='true'
              onClick={this.onTurnHistoryClick}
            >
              {buttonText}
            </Button>
            <Menu
              className={styles.chartMenu}
              id='simple-menu'
              anchorEl={this.state.turnHistoryAnchorEl}
              open={this.state.showTurnHistory}
              getContentAnchorEl={null}
              anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            >
              {turnCostChart}
            </Menu>
          </div>
        )
      }
      edgeContent = (
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.edgeTab}
              onChange={this.handleChangeEdgeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {
                edges.map(function (key, index) {
                  return (
                    <Tab label={'edge ' + (index + 1)} style={{textTransform: 'none'}}/>
                  )
                })
              }
            </Tabs>
          </AppBar>
          {historicTurnCostMenu}
          <SwipeableViews
            axis={'x'}
            index={this.state.edgeTab}
            onChangeIndex={this.handleChangeEdgeTab}
          >
            {
              edges.map(function (key, index) {
                let edgeTab = edges[index]
                return <PropertyTable propertyMap={edgeTab.properties}/>
              })
            }
          </SwipeableViews>
        </div>)
    }

    if (vertices) {
      let vertexProps = vertices[this.state.vertexTab].properties
      if (vertexProps.kalman && vertexProps.kalman.historicPattern) {
        kalmanChart = <HistoricChart
          kalman={vertexProps.kalman}
          vehicleFlow={vertexProps.vehicleFlowHistoricPattern}
          highway={vertexProps.highway}
          speedLimit={vertexProps['speed limit']}
        />
      }

      let kalmanMenu = null
      if (kalmanChart) {
        let buttonText = this.state.showKalman ? 'Hide historic speed' : 'Show historic speed'
        kalmanMenu = (
          <div>
            <Button
              className={styles.kalmanButton}
              aria-owns={this.state.showKalman ? 'simple-menu' : null}
              aria-haspopup='true'
              onClick={this.onKalmanClick}
            >
              {buttonText}
            </Button>
            <Menu
              className={styles.chartMenu}
              id='simple-menu'
              anchorEl={this.state.kalmanAnchorEl}
              open={this.state.showKalman}
              getContentAnchorEl={null}
              anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            >
              <ClickAwayListener onClickAway={this.onKalmanClose}>
                {kalmanChart}
              </ClickAwayListener>
            </Menu>
          </div>
        )
      }

      return (
        <Card className={styles.body}>
          <CardContent className={styles.content}>
            <div>
              <Button variant="contained" onClick={this.unselect}
                      style={{marginBottom: '10px'}}>close</Button>
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.vertexTab}
                  onChange={this.handleChangeVertexTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  {
                    vertices.map(function (key, index) {
                      return (
                        <Tab label={'vertex ' + (index + 1)} style={{textTransform: 'none'}}/>
                      )
                    })
                  }
                </Tabs>
              </AppBar>
              {kalmanMenu}
              <SwipeableViews
                axis={'x'}
                index={this.state.vertexTab}
                onChangeIndex={this.handleChangeVertexTab}
              >
                {
                  vertices.map(function (key, index) {
                    let vertexTab = vertices[index]
                    return (
                      <div>
                        <PropertyTable propertyMap={vertexTab.properties}/>
                        {edgeContent}
                      </div>
                    )
                  })
                }
              </SwipeableViews>
            </div>
          </CardContent>
        </Card>)
    } else {
      return (
        <div className={styles.uidSearchBody}>
          <TextField className={styles.uidSearch} id="uid-search" label="enter uid or click on the map" variant="filled" onKeyPress={this.onUidSearchEnter} />
        </div>
      )
    }
  }
}