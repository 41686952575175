import React from 'react'
import styles from './LoginContainer.module.css'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LoginContext, { LoginContextType } from '../LoginContext'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

export default class LoginContainer extends React.Component {

  state = {
    username: '',
    password: '',
    credentialsRefused: false,
    loginAttemptStarted: false,
  }

  onUsernameChanged = (e) => {
    this.setState({
      username: e.target.value
    })
  }

  onPasswordChanged = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  onLoginClicked = (context) => {
    const onError = () => {
      console.log('login failed')
      this.setState({
        credentialsRefused: true,
        loginAttemptStarted: false
      })
    }

    this.setState({
      loginAttemptStarted: true
    })

    context.attemptLogin(this.state.username, this.state.password, onError)
  }

  onKeyPress = (e, context) => {
    if (e.key === 'Enter') {
      this.onLoginClicked(context)
    }
  }

  render () {

    return (
      <LoginContext.Consumer>
        {(context: LoginContextType) => {
          return (
            <Dialog open onKeyPress={(e) => {this.onKeyPress(e, context)}}>
              <div className={styles.body}>
                <DialogTitle>LOGIN</DialogTitle>
                <DialogContent>

                  <TextField

                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="text"
                    fullWidth
                    value={this.state.username}
                    error={this.state.credentialsRefused}
                    onChange={this.onUsernameChanged}


                  />
                  <TextField
                    error={this.state.credentialsRefused}
                    autoFocus
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={this.onPasswordChanged}
                  />
                </DialogContent>
                <DialogActions>
                  <Button disabled={this.state.loginAttemptStarted}
                          onClick={() => {this.onLoginClicked(context)}}
                          variant="contained"
                          color="primary">
                    Login
                    {this.state.loginAttemptStarted && <div className={styles.buttonProgress}>
                      <CircularProgress size={20} color='secondary'/>
                    </div>}
                  </Button>
                </DialogActions>


              </div>
            </Dialog>
          )
        }}


      </LoginContext.Consumer>)
  }
}