//@flow
import React from 'react'
import mapboxgl from 'mapbox-gl'
import {withTrafficLayerData} from './TrafficLayerHOC'
import type {SelectedEdgeContextType} from '../../components/traffic/EdgeCard'
import type {LoginContextType} from '../../LoginContext'
import StreetLight from './icon_street-light.svg'
import axios from "axios";

type Props = {
  map: mapboxgl.Map,
  selectedEdgeContext: SelectedEdgeContextType,
  loginCtx: LoginContextType
}
type State = {}

const osmTrafficLightSource = 'osm_traffic_lights_source'
const osmTrafficLightLayerId = 'osm_traffic_lights_layer'
const dynamicTrafficLightSource = 'dynamic_traffic_lights_source'
const dynamicTrafficLightLayerId = 'dynamic_traffic_lights_layer'
const baseUrlTiles = 'https://graph-tiles.nugraph.graphmasters.net/v1/traffic-lights'

const image = new Image(100, 100);
image.src = StreetLight;
const size = 100
const numLayers = 10
var streetLightAsString = []

const osmTrafficLightLayer = {
  "id": osmTrafficLightLayerId,
  // "minzoom": 9,
  "type": "symbol",
  "layout": {
    'icon-image': 'street-light',
    'icon-size': 0.3,
  },
  "filter": [
    "!=",
    ['get', 'liveData'],
    true
  ],
  "source-layer": "traffic-lights",
  "source": osmTrafficLightSource
}

function newDynamicTrafficLightLayer(layerId) {
  return {
    "id": dynamicTrafficLightLayerId+layerId,
    // "minzoom": 9,
    "type": "symbol",
    "layout": {
      'icon-image': 'dynamic-street-light'+layerId,
      'icon-size': 0.3,
    },
    "filter": [
      "all",
      ["==", ['%', ['get', 'id'], layerId], 0],
      ["has", "liveData"]
    ],
    "source-layer": "traffic-lights",
    "source": dynamicTrafficLightSource+layerId
  }
}

// see https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
function dynamicTrafficLights(map, offset) {
  return {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },

    render: function () {
      let duration = 20000
      let t = ((offset*duration + performance.now()) % duration) / duration

      let context = this.context;
      context.clearRect(0, 0, this.width, this.height);
      context.scale(size/24, size/24)
      context.fillStyle = `rgba(0, 0, 0)`

      for (let i = 0; i < streetLightAsString.length; i++) {
        context.fill(new Path2D(streetLightAsString[i]))
      }

      let redAlpha = 0.3
      let amberAlpha = 0.3
      let greenAlpha = 0.3
      if (t < 0.4) {
        redAlpha = 1
      } else if (t < 0.5) {
        redAlpha = 1
        amberAlpha = 1
      } else if (t < 0.9) {
        greenAlpha = 1
      } else {
        amberAlpha = 1
      }

      context.fillStyle = `rgb(200, 0, 0, ${redAlpha})`
      context.beginPath()
      context.arc(12, 6, 2, 0, Math.PI * 2)
      context.fill()

      context.fillStyle = `rgb(255, 194, 0, ${amberAlpha})`
      context.beginPath()
      context.arc(12, 12, 2, 0, Math.PI * 2)
      context.fill()

      context.fillStyle = `rgb(37, 233, 0, ${greenAlpha})`
      context.beginPath()
      context.arc(12, 18, 2, 0, Math.PI * 2)
      context.fill()

      context.scale(24/size, 24/size)

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;
      this.offset = offset

      map.triggerRepaint();

      return true;
    }
  };
}

/*
*
*/
class TrafficLightsLayerVector extends React.Component<Props, State> {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // get the traffic light svg file as a string and extract the paths
    axios.get(image.src, {
      "Content-Type": "application/xml; charset=utf-8"
    })
      .then((response) => {
        let dom = new DOMParser().parseFromString(response.data, "application/xml");
        let paths = dom.getElementsByTagName('path')
        for (let i = 0; i < paths.length; i++) {
          streetLightAsString.push(paths[i].attributes[0].value)
        }
      });

    const map = this.props.map
    map.addImage('street-light', image);
    map.addSource(osmTrafficLightSource, generateTrafficLightsSource(this.props.loginCtx.token.accessToken, osmTrafficLightSource))
    map.addLayer(osmTrafficLightLayer)

    // giving the dynamic traffic lights a random start image animation using the modulus of the id (ie id%numLayers)
    // so we add 10 layers with all the data but each layer only has 1/10th of the data visible (using the filter query)
    for (let i = 0; i < numLayers; i++) {
      let dynamicStreetLights = dynamicTrafficLights(map, i/numLayers)
      map.addImage('dynamic-street-light'+i, dynamicStreetLights);
      map.addSource(dynamicTrafficLightSource+i, generateTrafficLightsSource(this.props.loginCtx.token.accessToken, dynamicTrafficLightSource+i))
      map.addLayer(newDynamicTrafficLightLayer(i))
    }
  }

  componentWillUnmount() {
    const map = this.props.map
    for (let i = 0; i < numLayers; i++) {
      map.removeLayer(dynamicTrafficLightLayerId+i)
      map.removeSource(dynamicTrafficLightSource+i)
      map.removeImage('dynamic-street-light'+i)
    }
    map.removeLayer(osmTrafficLightLayerId)
    map.removeSource(osmTrafficLightSource)
    map.removeImage('street-light')
  }

  render() {
    return null
  }
}

export default withTrafficLayerData(TrafficLightsLayerVector)

function generateTrafficLightsSource(token, id) {
  return {
    id: id,
    tiles: [
      baseUrlTiles + "/{z}/{x}/{y}.mvt?accessToken=" + token
    ],
    type: "vector"
  }
}
