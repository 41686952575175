import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

type Props = {
  kalman: {},
}

let dayStrings = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
let slotDuration = 600 // seconds
let slotsPerDay = 24 * 3600 / slotDuration // 144 slots per day
let slotsPerHour = 3600 / slotDuration // 6 slots per hour
let slotsPerMinute = 60 / slotDuration

export class HistoricChartHighchart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  twoDP(val) {
    return Math.round(val * 100) / 100
  }

  render() {
    let historicPattern = this.props.kalman.historicPattern
    let now = new Date()
    let day = now.getUTCDay()
    let hour = now.getUTCHours()
    let minute = now.getUTCMinutes()
    let historicSlot = Math.floor(day * slotsPerDay + hour * slotsPerHour + minute * slotsPerMinute)
    let historicData = historicPattern.slice()
    if (historicSlot < historicData.length) {
      historicData[historicSlot] = {
        y: historicData[historicSlot],
        color: '#fb00aa'
      }

      let dayMarkers = []
      for (let day = 1; day < 7; day++) {
        dayMarkers.push({
          color: 'red', // Color value
          value: day * slotsPerDay, // Value of where the line will appear
          width: 2 // Width of the line
        })
      }

      const options = {
        title: {
          text: 'Historic pattern'
        },
        tooltip: {
          formatter: function () {
            const day = this.x / slotsPerDay
            let dayString = dayStrings[Math.floor(day)]
            let time = this.x % slotsPerDay
            time = time * 10
            let hour = Math.floor(time / 60)
            let minutes = Math.floor(time % 60)

            return dayString + ', ' + String(hour).padStart(2, '0') + '.' + String(minutes).padStart(2, '0') + ' : <b>' + this.y + ' km/h</b>'
          }
        },
        series: [{
          name: 'historic',
          data: historicData,
          zones: [{
            value: 60,
            color: '#f7a35c'
          }, {
            value: 100,
            color: '#90ed7d'
          }, {
            color: '#7cb5ec'
          }]
        }],
        chart: {
          height: '50%',
          type: 'column'
        },
        plotOptions: {
          series: {
            animation: false
          }
        },
        xAxis: {
          title: {
            text: null
          },
          plotLines: dayMarkers,
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: {
            text: null
          },
          labels: {
            format: '{value} km/h'
          }
        }
      }

      return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      )
    }
  }
}