// source: routing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.AgentData', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Bucket', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.ChartData', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Comparison', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.ComparisonType', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.ConfigurablePenalties', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Coordinate', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.CurrentTurnCommand', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Day', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.DebugOpacities', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Destinations', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Feature', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.FeatureCollection', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Geometry', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Geometry.CoordinatesCase', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetRouteRequest', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetRouteRequest.CountrycodeCase', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetRouteRequest.StarttimesCase', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetRouteResponse', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetTourRequest', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.GetTourResponse', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Lane', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.LineString', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.PathType', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Point', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Polygon', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Properties', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Provider', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.ProviderType', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Reservation', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Restrictions', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.RoutingLayer', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.RoutingLayerVertex', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.SimulationRequest', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.SimulationResponse', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.SimulationSettings', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.SimulationStats', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.SimulationUpdateResponse', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.Tour', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.TruckParameters', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.TurnInfo', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.VehicleProperties', null, global);
goog.exportSymbol('proto.graphmasters.nugraph_website.route.VehicleType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Coordinate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Coordinate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Coordinate.displayName = 'proto.graphmasters.nugraph_website.route.Coordinate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Lane = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Lane.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Lane, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Lane.displayName = 'proto.graphmasters.nugraph_website.route.Lane';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.TurnInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.TurnInfo.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.TurnInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.TurnInfo.displayName = 'proto.graphmasters.nugraph_website.route.TurnInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Destinations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Destinations.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Destinations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Destinations.displayName = 'proto.graphmasters.nugraph_website.route.Destinations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Properties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Properties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Properties.displayName = 'proto.graphmasters.nugraph_website.route.Properties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.DebugOpacities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.DebugOpacities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.DebugOpacities.displayName = 'proto.graphmasters.nugraph_website.route.DebugOpacities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Restrictions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Restrictions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Restrictions.displayName = 'proto.graphmasters.nugraph_website.route.Restrictions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Reservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Reservation.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Reservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Reservation.displayName = 'proto.graphmasters.nugraph_website.route.Reservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Bucket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Bucket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Bucket.displayName = 'proto.graphmasters.nugraph_website.route.Bucket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Point.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Point.displayName = 'proto.graphmasters.nugraph_website.route.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.LineString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.LineString.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.LineString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.LineString.displayName = 'proto.graphmasters.nugraph_website.route.LineString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Polygon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Polygon.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Polygon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Polygon.displayName = 'proto.graphmasters.nugraph_website.route.Polygon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Geometry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Geometry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Geometry.displayName = 'proto.graphmasters.nugraph_website.route.Geometry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Feature.displayName = 'proto.graphmasters.nugraph_website.route.Feature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.ChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.ChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.ChartData.displayName = 'proto.graphmasters.nugraph_website.route.ChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.ConfigurablePenalties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.ConfigurablePenalties.displayName = 'proto.graphmasters.nugraph_website.route.ConfigurablePenalties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.AgentData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.AgentData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.AgentData.displayName = 'proto.graphmasters.nugraph_website.route.AgentData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.GetRouteResponse.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.GetRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.GetRouteResponse.displayName = 'proto.graphmasters.nugraph_website.route.GetRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.FeatureCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.FeatureCollection.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.FeatureCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.FeatureCollection.displayName = 'proto.graphmasters.nugraph_website.route.FeatureCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.SimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.SimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.SimulationResponse.displayName = 'proto.graphmasters.nugraph_website.route.SimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.SimulationStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.SimulationStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.SimulationStats.displayName = 'proto.graphmasters.nugraph_website.route.SimulationStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.TruckParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.TruckParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.TruckParameters.displayName = 'proto.graphmasters.nugraph_website.route.TruckParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.VehicleProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.VehicleProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.VehicleProperties.displayName = 'proto.graphmasters.nugraph_website.route.VehicleProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.GetRouteRequest.repeatedFields_, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_);
};
goog.inherits(proto.graphmasters.nugraph_website.route.GetRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.GetRouteRequest.displayName = 'proto.graphmasters.nugraph_website.route.GetRouteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.SimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.SimulationRequest.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.SimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.SimulationRequest.displayName = 'proto.graphmasters.nugraph_website.route.SimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Comparison = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Comparison, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Comparison.displayName = 'proto.graphmasters.nugraph_website.route.Comparison';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Provider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Provider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Provider.displayName = 'proto.graphmasters.nugraph_website.route.Provider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.CurrentTurnCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.CurrentTurnCommand.displayName = 'proto.graphmasters.nugraph_website.route.CurrentTurnCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.displayName = 'proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.SimulationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.SimulationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.SimulationSettings.displayName = 'proto.graphmasters.nugraph_website.route.SimulationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.SimulationUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.displayName = 'proto.graphmasters.nugraph_website.route.SimulationUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.RoutingLayerVertex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.RoutingLayerVertex.displayName = 'proto.graphmasters.nugraph_website.route.RoutingLayerVertex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.RoutingLayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.RoutingLayer.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.RoutingLayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.RoutingLayer.displayName = 'proto.graphmasters.nugraph_website.route.RoutingLayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.GetTourRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.GetTourRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.GetTourRequest.displayName = 'proto.graphmasters.nugraph_website.route.GetTourRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.GetTourResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.GetTourResponse.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.GetTourResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.GetTourResponse.displayName = 'proto.graphmasters.nugraph_website.route.GetTourResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Day = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Day.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Day, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Day.displayName = 'proto.graphmasters.nugraph_website.route.Day';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.graphmasters.nugraph_website.route.Tour = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.graphmasters.nugraph_website.route.Tour.repeatedFields_, null);
};
goog.inherits(proto.graphmasters.nugraph_website.route.Tour, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.graphmasters.nugraph_website.route.Tour.displayName = 'proto.graphmasters.nugraph_website.route.Tour';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Coordinate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Coordinate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Coordinate.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Coordinate;
  return proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Coordinate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Coordinate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Coordinate} returns this
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lng = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Coordinate} returns this
 */
proto.graphmasters.nugraph_website.route.Coordinate.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Lane.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Lane.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Lane} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Lane.toObject = function(includeInstance, msg) {
  var f, obj = {
    laneTurnsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    shouldUse: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    destinations: (f = msg.getDestinations()) && proto.graphmasters.nugraph_website.route.Destinations.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Lane}
 */
proto.graphmasters.nugraph_website.route.Lane.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Lane;
  return proto.graphmasters.nugraph_website.route.Lane.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Lane} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Lane}
 */
proto.graphmasters.nugraph_website.route.Lane.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLaneTurns(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldUse(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.Destinations;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Destinations.deserializeBinaryFromReader);
      msg.setDestinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Lane.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Lane} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Lane.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLaneTurnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getShouldUse();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDestinations();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.Destinations.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string lane_turns = 1;
 * @return {!Array<string>}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.getLaneTurnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.setLaneTurnsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.addLaneTurns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.clearLaneTurnsList = function() {
  return this.setLaneTurnsList([]);
};


/**
 * optional bool should_use = 2;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.getShouldUse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.setShouldUse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Destinations destinations = 3;
 * @return {?proto.graphmasters.nugraph_website.route.Destinations}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.getDestinations = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Destinations} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Destinations, 3));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Destinations|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
*/
proto.graphmasters.nugraph_website.route.Lane.prototype.setDestinations = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Lane} returns this
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.clearDestinations = function() {
  return this.setDestinations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Lane.prototype.hasDestinations = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.TurnInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.TurnInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.TurnInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.TurnInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    turnCommand: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shouldSuppressTurnCommand: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lanesList: jspb.Message.toObjectList(msg.getLanesList(),
    proto.graphmasters.nugraph_website.route.Lane.toObject, includeInstance),
    turnCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    destinations: (f = msg.getDestinations()) && proto.graphmasters.nugraph_website.route.Destinations.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.TurnInfo;
  return proto.graphmasters.nugraph_website.route.TurnInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.TurnInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnCommand(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldSuppressTurnCommand(value);
      break;
    case 4:
      var value = new proto.graphmasters.nugraph_website.route.Lane;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Lane.deserializeBinaryFromReader);
      msg.addLanes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnCost(value);
      break;
    case 6:
      var value = new proto.graphmasters.nugraph_website.route.Destinations;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Destinations.deserializeBinaryFromReader);
      msg.setDestinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.TurnInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.TurnInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.TurnInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurnCommand();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShouldSuppressTurnCommand();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLanesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.graphmasters.nugraph_website.route.Lane.serializeBinaryToWriter
    );
  }
  f = message.getTurnCost();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDestinations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.graphmasters.nugraph_website.route.Destinations.serializeBinaryToWriter
    );
  }
};


/**
 * optional string turn_command = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.getTurnCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.setTurnCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool should_suppress_turn_command = 3;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.getShouldSuppressTurnCommand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.setShouldSuppressTurnCommand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated Lane lanes = 4;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Lane>}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.getLanesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Lane>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Lane, 4));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Lane>} value
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
*/
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.setLanesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Lane=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Lane}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.addLanes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.graphmasters.nugraph_website.route.Lane, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.clearLanesList = function() {
  return this.setLanesList([]);
};


/**
 * optional double turn_cost = 5;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.getTurnCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.setTurnCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Destinations destinations = 6;
 * @return {?proto.graphmasters.nugraph_website.route.Destinations}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.getDestinations = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Destinations} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Destinations, 6));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Destinations|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
*/
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.setDestinations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.TurnInfo} returns this
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.clearDestinations = function() {
  return this.setDestinations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.TurnInfo.prototype.hasDestinations = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Destinations.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Destinations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Destinations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Destinations.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    refsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Destinations}
 */
proto.graphmasters.nugraph_website.route.Destinations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Destinations;
  return proto.graphmasters.nugraph_website.route.Destinations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Destinations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Destinations}
 */
proto.graphmasters.nugraph_website.route.Destinations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRefs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Destinations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Destinations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Destinations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * repeated string refs = 2;
 * @return {!Array<string>}
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.getRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.setRefsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.addRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Destinations} returns this
 */
proto.graphmasters.nugraph_website.route.Destinations.prototype.clearRefsList = function() {
  return this.setRefsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Properties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Properties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Properties.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, ""),
    anticipatedSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    turnInfo: (f = msg.getTurnInfo()) && proto.graphmasters.nugraph_website.route.TurnInfo.toObject(includeInstance, f),
    path: jspb.Message.getFieldWithDefault(msg, 5, 0),
    frequencyDensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    selfish: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dijkstraCost: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vehicleColourIndex: jspb.Message.getFieldWithDefault(msg, 9, 0),
    id: jspb.Message.getFieldWithDefault(msg, 10, ""),
    reservation: (f = msg.getReservation()) && proto.graphmasters.nugraph_website.route.Reservation.toObject(includeInstance, f),
    stopIndex: jspb.Message.getFieldWithDefault(msg, 12, 0),
    highZoomOpacity: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lowZoomOpacity: jspb.Message.getFieldWithDefault(msg, 14, 0),
    restrictions: (f = msg.getRestrictions()) && proto.graphmasters.nugraph_website.route.Restrictions.toObject(includeInstance, f),
    icon: jspb.Message.getFieldWithDefault(msg, 16, ""),
    debugOpacities: (f = msg.getDebugOpacities()) && proto.graphmasters.nugraph_website.route.DebugOpacities.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Properties}
 */
proto.graphmasters.nugraph_website.route.Properties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Properties;
  return proto.graphmasters.nugraph_website.route.Properties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Properties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Properties}
 */
proto.graphmasters.nugraph_website.route.Properties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnticipatedSpeed(value);
      break;
    case 4:
      var value = new proto.graphmasters.nugraph_website.route.TurnInfo;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.TurnInfo.deserializeBinaryFromReader);
      msg.setTurnInfo(value);
      break;
    case 5:
      var value = /** @type {!proto.graphmasters.nugraph_website.route.PathType} */ (reader.readEnum());
      msg.setPath(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequencyDensity(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfish(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDijkstraCost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVehicleColourIndex(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 11:
      var value = new proto.graphmasters.nugraph_website.route.Reservation;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Reservation.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStopIndex(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHighZoomOpacity(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowZoomOpacity(value);
      break;
    case 15:
      var value = new proto.graphmasters.nugraph_website.route.Restrictions;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Restrictions.deserializeBinaryFromReader);
      msg.setRestrictions(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 17:
      var value = new proto.graphmasters.nugraph_website.route.DebugOpacities;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.DebugOpacities.deserializeBinaryFromReader);
      msg.setDebugOpacities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Properties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Properties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Properties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnticipatedSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTurnInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.graphmasters.nugraph_website.route.TurnInfo.serializeBinaryToWriter
    );
  }
  f = message.getPath();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFrequencyDensity();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getSelfish();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDijkstraCost();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVehicleColourIndex();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.graphmasters.nugraph_website.route.Reservation.serializeBinaryToWriter
    );
  }
  f = message.getStopIndex();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getHighZoomOpacity();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getLowZoomOpacity();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getRestrictions();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.graphmasters.nugraph_website.route.Restrictions.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDebugOpacities();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.graphmasters.nugraph_website.route.DebugOpacities.serializeBinaryToWriter
    );
  }
};


/**
 * optional string length = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setLength = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string duration = 2;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double anticipated_speed = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getAnticipatedSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setAnticipatedSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional TurnInfo turn_info = 4;
 * @return {?proto.graphmasters.nugraph_website.route.TurnInfo}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getTurnInfo = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.TurnInfo} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.TurnInfo, 4));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.TurnInfo|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
*/
proto.graphmasters.nugraph_website.route.Properties.prototype.setTurnInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.clearTurnInfo = function() {
  return this.setTurnInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.hasTurnInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PathType path = 5;
 * @return {!proto.graphmasters.nugraph_website.route.PathType}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getPath = function() {
  return /** @type {!proto.graphmasters.nugraph_website.route.PathType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.PathType} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setPath = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float frequency_density = 6;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getFrequencyDensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setFrequencyDensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool selfish = 7;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getSelfish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setSelfish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string dijkstra_cost = 8;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getDijkstraCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setDijkstraCost = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 vehicle_colour_index = 9;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getVehicleColourIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setVehicleColourIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string id = 10;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Reservation reservation = 11;
 * @return {?proto.graphmasters.nugraph_website.route.Reservation}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getReservation = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Reservation} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Reservation, 11));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Reservation|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
*/
proto.graphmasters.nugraph_website.route.Properties.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 stop_index = 12;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getStopIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setStopIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 high_zoom_opacity = 13;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getHighZoomOpacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setHighZoomOpacity = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 low_zoom_opacity = 14;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getLowZoomOpacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setLowZoomOpacity = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Restrictions restrictions = 15;
 * @return {?proto.graphmasters.nugraph_website.route.Restrictions}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getRestrictions = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Restrictions} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Restrictions, 15));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Restrictions|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
*/
proto.graphmasters.nugraph_website.route.Properties.prototype.setRestrictions = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.clearRestrictions = function() {
  return this.setRestrictions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.hasRestrictions = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string icon = 16;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional DebugOpacities debug_opacities = 17;
 * @return {?proto.graphmasters.nugraph_website.route.DebugOpacities}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.getDebugOpacities = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.DebugOpacities} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.DebugOpacities, 17));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.DebugOpacities|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
*/
proto.graphmasters.nugraph_website.route.Properties.prototype.setDebugOpacities = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Properties} returns this
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.clearDebugOpacities = function() {
  return this.setDebugOpacities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Properties.prototype.hasDebugOpacities = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.DebugOpacities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.DebugOpacities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowZoomOpacity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    midZoomOpacity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    highZoomOpacity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.DebugOpacities}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.DebugOpacities;
  return proto.graphmasters.nugraph_website.route.DebugOpacities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.DebugOpacities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.DebugOpacities}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowZoomOpacity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMidZoomOpacity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHighZoomOpacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.DebugOpacities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.DebugOpacities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowZoomOpacity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMidZoomOpacity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHighZoomOpacity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 low_zoom_opacity = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.getLowZoomOpacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.DebugOpacities} returns this
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.setLowZoomOpacity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 mid_zoom_opacity = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.getMidZoomOpacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.DebugOpacities} returns this
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.setMidZoomOpacity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 high_zoom_opacity = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.getHighZoomOpacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.DebugOpacities} returns this
 */
proto.graphmasters.nugraph_website.route.DebugOpacities.prototype.setHighZoomOpacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Restrictions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Restrictions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Restrictions.toObject = function(includeInstance, msg) {
  var f, obj = {
    barrier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    closure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    physicalRestrictions: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accessRestriction: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toll: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    conditionalRestriction: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions}
 */
proto.graphmasters.nugraph_website.route.Restrictions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Restrictions;
  return proto.graphmasters.nugraph_website.route.Restrictions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Restrictions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions}
 */
proto.graphmasters.nugraph_website.route.Restrictions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBarrier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhysicalRestrictions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessRestriction(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToll(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionalRestriction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Restrictions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Restrictions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Restrictions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBarrier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClosure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhysicalRestrictions();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccessRestriction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToll();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getConditionalRestriction();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string barrier = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getBarrier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setBarrier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string closure = 2;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getClosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setClosure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string physical_restrictions = 3;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getPhysicalRestrictions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setPhysicalRestrictions = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string access_restriction = 4;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getAccessRestriction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setAccessRestriction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool toll = 5;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getToll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setToll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string conditional_restriction = 6;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.getConditionalRestriction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Restrictions} returns this
 */
proto.graphmasters.nugraph_website.route.Restrictions.prototype.setConditionalRestriction = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Reservation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Reservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Reservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Reservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTimeUnixNano: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bucketWidthNanoseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bucketsList: jspb.Message.toObjectList(msg.getBucketsList(),
    proto.graphmasters.nugraph_website.route.Bucket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Reservation}
 */
proto.graphmasters.nugraph_website.route.Reservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Reservation;
  return proto.graphmasters.nugraph_website.route.Reservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Reservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Reservation}
 */
proto.graphmasters.nugraph_website.route.Reservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimeUnixNano(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBucketWidthNanoseconds(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.Bucket;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Bucket.deserializeBinaryFromReader);
      msg.addBuckets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Reservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Reservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Reservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTimeUnixNano();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBucketWidthNanoseconds();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBucketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.Bucket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 start_time_unix_nano = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.getStartTimeUnixNano = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Reservation} returns this
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.setStartTimeUnixNano = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bucket_width_nanoseconds = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.getBucketWidthNanoseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Reservation} returns this
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.setBucketWidthNanoseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Bucket buckets = 3;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Bucket>}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.getBucketsList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Bucket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Bucket, 3));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Bucket>} value
 * @return {!proto.graphmasters.nugraph_website.route.Reservation} returns this
*/
proto.graphmasters.nugraph_website.route.Reservation.prototype.setBucketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Bucket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Bucket}
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.addBuckets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.graphmasters.nugraph_website.route.Bucket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Reservation} returns this
 */
proto.graphmasters.nugraph_website.route.Reservation.prototype.clearBucketsList = function() {
  return this.setBucketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Bucket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Bucket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Bucket.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservations: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    travelTimeSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Bucket}
 */
proto.graphmasters.nugraph_website.route.Bucket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Bucket;
  return proto.graphmasters.nugraph_website.route.Bucket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Bucket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Bucket}
 */
proto.graphmasters.nugraph_website.route.Bucket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReservations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTravelTimeSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Bucket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Bucket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Bucket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservations();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTravelTimeSeconds();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double reservations = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.getReservations = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Bucket} returns this
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.setReservations = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double travel_time_seconds = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.getTravelTimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.Bucket} returns this
 */
proto.graphmasters.nugraph_website.route.Bucket.prototype.setTravelTimeSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Point.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    lngLatList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Point}
 */
proto.graphmasters.nugraph_website.route.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Point;
  return proto.graphmasters.nugraph_website.route.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Point}
 */
proto.graphmasters.nugraph_website.route.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLngLat(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLngLatList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double lng_lat = 1;
 * @return {!Array<number>}
 */
proto.graphmasters.nugraph_website.route.Point.prototype.getLngLatList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.graphmasters.nugraph_website.route.Point} returns this
 */
proto.graphmasters.nugraph_website.route.Point.prototype.setLngLatList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Point} returns this
 */
proto.graphmasters.nugraph_website.route.Point.prototype.addLngLat = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Point} returns this
 */
proto.graphmasters.nugraph_website.route.Point.prototype.clearLngLatList = function() {
  return this.setLngLatList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.LineString.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.LineString.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.LineString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.LineString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.LineString.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.graphmasters.nugraph_website.route.Point.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.LineString}
 */
proto.graphmasters.nugraph_website.route.LineString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.LineString;
  return proto.graphmasters.nugraph_website.route.LineString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.LineString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.LineString}
 */
proto.graphmasters.nugraph_website.route.LineString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Point;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Point.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.LineString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.LineString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.LineString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.LineString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Point.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Point points = 1;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Point>}
 */
proto.graphmasters.nugraph_website.route.LineString.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Point, 1));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Point>} value
 * @return {!proto.graphmasters.nugraph_website.route.LineString} returns this
*/
proto.graphmasters.nugraph_website.route.LineString.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Point}
 */
proto.graphmasters.nugraph_website.route.LineString.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.graphmasters.nugraph_website.route.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.LineString} returns this
 */
proto.graphmasters.nugraph_website.route.LineString.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Polygon.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Polygon.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Polygon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Polygon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Polygon.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.graphmasters.nugraph_website.route.Point.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Polygon}
 */
proto.graphmasters.nugraph_website.route.Polygon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Polygon;
  return proto.graphmasters.nugraph_website.route.Polygon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Polygon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Polygon}
 */
proto.graphmasters.nugraph_website.route.Polygon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Point;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Point.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Polygon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Polygon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Polygon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Polygon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Point.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Point points = 1;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Point>}
 */
proto.graphmasters.nugraph_website.route.Polygon.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Point, 1));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Point>} value
 * @return {!proto.graphmasters.nugraph_website.route.Polygon} returns this
*/
proto.graphmasters.nugraph_website.route.Polygon.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Point}
 */
proto.graphmasters.nugraph_website.route.Polygon.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.graphmasters.nugraph_website.route.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Polygon} returns this
 */
proto.graphmasters.nugraph_website.route.Polygon.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.Geometry.CoordinatesCase = {
  COORDINATES_NOT_SET: 0,
  POINT: 2,
  LINE_STRING: 3,
  POLYGON: 4
};

/**
 * @return {proto.graphmasters.nugraph_website.route.Geometry.CoordinatesCase}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.getCoordinatesCase = function() {
  return /** @type {proto.graphmasters.nugraph_website.route.Geometry.CoordinatesCase} */(jspb.Message.computeOneofCase(this, proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Geometry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Geometry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Geometry.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    point: (f = msg.getPoint()) && proto.graphmasters.nugraph_website.route.Point.toObject(includeInstance, f),
    lineString: (f = msg.getLineString()) && proto.graphmasters.nugraph_website.route.LineString.toObject(includeInstance, f),
    polygon: (f = msg.getPolygon()) && proto.graphmasters.nugraph_website.route.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Geometry}
 */
proto.graphmasters.nugraph_website.route.Geometry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Geometry;
  return proto.graphmasters.nugraph_website.route.Geometry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Geometry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Geometry}
 */
proto.graphmasters.nugraph_website.route.Geometry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Point;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.LineString;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.LineString.deserializeBinaryFromReader);
      msg.setLineString(value);
      break;
    case 4:
      var value = new proto.graphmasters.nugraph_website.route.Polygon;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Polygon.deserializeBinaryFromReader);
      msg.setPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Geometry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Geometry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Geometry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Point.serializeBinaryToWriter
    );
  }
  f = message.getLineString();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.LineString.serializeBinaryToWriter
    );
  }
  f = message.getPolygon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.graphmasters.nugraph_website.route.Polygon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Point point = 2;
 * @return {?proto.graphmasters.nugraph_website.route.Point}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.getPoint = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Point} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Point, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Point|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
*/
proto.graphmasters.nugraph_website.route.Geometry.prototype.setPoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LineString line_string = 3;
 * @return {?proto.graphmasters.nugraph_website.route.LineString}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.getLineString = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.LineString} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.LineString, 3));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.LineString|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
*/
proto.graphmasters.nugraph_website.route.Geometry.prototype.setLineString = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.clearLineString = function() {
  return this.setLineString(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.hasLineString = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Polygon polygon = 4;
 * @return {?proto.graphmasters.nugraph_website.route.Polygon}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.getPolygon = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Polygon} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Polygon, 4));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Polygon|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
*/
proto.graphmasters.nugraph_website.route.Geometry.prototype.setPolygon = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.graphmasters.nugraph_website.route.Geometry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Geometry} returns this
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.clearPolygon = function() {
  return this.setPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Geometry.prototype.hasPolygon = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    geometry: (f = msg.getGeometry()) && proto.graphmasters.nugraph_website.route.Geometry.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.graphmasters.nugraph_website.route.Properties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Feature;
  return proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Geometry;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Geometry.deserializeBinaryFromReader);
      msg.setGeometry(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.Properties;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Properties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGeometry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Geometry.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.Properties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Feature} returns this
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Geometry geometry = 2;
 * @return {?proto.graphmasters.nugraph_website.route.Geometry}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.getGeometry = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Geometry} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Geometry, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Geometry|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Feature} returns this
*/
proto.graphmasters.nugraph_website.route.Feature.prototype.setGeometry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Feature} returns this
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.clearGeometry = function() {
  return this.setGeometry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.hasGeometry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Properties properties = 3;
 * @return {?proto.graphmasters.nugraph_website.route.Properties}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.getProperties = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Properties} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Properties, 3));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Properties|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Feature} returns this
*/
proto.graphmasters.nugraph_website.route.Feature.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Feature} returns this
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Feature.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.ChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.ChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.ChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    configurablePenalties: (f = msg.getConfigurablePenalties()) && proto.graphmasters.nugraph_website.route.ConfigurablePenalties.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.graphmasters.nugraph_website.route.Coordinate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.ChartData}
 */
proto.graphmasters.nugraph_website.route.ChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.ChartData;
  return proto.graphmasters.nugraph_website.route.ChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.ChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.ChartData}
 */
proto.graphmasters.nugraph_website.route.ChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.ConfigurablePenalties;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.ConfigurablePenalties.deserializeBinaryFromReader);
      msg.setConfigurablePenalties(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.Coordinate;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.ChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.ChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.ChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getConfigurablePenalties();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.ConfigurablePenalties.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.ChartData} returns this
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional ConfigurablePenalties configurable_penalties = 2;
 * @return {?proto.graphmasters.nugraph_website.route.ConfigurablePenalties}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.getConfigurablePenalties = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.ConfigurablePenalties} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.ConfigurablePenalties, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.ConfigurablePenalties|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.ChartData} returns this
*/
proto.graphmasters.nugraph_website.route.ChartData.prototype.setConfigurablePenalties = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.ChartData} returns this
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.clearConfigurablePenalties = function() {
  return this.setConfigurablePenalties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.hasConfigurablePenalties = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinate location = 3;
 * @return {?proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.getLocation = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Coordinate, 3));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Coordinate|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.ChartData} returns this
*/
proto.graphmasters.nugraph_website.route.ChartData.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.ChartData} returns this
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.ChartData.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.ConfigurablePenalties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.toObject = function(includeInstance, msg) {
  var f, obj = {
    travelTimeAndTurnCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    speedPenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    anglePenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    highwayPenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.ConfigurablePenalties;
  return proto.graphmasters.nugraph_website.route.ConfigurablePenalties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTravelTimeAndTurnCost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeedPenalty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnglePenalty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighwayPenalty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.ConfigurablePenalties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTravelTimeAndTurnCost();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSpeedPenalty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAnglePenalty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHighwayPenalty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double travel_time_and_turn_cost = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.getTravelTimeAndTurnCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} returns this
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.setTravelTimeAndTurnCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double speed_penalty = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.getSpeedPenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} returns this
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.setSpeedPenalty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double angle_penalty = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.getAnglePenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} returns this
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.setAnglePenalty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double highway_penalty = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.getHighwayPenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.ConfigurablePenalties} returns this
 */
proto.graphmasters.nugraph_website.route.ConfigurablePenalties.prototype.setHighwayPenalty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.AgentData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.AgentData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.AgentData.toObject = function(includeInstance, msg) {
  var f, obj = {
    distanceKm: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    journeyTimeSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    etaErrorPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pathChanges: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.AgentData}
 */
proto.graphmasters.nugraph_website.route.AgentData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.AgentData;
  return proto.graphmasters.nugraph_website.route.AgentData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.AgentData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.AgentData}
 */
proto.graphmasters.nugraph_website.route.AgentData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistanceKm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJourneyTimeSeconds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEtaErrorPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPathChanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.AgentData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.AgentData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.AgentData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistanceKm();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getJourneyTimeSeconds();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEtaErrorPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPathChanges();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double distance_km = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.getDistanceKm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.AgentData} returns this
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.setDistanceKm = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double journey_time_seconds = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.getJourneyTimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.AgentData} returns this
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.setJourneyTimeSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double eta_error_percent = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.getEtaErrorPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.AgentData} returns this
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.setEtaErrorPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double path_changes = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.getPathChanges = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.AgentData} returns this
 */
proto.graphmasters.nugraph_website.route.AgentData.prototype.setPathChanges = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.GetRouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    route: (f = msg.getRoute()) && proto.graphmasters.nugraph_website.route.FeatureCollection.toObject(includeInstance, f),
    comparisonRoute: (f = msg.getComparisonRoute()) && proto.graphmasters.nugraph_website.route.FeatureCollection.toObject(includeInstance, f),
    truckSliderRoutesList: jspb.Message.toObjectList(msg.getTruckSliderRoutesList(),
    proto.graphmasters.nugraph_website.route.FeatureCollection.toObject, includeInstance),
    startTimeSliderRoutesList: jspb.Message.toObjectList(msg.getStartTimeSliderRoutesList(),
    proto.graphmasters.nugraph_website.route.FeatureCollection.toObject, includeInstance),
    requestId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gpx: jspb.Message.getFieldWithDefault(msg, 6, ""),
    geojson: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.GetRouteResponse;
  return proto.graphmasters.nugraph_website.route.GetRouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.FeatureCollection;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader);
      msg.setRoute(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.FeatureCollection;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader);
      msg.setComparisonRoute(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.FeatureCollection;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader);
      msg.addTruckSliderRoutes(value);
      break;
    case 4:
      var value = new proto.graphmasters.nugraph_website.route.FeatureCollection;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader);
      msg.addStartTimeSliderRoutes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGpx(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeojson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.GetRouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getComparisonRoute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getTruckSliderRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getStartTimeSliderRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGpx();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGeojson();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional FeatureCollection route = 1;
 * @return {?proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getRoute = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.FeatureCollection} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.FeatureCollection, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.FeatureCollection|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setRoute = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.clearRoute = function() {
  return this.setRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.hasRoute = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FeatureCollection comparison_route = 2;
 * @return {?proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getComparisonRoute = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.FeatureCollection} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.FeatureCollection, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.FeatureCollection|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setComparisonRoute = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.clearComparisonRoute = function() {
  return this.setComparisonRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.hasComparisonRoute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FeatureCollection truck_slider_routes = 3;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getTruckSliderRoutesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.FeatureCollection, 3));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setTruckSliderRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.FeatureCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.addTruckSliderRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.graphmasters.nugraph_website.route.FeatureCollection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.clearTruckSliderRoutesList = function() {
  return this.setTruckSliderRoutesList([]);
};


/**
 * repeated FeatureCollection start_time_slider_routes = 4;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getStartTimeSliderRoutesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.FeatureCollection, 4));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.FeatureCollection>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setStartTimeSliderRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.FeatureCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.addStartTimeSliderRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.graphmasters.nugraph_website.route.FeatureCollection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.clearStartTimeSliderRoutesList = function() {
  return this.setStartTimeSliderRoutesList([]);
};


/**
 * optional string request_id = 5;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gpx = 6;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getGpx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setGpx = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string geoJson = 7;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.getGeojson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteResponse.prototype.setGeojson = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.FeatureCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.FeatureCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuresList: jspb.Message.toObjectList(msg.getFeaturesList(),
    proto.graphmasters.nugraph_website.route.Feature.toObject, includeInstance),
    accelerationProfileList: jspb.Message.toObjectList(msg.getAccelerationProfileList(),
    proto.graphmasters.nugraph_website.route.ChartData.toObject, includeInstance),
    agentDataList: jspb.Message.toObjectList(msg.getAgentDataList(),
    proto.graphmasters.nugraph_website.route.AgentData.toObject, includeInstance),
    selfishAgentDataList: jspb.Message.toObjectList(msg.getSelfishAgentDataList(),
    proto.graphmasters.nugraph_website.route.AgentData.toObject, includeInstance),
    truckParameters: (f = msg.getTruckParameters()) && proto.graphmasters.nugraph_website.route.TruckParameters.toObject(includeInstance, f),
    startTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    duration: (f = msg.getDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.FeatureCollection;
  return proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.FeatureCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Feature;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader);
      msg.addFeatures(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.ChartData;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.ChartData.deserializeBinaryFromReader);
      msg.addAccelerationProfile(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.AgentData;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.AgentData.deserializeBinaryFromReader);
      msg.addAgentData(value);
      break;
    case 4:
      var value = new proto.graphmasters.nugraph_website.route.AgentData;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.AgentData.deserializeBinaryFromReader);
      msg.addSelfishAgentData(value);
      break;
    case 5:
      var value = new proto.graphmasters.nugraph_website.route.TruckParameters;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.TruckParameters.deserializeBinaryFromReader);
      msg.setTruckParameters(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 7:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.FeatureCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationProfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.ChartData.serializeBinaryToWriter
    );
  }
  f = message.getAgentDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.AgentData.serializeBinaryToWriter
    );
  }
  f = message.getSelfishAgentDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.graphmasters.nugraph_website.route.AgentData.serializeBinaryToWriter
    );
  }
  f = message.getTruckParameters();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.graphmasters.nugraph_website.route.TruckParameters.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Feature features = 1;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Feature>}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getFeaturesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Feature, 1));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Feature>} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.addFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.graphmasters.nugraph_website.route.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};


/**
 * repeated ChartData acceleration_profile = 2;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.ChartData>}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getAccelerationProfileList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.ChartData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.ChartData, 2));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.ChartData>} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setAccelerationProfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.ChartData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.ChartData}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.addAccelerationProfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.graphmasters.nugraph_website.route.ChartData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearAccelerationProfileList = function() {
  return this.setAccelerationProfileList([]);
};


/**
 * repeated AgentData agent_data = 3;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.AgentData>}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getAgentDataList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.AgentData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.AgentData, 3));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.AgentData>} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setAgentDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.AgentData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.AgentData}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.addAgentData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.graphmasters.nugraph_website.route.AgentData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearAgentDataList = function() {
  return this.setAgentDataList([]);
};


/**
 * repeated AgentData selfish_agent_data = 4;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.AgentData>}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getSelfishAgentDataList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.AgentData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.AgentData, 4));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.AgentData>} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setSelfishAgentDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.AgentData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.AgentData}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.addSelfishAgentData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.graphmasters.nugraph_website.route.AgentData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearSelfishAgentDataList = function() {
  return this.setSelfishAgentDataList([]);
};


/**
 * optional TruckParameters truck_parameters = 5;
 * @return {?proto.graphmasters.nugraph_website.route.TruckParameters}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getTruckParameters = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.TruckParameters} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.TruckParameters, 5));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.TruckParameters|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setTruckParameters = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearTruckParameters = function() {
  return this.setTruckParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.hasTruckParameters = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string start_time = 6;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Duration duration = 7;
 * @return {?proto.google.protobuf.Duration}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 7));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
*/
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.FeatureCollection} returns this
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.FeatureCollection.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.SimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.SimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureCollection: (f = msg.getFeatureCollection()) && proto.graphmasters.nugraph_website.route.FeatureCollection.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.graphmasters.nugraph_website.route.SimulationStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.SimulationResponse;
  return proto.graphmasters.nugraph_website.route.SimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.FeatureCollection;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.FeatureCollection.deserializeBinaryFromReader);
      msg.setFeatureCollection(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.SimulationStats;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.SimulationStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.SimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureCollection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.SimulationStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeatureCollection feature_collection = 1;
 * @return {?proto.graphmasters.nugraph_website.route.FeatureCollection}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.getFeatureCollection = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.FeatureCollection} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.FeatureCollection, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.FeatureCollection|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse} returns this
*/
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.setFeatureCollection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.clearFeatureCollection = function() {
  return this.setFeatureCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.hasFeatureCollection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SimulationStats stats = 2;
 * @return {?proto.graphmasters.nugraph_website.route.SimulationStats}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.getStats = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.SimulationStats} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.SimulationStats, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.SimulationStats|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse} returns this
*/
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationResponse} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.SimulationResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.SimulationStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.SimulationStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeSessions: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newSessionsRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    targetNewSessionsRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    routeRequestRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    targetRouteRequestRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    updateRequestRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    targetUpdateRequestRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.SimulationStats;
  return proto.graphmasters.nugraph_website.route.SimulationStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveSessions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewSessionsRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetNewSessionsRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRouteRequestRate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetRouteRequestRate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpdateRequestRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetUpdateRequestRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.SimulationStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveSessions();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNewSessionsRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTargetNewSessionsRate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRouteRequestRate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTargetRouteRequestRate();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getUpdateRequestRate();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTargetUpdateRequestRate();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int64 active_sessions = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getActiveSessions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setActiveSessions = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double new_sessions_rate = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getNewSessionsRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setNewSessionsRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double target_new_sessions_rate = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getTargetNewSessionsRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setTargetNewSessionsRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double route_request_rate = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getRouteRequestRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setRouteRequestRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double target_route_request_rate = 5;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getTargetRouteRequestRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setTargetRouteRequestRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double update_request_rate = 6;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getUpdateRequestRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setUpdateRequestRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double target_update_request_rate = 7;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.getTargetUpdateRequestRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationStats} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationStats.prototype.setTargetUpdateRequestRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.TruckParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.TruckParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.TruckParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    anglePenaltyFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    primaryPlusMultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    secondaryMultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    serviceResidentialTertiaryMultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    trackUnknownConstructionMultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    truckSpeedLowerBoundKmh: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    truckSpeedUpperBoundKmh: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    truckSpeedTransformationFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.TruckParameters;
  return proto.graphmasters.nugraph_website.route.TruckParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.TruckParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxAngle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnglePenaltyFactor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrimaryPlusMultiplier(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSecondaryMultiplier(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceResidentialTertiaryMultiplier(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackUnknownConstructionMultiplier(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTruckSpeedLowerBoundKmh(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTruckSpeedUpperBoundKmh(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTruckSpeedTransformationFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.TruckParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.TruckParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.TruckParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAnglePenaltyFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPrimaryPlusMultiplier();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSecondaryMultiplier();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getServiceResidentialTertiaryMultiplier();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTrackUnknownConstructionMultiplier();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTruckSpeedLowerBoundKmh();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTruckSpeedUpperBoundKmh();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTruckSpeedTransformationFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional double max_angle = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getMaxAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setMaxAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double angle_penalty_factor = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getAnglePenaltyFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setAnglePenaltyFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double primary_plus_multiplier = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getPrimaryPlusMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setPrimaryPlusMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double secondary_multiplier = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getSecondaryMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setSecondaryMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double service_residential_tertiary_multiplier = 5;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getServiceResidentialTertiaryMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setServiceResidentialTertiaryMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double track_unknown_construction_multiplier = 6;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getTrackUnknownConstructionMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setTrackUnknownConstructionMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double truck_speed_lower_bound_kmh = 7;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getTruckSpeedLowerBoundKmh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setTruckSpeedLowerBoundKmh = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double truck_speed_upper_bound_kmh = 8;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getTruckSpeedUpperBoundKmh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setTruckSpeedUpperBoundKmh = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double truck_speed_transformation_factor = 9;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.getTruckSpeedTransformationFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.TruckParameters} returns this
 */
proto.graphmasters.nugraph_website.route.TruckParameters.prototype.setTruckSpeedTransformationFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.VehicleProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.VehicleProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    length: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.VehicleProperties;
  return proto.graphmasters.nugraph_website.route.VehicleProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.VehicleProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLength(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.VehicleProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.VehicleProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double width = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties} returns this
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double height = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties} returns this
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double length = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties} returns this
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double weight = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.VehicleProperties} returns this
 */
proto.graphmasters.nugraph_website.route.VehicleProperties.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.repeatedFields_ = [2,24];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_ = [[34,35],[40,41]];

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.StarttimesCase = {
  STARTTIMES_NOT_SET: 0,
  START_TIME: 34,
  START_TIME_SLIDER: 35
};

/**
 * @return {proto.graphmasters.nugraph_website.route.GetRouteRequest.StarttimesCase}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getStarttimesCase = function() {
  return /** @type {proto.graphmasters.nugraph_website.route.GetRouteRequest.StarttimesCase} */(jspb.Message.computeOneofCase(this, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.CountrycodeCase = {
  COUNTRYCODE_NOT_SET: 0,
  AVOID: 40,
  STAY: 41
};

/**
 * @return {proto.graphmasters.nugraph_website.route.GetRouteRequest.CountrycodeCase}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getCountrycodeCase = function() {
  return /** @type {proto.graphmasters.nugraph_website.route.GetRouteRequest.CountrycodeCase} */(jspb.Message.computeOneofCase(this, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.GetRouteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    origin: (f = msg.getOrigin()) && proto.graphmasters.nugraph_website.route.Coordinate.toObject(includeInstance, f),
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    proto.graphmasters.nugraph_website.route.Coordinate.toObject, includeInstance),
    disableHeuristic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    debug: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    makeChart: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    useParams: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    truckParameters: (f = msg.getTruckParameters()) && proto.graphmasters.nugraph_website.route.TruckParameters.toObject(includeInstance, f),
    useVehicleProperties: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    vehicleProperties: (f = msg.getVehicleProperties()) && proto.graphmasters.nugraph_website.route.VehicleProperties.toObject(includeInstance, f),
    useHeading: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    comparison: (f = msg.getComparison()) && proto.graphmasters.nugraph_website.route.Comparison.toObject(includeInstance, f),
    authorization: jspb.Message.getFieldWithDefault(msg, 14, ""),
    numAgents: jspb.Message.getFieldWithDefault(msg, 15, 0),
    agentStartInterval: jspb.Message.getFieldWithDefault(msg, 16, ""),
    ignoreStaticCosts: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    useDestinationHeading: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    destinationHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    destinationStreetName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    timeout: jspb.Message.getFieldWithDefault(msg, 21, ""),
    switchTimeout: jspb.Message.getFieldWithDefault(msg, 22, ""),
    provider: (f = msg.getProvider()) && proto.graphmasters.nugraph_website.route.Provider.toObject(includeInstance, f),
    routingLayerIdsList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    featuredEventId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    role: jspb.Message.getFieldWithDefault(msg, 26, ""),
    vehicleTypeEvent: jspb.Message.getFieldWithDefault(msg, 27, ""),
    parkingAreaId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    tripDirection: jspb.Message.getFieldWithDefault(msg, 29, ""),
    vehicleType: jspb.Message.getFieldWithDefault(msg, 30, 0),
    originLevel: (f = msg.getOriginLevel()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    destinationLevel: (f = msg.getDestinationLevel()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    truckSlider: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    startTime: jspb.Message.getFieldWithDefault(msg, 34, ""),
    startTimeSlider: (f = msg.getStartTimeSlider()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    preferOutdoorLevels: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    useSelfishRouting: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    avoidTolls: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    sessionId: jspb.Message.getFieldWithDefault(msg, 39, ""),
    avoid: jspb.Message.getFieldWithDefault(msg, 40, ""),
    stay: jspb.Message.getFieldWithDefault(msg, 41, ""),
    ignoreClosures: jspb.Message.getBooleanFieldWithDefault(msg, 42, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.GetRouteRequest;
  return proto.graphmasters.nugraph_website.route.GetRouteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Coordinate;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader);
      msg.setOrigin(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Coordinate;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableHeuristic(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebug(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMakeChart(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseParams(value);
      break;
    case 7:
      var value = new proto.graphmasters.nugraph_website.route.TruckParameters;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.TruckParameters.deserializeBinaryFromReader);
      msg.setTruckParameters(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVehicleProperties(value);
      break;
    case 10:
      var value = new proto.graphmasters.nugraph_website.route.VehicleProperties;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.VehicleProperties.deserializeBinaryFromReader);
      msg.setVehicleProperties(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseHeading(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeading(value);
      break;
    case 13:
      var value = new proto.graphmasters.nugraph_website.route.Comparison;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Comparison.deserializeBinaryFromReader);
      msg.setComparison(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorization(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumAgents(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentStartInterval(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreStaticCosts(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseDestinationHeading(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDestinationHeading(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationStreetName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeout(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwitchTimeout(value);
      break;
    case 23:
      var value = new proto.graphmasters.nugraph_website.route.Provider;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoutingLayerIds(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturedEventId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleTypeEvent(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setParkingAreaId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripDirection(value);
      break;
    case 30:
      var value = /** @type {!proto.graphmasters.nugraph_website.route.VehicleType} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 31:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOriginLevel(value);
      break;
    case 32:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDestinationLevel(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTruckSlider(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 35:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setStartTimeSlider(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreferOutdoorLevels(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSelfishRouting(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvoidTolls(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvoid(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setStay(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreClosures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.GetRouteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.GetRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getDisableHeuristic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDebug();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMakeChart();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUseParams();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTruckParameters();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.graphmasters.nugraph_website.route.TruckParameters.serializeBinaryToWriter
    );
  }
  f = message.getUseVehicleProperties();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVehicleProperties();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.graphmasters.nugraph_website.route.VehicleProperties.serializeBinaryToWriter
    );
  }
  f = message.getUseHeading();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getComparison();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.graphmasters.nugraph_website.route.Comparison.serializeBinaryToWriter
    );
  }
  f = message.getAuthorization();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNumAgents();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getAgentStartInterval();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIgnoreStaticCosts();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getUseDestinationHeading();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDestinationHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getDestinationStreetName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTimeout();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSwitchTimeout();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.graphmasters.nugraph_website.route.Provider.serializeBinaryToWriter
    );
  }
  f = message.getRoutingLayerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getFeaturedEventId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getVehicleTypeEvent();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getParkingAreaId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getTripDirection();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getOriginLevel();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDestinationLevel();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTruckSlider();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getStartTimeSlider();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPreferOutdoorLevels();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getUseSelfishRouting();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getAvoidTolls();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeString(
      40,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 41));
  if (f != null) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getIgnoreClosures();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
};


/**
 * optional Coordinate origin = 1;
 * @return {?proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getOrigin = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Coordinate, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Coordinate|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearOrigin = function() {
  return this.setOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Coordinate destinations = 2;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Coordinate>}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Coordinate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Coordinate, 2));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Coordinate>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Coordinate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.graphmasters.nugraph_website.route.Coordinate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};


/**
 * optional bool disable_heuristic = 3;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDisableHeuristic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDisableHeuristic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool debug = 4;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDebug = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDebug = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool make_chart = 5;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getMakeChart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setMakeChart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool use_params = 6;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getUseParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setUseParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional TruckParameters truck_parameters = 7;
 * @return {?proto.graphmasters.nugraph_website.route.TruckParameters}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getTruckParameters = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.TruckParameters} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.TruckParameters, 7));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.TruckParameters|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setTruckParameters = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearTruckParameters = function() {
  return this.setTruckParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasTruckParameters = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool use_vehicle_properties = 9;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getUseVehicleProperties = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setUseVehicleProperties = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional VehicleProperties vehicle_properties = 10;
 * @return {?proto.graphmasters.nugraph_website.route.VehicleProperties}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getVehicleProperties = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.VehicleProperties} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.VehicleProperties, 10));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.VehicleProperties|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setVehicleProperties = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearVehicleProperties = function() {
  return this.setVehicleProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasVehicleProperties = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool use_heading = 11;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getUseHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setUseHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional float heading = 12;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional Comparison comparison = 13;
 * @return {?proto.graphmasters.nugraph_website.route.Comparison}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getComparison = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Comparison} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Comparison, 13));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Comparison|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setComparison = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearComparison = function() {
  return this.setComparison(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasComparison = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string authorization = 14;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getAuthorization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setAuthorization = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 num_agents = 15;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getNumAgents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setNumAgents = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string agent_start_interval = 16;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getAgentStartInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setAgentStartInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool ignore_static_costs = 17;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getIgnoreStaticCosts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setIgnoreStaticCosts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool use_destination_heading = 18;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getUseDestinationHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setUseDestinationHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional float destination_heading = 19;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDestinationHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDestinationHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional string destination_street_name = 20;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDestinationStreetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDestinationStreetName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string timeout = 21;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getTimeout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string switch_timeout = 22;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getSwitchTimeout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setSwitchTimeout = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Provider provider = 23;
 * @return {?proto.graphmasters.nugraph_website.route.Provider}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getProvider = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Provider} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Provider, 23));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Provider|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated string routing_layer_ids = 24;
 * @return {!Array<string>}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getRoutingLayerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setRoutingLayerIdsList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.addRoutingLayerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearRoutingLayerIdsList = function() {
  return this.setRoutingLayerIdsList([]);
};


/**
 * optional string featured_event_id = 25;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getFeaturedEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setFeaturedEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string role = 26;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string vehicle_type_event = 27;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getVehicleTypeEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setVehicleTypeEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string parking_area_id = 28;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getParkingAreaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setParkingAreaId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string trip_direction = 29;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getTripDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setTripDirection = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional VehicleType vehicle_type = 30;
 * @return {!proto.graphmasters.nugraph_website.route.VehicleType}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getVehicleType = function() {
  return /** @type {!proto.graphmasters.nugraph_website.route.VehicleType} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.VehicleType} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional google.protobuf.Int32Value origin_level = 31;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getOriginLevel = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 31));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setOriginLevel = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearOriginLevel = function() {
  return this.setOriginLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasOriginLevel = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Int32Value destination_level = 32;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getDestinationLevel = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 32));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setDestinationLevel = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearDestinationLevel = function() {
  return this.setDestinationLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasDestinationLevel = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional bool truck_slider = 33;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getTruckSlider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setTruckSlider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional string start_time = 34;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setOneofField(this, 34, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearStartTime = function() {
  return jspb.Message.setOneofField(this, 34, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Empty start_time_slider = 35;
 * @return {?proto.google.protobuf.Empty}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getStartTimeSlider = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 35));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setStartTimeSlider = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearStartTimeSlider = function() {
  return this.setStartTimeSlider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasStartTimeSlider = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional bool prefer_outdoor_levels = 36;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getPreferOutdoorLevels = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setPreferOutdoorLevels = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool use_selfish_routing = 37;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getUseSelfishRouting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setUseSelfishRouting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool avoid_tolls = 38;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getAvoidTolls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setAvoidTolls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional string session_id = 39;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string avoid = 40;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getAvoid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setAvoid = function(value) {
  return jspb.Message.setOneofField(this, 40, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearAvoid = function() {
  return jspb.Message.setOneofField(this, 40, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasAvoid = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional string stay = 41;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getStay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setStay = function(value) {
  return jspb.Message.setOneofField(this, 41, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.clearStay = function() {
  return jspb.Message.setOneofField(this, 41, proto.graphmasters.nugraph_website.route.GetRouteRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.hasStay = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional bool ignore_closures = 42;
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.getIgnoreClosures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.graphmasters.nugraph_website.route.GetRouteRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetRouteRequest.prototype.setIgnoreClosures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.SimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.SimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && proto.graphmasters.nugraph_website.route.SimulationSettings.toObject(includeInstance, f),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.graphmasters.nugraph_website.route.Feature.toObject, includeInstance),
    request: (f = msg.getRequest()) && proto.graphmasters.nugraph_website.route.GetRouteRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.SimulationRequest;
  return proto.graphmasters.nugraph_website.route.SimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.SimulationSettings;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.SimulationSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Feature;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.GetRouteRequest;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.GetRouteRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.SimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.SimulationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.GetRouteRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional SimulationSettings settings = 1;
 * @return {?proto.graphmasters.nugraph_website.route.SimulationSettings}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.getSettings = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.SimulationSettings} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.SimulationSettings, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.SimulationSettings|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
*/
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Feature sources = 2;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Feature>}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Feature, 2));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Feature>} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
*/
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.graphmasters.nugraph_website.route.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};


/**
 * optional GetRouteRequest request = 3;
 * @return {?proto.graphmasters.nugraph_website.route.GetRouteRequest}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.getRequest = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.GetRouteRequest} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.GetRouteRequest, 3));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.GetRouteRequest|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
*/
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationRequest} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.SimulationRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Comparison.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Comparison} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Comparison.toObject = function(includeInstance, msg) {
  var f, obj = {
    comparisonType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    comparisonUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Comparison}
 */
proto.graphmasters.nugraph_website.route.Comparison.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Comparison;
  return proto.graphmasters.nugraph_website.route.Comparison.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Comparison} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Comparison}
 */
proto.graphmasters.nugraph_website.route.Comparison.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.graphmasters.nugraph_website.route.ComparisonType} */ (reader.readEnum());
      msg.setComparisonType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComparisonUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Comparison.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Comparison} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Comparison.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComparisonType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getComparisonUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ComparisonType comparison_type = 1;
 * @return {!proto.graphmasters.nugraph_website.route.ComparisonType}
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.getComparisonType = function() {
  return /** @type {!proto.graphmasters.nugraph_website.route.ComparisonType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.ComparisonType} value
 * @return {!proto.graphmasters.nugraph_website.route.Comparison} returns this
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.setComparisonType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string comparison_url = 2;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.getComparisonUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Comparison} returns this
 */
proto.graphmasters.nugraph_website.route.Comparison.prototype.setComparisonUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Provider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Provider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Provider.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    providerUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Provider}
 */
proto.graphmasters.nugraph_website.route.Provider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Provider;
  return proto.graphmasters.nugraph_website.route.Provider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Provider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Provider}
 */
proto.graphmasters.nugraph_website.route.Provider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.graphmasters.nugraph_website.route.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Provider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Provider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Provider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProviderUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ProviderType provider_type = 1;
 * @return {!proto.graphmasters.nugraph_website.route.ProviderType}
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.getProviderType = function() {
  return /** @type {!proto.graphmasters.nugraph_website.route.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.ProviderType} value
 * @return {!proto.graphmasters.nugraph_website.route.Provider} returns this
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string provider_url = 2;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.getProviderUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Provider} returns this
 */
proto.graphmasters.nugraph_website.route.Provider.prototype.setProviderUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.CurrentTurnCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: (f = msg.getLocation()) && proto.graphmasters.nugraph_website.route.Coordinate.toObject(includeInstance, f),
    turnCommand: (f = msg.getTurnCommand()) && proto.graphmasters.nugraph_website.route.TurnInfo.toObject(includeInstance, f),
    turnCommandIdx: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.CurrentTurnCommand;
  return proto.graphmasters.nugraph_website.route.CurrentTurnCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Coordinate;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.TurnInfo;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.TurnInfo.deserializeBinaryFromReader);
      msg.setTurnCommand(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTurnCommandIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.CurrentTurnCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getTurnCommand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.TurnInfo.serializeBinaryToWriter
    );
  }
  f = message.getTurnCommandIdx();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional Coordinate location = 1;
 * @return {?proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.getLocation = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Coordinate, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Coordinate|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} returns this
*/
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} returns this
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TurnInfo turn_command = 2;
 * @return {?proto.graphmasters.nugraph_website.route.TurnInfo}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.getTurnCommand = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.TurnInfo} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.TurnInfo, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.TurnInfo|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} returns this
*/
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.setTurnCommand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} returns this
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.clearTurnCommand = function() {
  return this.setTurnCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.hasTurnCommand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 turn_command_idx = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.getTurnCommandIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.CurrentTurnCommand} returns this
 */
proto.graphmasters.nugraph_website.route.CurrentTurnCommand.prototype.setTurnCommandIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: (f = msg.getLocation()) && proto.graphmasters.nugraph_website.route.Coordinate.toObject(includeInstance, f),
    featuresList: jspb.Message.toObjectList(msg.getFeaturesList(),
    proto.graphmasters.nugraph_website.route.Feature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest;
  return proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Coordinate;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Feature;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader);
      msg.addFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter
    );
  }
};


/**
 * optional Coordinate location = 1;
 * @return {?proto.graphmasters.nugraph_website.route.Coordinate}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.getLocation = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Coordinate, 1));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Coordinate|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Feature features = 2;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Feature>}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.getFeaturesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Feature, 2));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Feature>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} returns this
*/
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.setFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.addFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.graphmasters.nugraph_website.route.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetCurrentTurnCommandRequest.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.SimulationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.SimulationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    accelerationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    nextRefreshIntervalSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selfishRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.SimulationSettings;
  return proto.graphmasters.nugraph_website.route.SimulationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRequestRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAccelerationRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextRefreshIntervalSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSelfishRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.SimulationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAccelerationRate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNextRefreshIntervalSeconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSelfishRatio();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string instance_id = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double request_rate = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.getRequestRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.setRequestRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double acceleration_rate = 3;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.getAccelerationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.setAccelerationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 next_refresh_interval_seconds = 4;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.getNextRefreshIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.setNextRefreshIntervalSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double selfish_ratio = 5;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.getSelfishRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.SimulationSettings} returns this
 */
proto.graphmasters.nugraph_website.route.SimulationSettings.prototype.setSelfishRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.SimulationUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationUpdateResponse}
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.SimulationUpdateResponse;
  return proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.SimulationUpdateResponse}
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.SimulationUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.SimulationUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.RoutingLayerVertex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.toObject = function(includeInstance, msg) {
  var f, obj = {
    vertex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    penalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.RoutingLayerVertex;
  return proto.graphmasters.nugraph_website.route.RoutingLayerVertex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVertex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPenalty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.RoutingLayerVertex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVertex();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPenalty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional uint64 vertex = 1;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.getVertex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex} returns this
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.setVertex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double penalty = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.getPenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex} returns this
 */
proto.graphmasters.nugraph_website.route.RoutingLayerVertex.prototype.setPenalty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.RoutingLayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    penalisedVerticesList: jspb.Message.toObjectList(msg.getPenalisedVerticesList(),
    proto.graphmasters.nugraph_website.route.RoutingLayerVertex.toObject, includeInstance),
    priority: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayer}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.RoutingLayer;
  return proto.graphmasters.nugraph_website.route.RoutingLayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayer}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.RoutingLayerVertex;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.RoutingLayerVertex.deserializeBinaryFromReader);
      msg.addPenalisedVertices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.RoutingLayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPenalisedVerticesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.RoutingLayerVertex.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated RoutingLayerVertex penalised_vertices = 1;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.RoutingLayerVertex>}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.getPenalisedVerticesList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.RoutingLayerVertex>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.RoutingLayerVertex, 1));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.RoutingLayerVertex>} value
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayer} returns this
*/
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.setPenalisedVerticesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayerVertex}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.addPenalisedVertices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.graphmasters.nugraph_website.route.RoutingLayerVertex, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayer} returns this
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.clearPenalisedVerticesList = function() {
  return this.setPenalisedVerticesList([]);
};


/**
 * optional int32 priority = 2;
 * @return {number}
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.graphmasters.nugraph_website.route.RoutingLayer} returns this
 */
proto.graphmasters.nugraph_website.route.RoutingLayer.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.GetTourRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.GetTourRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tourData: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.GetTourRequest}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.GetTourRequest;
  return proto.graphmasters.nugraph_website.route.GetTourRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.GetTourRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.GetTourRequest}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourData(value);
      break;
    case 2:
      var value = /** @type {!proto.graphmasters.nugraph_website.route.VehicleType} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.GetTourRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.GetTourRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTourData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string tour_data = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.getTourData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.GetTourRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.setTourData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleType vehicle_type = 2;
 * @return {!proto.graphmasters.nugraph_website.route.VehicleType}
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.getVehicleType = function() {
  return /** @type {!proto.graphmasters.nugraph_website.route.VehicleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.VehicleType} value
 * @return {!proto.graphmasters.nugraph_website.route.GetTourRequest} returns this
 */
proto.graphmasters.nugraph_website.route.GetTourRequest.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.GetTourResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.GetTourResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.graphmasters.nugraph_website.route.Day.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.GetTourResponse}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.GetTourResponse;
  return proto.graphmasters.nugraph_website.route.GetTourResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.GetTourResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.GetTourResponse}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.graphmasters.nugraph_website.route.Day;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Day.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.GetTourResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.GetTourResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.graphmasters.nugraph_website.route.Day.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Day days = 1;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Day>}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Day>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Day, 1));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Day>} value
 * @return {!proto.graphmasters.nugraph_website.route.GetTourResponse} returns this
*/
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Day=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Day}
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.graphmasters.nugraph_website.route.Day, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.GetTourResponse} returns this
 */
proto.graphmasters.nugraph_website.route.GetTourResponse.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Day.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Day.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Day.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Day} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Day.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toursList: jspb.Message.toObjectList(msg.getToursList(),
    proto.graphmasters.nugraph_website.route.Tour.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Day}
 */
proto.graphmasters.nugraph_website.route.Day.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Day;
  return proto.graphmasters.nugraph_website.route.Day.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Day} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Day}
 */
proto.graphmasters.nugraph_website.route.Day.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Tour;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Tour.deserializeBinaryFromReader);
      msg.addTours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Day.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Day.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Day} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Day.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToursList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Tour.serializeBinaryToWriter
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Day.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Day} returns this
 */
proto.graphmasters.nugraph_website.route.Day.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Tour tours = 2;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Tour>}
 */
proto.graphmasters.nugraph_website.route.Day.prototype.getToursList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Tour>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Tour, 2));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Tour>} value
 * @return {!proto.graphmasters.nugraph_website.route.Day} returns this
*/
proto.graphmasters.nugraph_website.route.Day.prototype.setToursList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Tour=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Tour}
 */
proto.graphmasters.nugraph_website.route.Day.prototype.addTours = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.graphmasters.nugraph_website.route.Tour, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Day} returns this
 */
proto.graphmasters.nugraph_website.route.Day.prototype.clearToursList = function() {
  return this.setToursList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.graphmasters.nugraph_website.route.Tour.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.toObject = function(opt_includeInstance) {
  return proto.graphmasters.nugraph_website.route.Tour.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.graphmasters.nugraph_website.route.Tour} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Tour.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    route: (f = msg.getRoute()) && proto.graphmasters.nugraph_website.route.Feature.toObject(includeInstance, f),
    waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.graphmasters.nugraph_website.route.Feature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.graphmasters.nugraph_website.route.Tour}
 */
proto.graphmasters.nugraph_website.route.Tour.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.graphmasters.nugraph_website.route.Tour;
  return proto.graphmasters.nugraph_website.route.Tour.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.graphmasters.nugraph_website.route.Tour} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.graphmasters.nugraph_website.route.Tour}
 */
proto.graphmasters.nugraph_website.route.Tour.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.graphmasters.nugraph_website.route.Feature;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader);
      msg.setRoute(value);
      break;
    case 3:
      var value = new proto.graphmasters.nugraph_website.route.Feature;
      reader.readMessage(value,proto.graphmasters.nugraph_website.route.Feature.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.graphmasters.nugraph_website.route.Tour.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.graphmasters.nugraph_website.route.Tour} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.graphmasters.nugraph_website.route.Tour.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.graphmasters.nugraph_website.route.Feature.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.graphmasters.nugraph_website.route.Tour} returns this
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Feature route = 2;
 * @return {?proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.getRoute = function() {
  return /** @type{?proto.graphmasters.nugraph_website.route.Feature} */ (
    jspb.Message.getWrapperField(this, proto.graphmasters.nugraph_website.route.Feature, 2));
};


/**
 * @param {?proto.graphmasters.nugraph_website.route.Feature|undefined} value
 * @return {!proto.graphmasters.nugraph_website.route.Tour} returns this
*/
proto.graphmasters.nugraph_website.route.Tour.prototype.setRoute = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.graphmasters.nugraph_website.route.Tour} returns this
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.clearRoute = function() {
  return this.setRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.hasRoute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Feature waypoints = 3;
 * @return {!Array<!proto.graphmasters.nugraph_website.route.Feature>}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.graphmasters.nugraph_website.route.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.graphmasters.nugraph_website.route.Feature, 3));
};


/**
 * @param {!Array<!proto.graphmasters.nugraph_website.route.Feature>} value
 * @return {!proto.graphmasters.nugraph_website.route.Tour} returns this
*/
proto.graphmasters.nugraph_website.route.Tour.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.graphmasters.nugraph_website.route.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.graphmasters.nugraph_website.route.Feature}
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.graphmasters.nugraph_website.route.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.graphmasters.nugraph_website.route.Tour} returns this
 */
proto.graphmasters.nugraph_website.route.Tour.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};


/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.ComparisonType = {
  NONE: 0,
  TOMTOM: 1,
  GERMANY: 2,
  NUGRAPH_SELFISH: 3,
  CUSTOM: 4,
  HERE: 5,
  EUROPE_TRUCK_ROUTER: 6,
  GERMANY_NO_TRAFFIC: 7,
  EUROPE: 8,
  EUROPE_NO_TRAFFIC: 9,
  ROUTER_GATEWAY: 10
};

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.ProviderType = {
  PROVIDER_NONE: 0,
  PROVIDER_ROUTER_GATEWAY: 1,
  PROVIDER_HERE: 2,
  PROVIDER_EUROPE_TRUCK_ROUTER: 3,
  PROVIDER_EUROPE: 4,
  PROVIDER_EVENT: 5,
  PROVIDER_SIMULATION: 6,
  PROVIDER_BICYCLE: 7,
  PROVIDER_PEDESTRIAN: 8,
  PROVIDER_GERMANY: 9,
  PROVIDER_GERMANY_TEST: 10,
  PROVIDER_MULTITARGET_EUROPE: 11,
  PROVIDER_MULTITARGET_GERMANY: 12,
  PROVIDER_MULTITARGET_BICYCLE: 13,
  PROVIDER_KAPSCH: 14
};

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.PathType = {
  DEBUG_VERTEX: 0,
  DEBUG_PATH: 1,
  ACTUAL_PATH: 2,
  COMPARISON_PATH: 3,
  CONTRACTION_BOUNDARY: 4,
  DOWNSAMPLED_PATH: 5,
  RESTRICTION_INFO: 6
};

/**
 * @enum {number}
 */
proto.graphmasters.nugraph_website.route.VehicleType = {
  CAR: 0,
  DELIVERY: 1,
  TRUCK: 2,
  BICYCLE: 3,
  PEDESTRIAN: 4
};

goog.object.extend(exports, proto.graphmasters.nugraph_website.route);
